import React from 'react'
import { CImage, CView } from 'app/components/Common'
import { useHistory } from 'react-router-dom'
import logo from '../../../assets/images/logo.png'

const HeaderLogo = () => {
	const navigation = useHistory()
	return (
		<CView
			onClick={() => {
				navigation.push('/admin')
			}}
			style={{
				float: 'left',
				height: 38,
				paddingInline: 5,
				justifyContent: 'center',
				alignItems: 'center',
				background: 'rgba(255, 255, 255, 1)',
				borderRadius: 5,
				cursor: 'pointer',
				userSelect: 'none',
			}}>
			<CImage
				alt={'headerLogo'}
				src={logo}
				resizeMode={'contain'}
				style={{ width: '100%', height: '100%' }}
			/>
		</CView>
	)
}

export default HeaderLogo
