import React, { useState } from 'react'
import { Layout, Menu, MenuProps } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'

import { useWindowDimensions } from 'app/hooks'
import { Text, View } from 'styles/reactnative'
import './styles.css'
const { Header } = Layout
const HeaderMenu = ({ menus }) => {
	const classes = useStyles()

	const navigation = useHistory()
	const location = useLocation()
	const url = location.pathname.split('/')?.[2]

	const { width } = useWindowDimensions()
	const [current, setCurrent] = useState('사용자관리')
	const goTos = goTo => {
		navigation.push(goTo)
	}

	const mobileSize = width < 600 ? '#000' : '#fff'
	const MenuText = (goTo, text: any, item, children) => {
		return (
			<Text
				onClick={() => goTos(goTo)}
				style={{
					display: 'flex',
					alignSelf: 'flex-end',
					background: url === item.path ? '#fff' : 'transparent',
					color: children ? '#000' : url === item.path ? '#000' : mobileSize,

					paddingInline: 10,
					height: 56,
				}}>
				{text}
			</Text>
		)
	}

	const onClick: MenuProps['onClick'] = e => {
		setCurrent(e.key)
	}

	const menuItems = menus.map((item: any) => ({
		key: item.id,
		label: MenuText(`/admin/${item.path}`, item?.name, item, false),
		children: item?.data?.map((child: any) => ({
			key: child.id,
			label: MenuText(`/admin/${child.path}`, child?.name, item, true),
		})),
	}))

	return (
		<Header className={classes.header}>
			<View />
			<Menu
				onClick={onClick}
				selectedKeys={[current]}
				mode="horizontal"
				items={menuItems}
				style={{
					justifyContent: 'flex-start',
					alignItems: 'flex-end',
					background: '#2DA0D2',
					height: 60,
					width: '100%',
					borderBottom: 0,
				}}
				theme={'light'}
			/>
			<View />
		</Header>
	)
}
const useStyles = createUseStyles({
	header: {
		position: 'sticky',
		top: 0,
		zIndex: 1,
		width: '100%',
		height: 60,
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		background: '#2DA0D2',
		paddingInline: 0,
		marginLeft: -30,
	},
})
export default HeaderMenu
