import styled from 'styled-components'

export const ContentWrap = styled.div`
	width: 100%;
	max-width: 1200px;
	background-color: #ffffff;
	/* padding: 10px; */
	/* margin-left: ; */
	padding: 0 10px;
	margin: 30px auto;
	min-height: 70vh;

	@media screen and (max-width: 570px) {
		min-height: 50vh;
	}
`

export const CustomPageWrap = styled.div`
	/* display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column; */
`

export const Title = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	width: 80%;
	height: 36px;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
`
export const Name = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	width: 150px;
	height: 36px;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;
`
export const Date = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 167px;
	height: 36px;

	border-width: 0px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;
`

export const DataRow = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);

	width: 100%;
`

export const TitleHeader = styled.div`
	box-sizing: border-box;
	grid-column: 1 / 3;
	height: 40px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	display: flex;
	align-items: center;

	color: #333333;
`

export const PostFormTitle = styled.div`
	box-sizing: border-box;
	grid-column: 3 / 13;
	height: 40px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	align-items: center;

	color: #333333;
`
export const ContentHeader = styled.div`
	box-sizing: border-box;
	grid-column: 1 / 3;
	height: 280px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	display: flex;
	align-items: center;

	color: #333333;
`

export const Content = styled.div`
	box-sizing: border-box;
	grid-column: 3 / 13;
	/* width: 90%; */
	height: 280px;
`

export const Lock = styled.div`
	width: 200px;
	padding: 0 30px;
	height: 46px;
	line-height: 46px;

	border-width: 0px 0px 1px 0px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const Password = styled.div`
	width: 100%;
	padding: 0 5px;
	height: 46px;
	line-height: 46px;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
export const CancelButton = styled.button`
	padding: 0px 10px;

	width: 68px;
	text-align: center;
	height: 32px;

	/* content/accentRed */

	background: #d44242;
	border-radius: 2px;
	border: none;

	font-size: 14px;
	line-height: 17px;

	/* text/white */

	color: #ffffff;
`
