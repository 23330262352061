import React, { useEffect, useState } from 'react'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import { useHistory } from 'react-router-dom'
import setting from '../../../../../assets/icons/setting.png'
import { TitleWrapLeft, TitleWrapRight, ButtonWrap, ControllerWrap } from 'styles/globalPage-styles'
import { ContentWrap, ContentHeader, CustomPageWrap, Date, Name, Title } from './styles'
import * as antd from 'antd'
import Editor from '../Post/editor'
import { Content, DataRow, Lock, Password, PostFormTitle, TitleHeader } from './styles'
import styled from 'styled-components'
import CIUtils from 'utils/ciutils'
import PersonalModal from './PersonalModal'

type Props = {
	handleModal: (change?: boolean) => void
	data?: any
	setPostdataChange?: React.Dispatch<React.SetStateAction<boolean>>
}

function PostForm({ handleModal, data, setPostdataChange }: Props) {
	const { data: token } = useToken()

	const [type, setType] = useState('')
	const [content, setContent] = useState('')
	const [title, setTitle] = useState('')
	const [password, setPassword] = useState('')
	const [name, setName] = useState('')
	const [phone, setPhone] = useState('')
	const [passwordRule, setPasswordRule] = useState(false)
	const [personalData, setPersonalData] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const navigate = useHistory()

	const initData = () => {
		if (data) {
			setContent(decodeURIComponent(data.content))
			setTitle(decodeURIComponent(data.title))
			setPassword(data.password)
			setName(data.writer)
			setPhone(data.phone)
		}
	}

	const handlePersonalModal = () => {
		setShowModal(false)
	}

	const passwordCheck = () => {
		if (!CIUtils.validateIdeaPassword(password)) {
			setPasswordRule(false)
		} else {
			setPasswordRule(true)
		}
	}

	const checkChangedData = () => {
		let updateData: any = {}

		if (decodeURIComponent(data.content) != content) {
			updateData = {
				...updateData,
				content,
			}
		}

		if (decodeURIComponent(data.title) != title) {
			updateData = {
				...updateData,
				title,
			}
		}

		if (data.password != password) {
			updateData = {
				...updateData,
				password,
			}
		}

		if (data.writer != name) {
			updateData = {
				...updateData,
				writer: name,
			}
		}

		if (data.phone != phone) {
			updateData = {
				...updateData,
				phone,
			}
		}

		if (Object.keys(updateData).length < 1) return undefined
		return updateData
	}

	const validateData = () => {
		if (!name) {
			antd.message.info('작성자명을 입력해주세요')
			return false
		}
		if (!title) {
			antd.message.info('제목을 입력해주세요')
			return false
		}
		if (!content) {
			antd.message.info('내용을 입력해주세요')
			return false
		}
		if (!phone) {
			antd.message.info('연락처를 입력해주세요')
			return false
		}
		if (!password || !passwordRule) {
			antd.message.info('비밀번호를 확인해주세요')
			return false
		}

		if (!data && !personalData) {
			antd.message.info('개인정보제공에 동의해주세요')
			return false
		}

		return true
	}

	const updatePost = () => {
		// const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		const boardPath = 'ideas'
		let apiurl = `/board/${boardPath}/${data?.id}`

		const validation = validateData()

		if (!validation) {
			return
		}

		const updateData: any = checkChangedData()

		if (!updateData) {
			antd.message.info('수정사항이 없습니다')
			return
		}

		API.put(apiurl, token + '', updateData)
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정되었습니다')
					setPostdataChange && setPostdataChange(true)
					handleModal(false)
					// setPost(null)
					// setContent(res.result.result.content)
					// setTitle(res.result.result.title)
					// // setPost(res.result.result)
					// setPassword(res.result.result.password)
					// setLock()
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const createPost = () => {
		// const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		// temp
		const boardPath = 'ideas'
		let apiurl = `/board/${boardPath}`

		const validation = validateData()

		if (!validation) {
			return
		}

		API.post(apiurl, token + '', {
			type,
			lock: true,
			title,
			name,
			phone,
			content,
			password,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('등록되었습니다')
					handleModal(true)
					// const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
					// if (lock) {
					// 	handleModal(true)
					// } else {
					// 	navigate.push(`/ideas/${res.result.result}`)
					// }
				}
			})
			.catch((err: any) => {})
	}

	useEffect(() => {
		initData()
	}, [])

	useEffect(() => {
		passwordCheck()
	}, [password])

	return (
		<ContentWrap>
			<TitleWrap>
				<MainTitle>Write</MainTitle>
				<SubTitle>아이디어를 남겨주시면 전문팀이 확인하고 답변해드립니다</SubTitle>
			</TitleWrap>
			<TableWrap>
				<DataRow>
					<TitleHeader>제목</TitleHeader>
					<PostFormTitle>
						<antd.Input value={title} onChange={e => setTitle(e.target.value)}></antd.Input>
					</PostFormTitle>
				</DataRow>
				<DataRow>
					<ContentHeader>내용</ContentHeader>
					<Content>
						<Editor content={content} setContent={setContent}></Editor>
					</Content>
				</DataRow>
				<DataRow>
					<TitleHeader>작성자명</TitleHeader>
					<PostFormTitle>
						<antd.Input value={name} onChange={e => setName(e.target.value)}></antd.Input>
					</PostFormTitle>
				</DataRow>
				<DataRow>
					<TitleHeader>연락처</TitleHeader>
					<PostFormTitle>
						<antd.Input value={phone} onChange={e => setPhone(e.target.value)}></antd.Input>
					</PostFormTitle>
				</DataRow>
				<DataRow>
					<TitleHeader>비밀번호</TitleHeader>
					<PostFormTitle style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
						<antd.Input
							type="password"
							// readOnly={!lock}
							onChange={e => setPassword(e.target.value)}
							value={password}></antd.Input>
					</PostFormTitle>
				</DataRow>
				<DataRow>
					<TitleHeader></TitleHeader>
					<PostFormTitle style={{ height: '20px' }}>
						<PasswordRule>
							{passwordRule ? '' : '영문대소문자, 숫자를 사용하여 6~20자로 입력'}
						</PasswordRule>
					</PostFormTitle>
				</DataRow>
				{!data && (
					<DataRow>
						<TitleHeader>약관동의</TitleHeader>
						<PersonalForm>
							<antd.Input
								style={{ width: 'auto', marginRight: '5px' }}
								type="radio"
								checked={personalData}
								onChange={e => {
									console.log(e.target.checked)
								}}></antd.Input>
							<Personal
								onClick={() => {
									setPersonalData(true)
								}}>
								개인정보수집제공동의(필수)
							</Personal>
							<ShowButton
								onClick={() => {
									setShowModal(true)
								}}>
								보기
							</ShowButton>
						</PersonalForm>
					</DataRow>
				)}

				{/* <DataRow>
					<TitleHeader>비공개</TitleHeader>
					<PostFormTitle>
						<Lock>
							<antd.Space>
								<antd.Checkbox
									onChange={e => {
										setLock(e.target.checked)
										if (!e.target.checked) setPassword('')
									}}>
									비공개
								</antd.Checkbox>
							</antd.Space>
						</Lock>
						<TitleHeader>비밀번호</TitleHeader>
						<Password>
							<Input type="password" readOnly={!lock} onChange={(e)=>setPassword(e.target.value)} value={password}></Input>
						</Password>
					</PostFormTitle>
				</DataRow> 
				*/}
			</TableWrap>
			{!data ? (
				<ButtonWrap style={{ margin: '0 auto' }}>
					<CreateButton onClick={createPost}>등록하기</CreateButton>
					{/* <CancelButton onClick={()=>handleModal(false)}>취소</CancelButton> */}
				</ButtonWrap>
			) : (
				<ButtonWrap style={{ margin: '0 auto' }}>
					<CreateButton onClick={updatePost}>수정하기</CreateButton>
					<CancelButton onClick={() => handleModal(false)}>취소</CancelButton>
				</ButtonWrap>
			)}

			{showModal && <PersonalModal handleModal={handlePersonalModal} />}
		</ContentWrap>
	)
}

export default PostForm

const PersonalForm = styled.div`
	box-sizing: border-box;
	grid-column: 3 / 13;
	height: 40px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	align-items: center;

	color: #333333;
`

const TableWrap = styled.div`
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 15px;

	width: 100%;
	margin-bottom: 40px;
`

const TitleWrap = styled.div`
	width: 100%;
	max-width: 1320px;
	text-align: center;
	margin: 90px auto 80px auto;

	background-color: #ffffff;
`

const MainTitle = styled.div`
	width: 100%;

	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 60px;
	/* identical to box height, or 117% */

	text-align: center;
	letter-spacing: -0.015em;

	color: #000000;
`

const SubTitle = styled.div`
	width: 100%;

	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 26px;
	/* identical to box height, or 144% */

	text-align: center;
	letter-spacing: -0.015em;

	color: #333333;
`

const PasswordRule = styled.span`
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;

	color: #ce5858;
`

const CreateButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;

	width: 137px;
	height: 49px;

	background: #333333;
	border-radius: 1px;
	/* border-color: #333333; */

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	display: flex;
	align-items: center;

	color: #ffffff;

	&:hover{
		cursor: pointer;
	}
`

const CancelButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;

	width: 137px;
	height: 49px;

	background: #333333;
	border-radius: 1px;
	/* border-color: #333333; */

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	display: flex;
	align-items: center;

	color: #ffffff;
`
const Personal = styled.span`
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	display: flex;
	align-items: center;

	color: #7d7d7d;

	margin-right: 25px;

	&:hover {
		cursor: pointer;
	}
`
const ShowButton = styled.span`
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	display: flex;
	align-items: center;

	color: #868686;
	text-decoration: underline;

	&:hover {
		cursor: pointer;
	}
`
