import React, { useState } from 'react'
import styled from 'styled-components'
import prevIcon from '../../../../assets/icons/left.png'
import nextIcon from '../../../../assets/icons/right.png'
import { View } from 'styles/reactnative'

let pageC = 0

function Items({ page, changePage, currentItems }) {
	return (
		<>
			{currentItems?.map((item, index) => {
				return (
					<View
						key={index.toString()}
						style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
						{item === page ? (
							<ClickedPageNumberBox>{item}</ClickedPageNumberBox>
						) : (
							<PageNumberBox onClick={() => changePage(item)}>{item}</PageNumberBox>
						)}
					</View>
				)
				// if (item === page) return <ClickedPageNumberBox>{item}</ClickedPageNumberBox>
				// else return <PageNumberBox onClick={() => changePage(item)}>{item}</PageNumberBox>
				// // <div>
				// //   <h3 onClick={()=>changePage(item)}>Item #{item}</h3>
				// // </div>
			})}
		</>
	)
}

function PaginatedItems({ page, pageSize, changePage, itemsPerPage }) {
	const pageCount = Math.ceil(pageSize.length / itemsPerPage)
	const [itemOffset, setItemOffset] = useState(0)

	const endOffset = itemOffset + itemsPerPage
	const currentItems = pageSize.slice(itemOffset, endOffset)
	// changePage(currentItems[0])

	const setPageCount = (str: string) => {
		if (str === 'prev' && pageC !== 0) {
			pageC = pageC - 1
			handlePageClick(pageC)
		}

		if (str === 'next' && pageC < pageCount - 1) {
			pageC = pageC + 1
			handlePageClick(pageC)
		}
	}

	// Invoke when user click to request another page.
	const handlePageClick = (p: number) => {
		const newOffset = (p * itemsPerPage) % pageSize.length
		setItemOffset(newOffset)
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: '5px' }}>
			<MoveButtonBox onClick={() => setPageCount('prev')}>
				<img src={prevIcon} />
			</MoveButtonBox>
			<Items page={page} changePage={changePage} currentItems={currentItems} />
			<MoveButtonBox onClick={() => setPageCount('next')}>
				<img src={nextIcon} />
			</MoveButtonBox>
		</div>
	)
}

export default PaginatedItems

const ClickedPageNumberBox = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 22px;
	height: 22px;

	/* content/line */

	border: 0.5px solid #425788;
	border-radius: 1px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	text-align: center;

	background-color: #425788;
	color: #ffffff;
`
const PageNumberBox = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 22px;
	height: 22px;

	/* content/line */

	border: 0.5px solid #425788;
	border-radius: 1px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	text-align: center;

	&:hover {
		cursor: pointer;
		background-color: #425788;
		color: #ffffff;
	}
`
const MoveButtonBox = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 22px;
	height: 22px;

	/* content/line */

	border: 0.5px solid #425788;
	border-radius: 1px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	text-align: center;

	&:hover {
		cursor: pointer;
	}

	/* &:{
    background-color : #425788;
    color : #ffffff
  } */
`
