import React, { useState, useEffect } from 'react'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import { TableListSection } from './styles'

import styled from 'styled-components'
import {
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	ButtonWrap,
	CreateButton,
	TableWrap,
	ContentWrap,
	ControllerWrap,
} from 'styles/globalPage-styles'
import { CancelButton } from '../BoardManage/styles'
import setting from '../../../../../assets/icons/setting.png'

import Editor from '../Custom/PostPage/editor'
import * as antd from 'antd'

const PostManagementTable = () => {
	const { data: token } = useToken()

	const [post, setPost] = useState<any>()

	const [boardid, setBoardid] = useState<any>(
		parseInt(window.location.pathname?.split('/')?.[3]?.split('?')[0]) || 0
	)
	const [postid, setPostid] = useState<any>(
		parseInt(window.location.pathname?.split('/')?.[4]?.split('?')[0]) || 0
	)
	const [edit, setEdit] = useState(false)

	const [content, setContent] = useState('')

	const [title, setTitle] = useState('')

	const [lock, setLock] = useState(false)
	const [password, setPassword] = useState<string | null>(null)
	const [boardname, setBoardname] = useState('')

	const initData = () => {
		if (post) {
			setContent(post.content)
			setTitle(post.title)
			setLock(post.lock)
			setPassword(post.password)
		}
	}

	const getPost = () => {
		setBoardid(parseInt(window.location.pathname?.split('/')?.[3]?.split('?')[0]) || 0)
		setPostid(parseInt(window.location.pathname?.split('/')?.[4]?.split('?')[0]) || 0)

		const name = decodeURI(decodeURIComponent(window.location.search?.split('=')[1]))
		setBoardname(name)
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		let apiurl = `/board/${boardPath}/${postid}`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setContent(res.result.result.content)
					setTitle(res.result.result.title)
					setPost(res.result.result)
					setLock(res.result.result.lock)
					setPassword(res.result.result.password)
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const updateData = () => {
		let data: any = {}

		if (post.content != content) {
			data = {
				...data,
				content,
			}
		}

		if (post.title != title) {
			data = {
				...data,
				title,
			}
		}

		if (post.lock != lock) {
			data = {
				...data,
				lock,
			}
		}

		if (post.password != password) {
			data = {
				...data,
				password,
			}
		}

		if (Object.keys(data).length < 1) return undefined
		return data
	}

	const validateData = () => {
		if ((lock && !password) || (password && password.length > 20)) {
			antd.message.error('게시글 비공개시 비밀번호를 1~20자 이내로 작성해 주세요')
			return false
		}

		if(!title || title.length < 1 || title.length > 100) {
			antd.message.error('제목은 1~100자 이내로 작성해 주세요')
			return false
		}

		if(!content || content.length < 1) {
			antd.message.error('내용을 작성해 주세요')
			return false
		}
		return true
	}

	const updatePost = () => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]

		let apiurl = `/board/${boardPath}/${postid}`

		const data: any = updateData()

		if (!data) {
			antd.message.info('수정사항이 없습니다')
			return
		}

		if (!validateData()) {
			return 
		}

		API.put(apiurl, token + '', data)
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정되었습니다')
					let tempPost = { ...post }
					tempPost['content'] = content
					tempPost['title'] = title
					tempPost['password'] = password
					tempPost['lock'] = lock
					setPost(tempPost)
					// setPost(null)
					// setContent(res.result.result.content)
					// setTitle(res.result.result.title)
					// // setPost(res.result.result)
					// setPassword(res.result.result.password)
					// setLock()
					setEdit(false)
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	useEffect(() => {
		if (!edit) initData()
	}, [edit])

	useEffect(() => {
		if (postid) getPost()
	}, [])

	return (
		<TableListSection>
			<ContentWrap>
				<TitleWrap>
					<TitleWrapLeft>
						<img src={setting} alt="icon"></img>
						<span style={{ fontSize: '20px', fontWeight: 'bold' }}>
							게시글
						</span>
					</TitleWrapLeft>
					<TitleWrapRight>
						<span>홈 &gt; 게시판설정 &gt; {boardname} &gt; 상세 </span>
					</TitleWrapRight>
				</TitleWrap>
				<ControllerWrap></ControllerWrap>
				<TableWrap>
					<DataRow>
						<TitleHeader>제목</TitleHeader>
						<Title>
							{
								!edit
								? decodeURIComponent(title)
								:<antd.Input value={decodeURIComponent(title)} onChange={(e)=>setTitle(e.target.value)}></antd.Input>
							}
						</Title>
					</DataRow>
					<ContentDataRow>
						<ContentHeader>내용</ContentHeader>
						{
							!edit
							? <Content dangerouslySetInnerHTML={{ __html: decodeURIComponent(content) }} ></Content>
							:						
							<Content style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'hidden' }}>
								<Editor content={decodeURIComponent(content)} edit={edit} setContent={setContent} />
							</Content>
						}

					</ContentDataRow>
					{/* <DataRow>
						<ContentHeader>내용</ContentHeader>
						<Content>
							{
								!edit
								? content
								: <ContentTextarea value={content} onChange={(e)=>setContent(e.target.value)}></ContentTextarea>
							}
						</Content>
					</DataRow> */}
				<DataRow>
					<TitleHeader>비공개</TitleHeader>
					<Title>
						{!edit ? (
							lock ? (
								'비공개'
							) : (
								'공개'
							)
						) : (
							<>
								<Lock>
									<input
										type="checkbox"
										checked={lock}
										onChange={e => {
											setLock(e.target.checked)
											if (!e.target.checked) setPassword(null)
										}}></input>
									<label style={{ whiteSpace: 'nowrap' }}>비공개</label>
								</Lock>
								<TitleHeader>비밀번호</TitleHeader>
								<Password>
									<antd.Input
										style={{ width: '100%', backgroundColor: lock ? 'white' : 'gray' }}
										type="password"
										value={password ? password : ''}
										readOnly={!lock}
										placeholder={!lock ? '잠금시 비밀번호 작성' : ''}
										onChange={e => setPassword(e.target.value)}></antd.Input>
									{/* <Input type="password" readOnly={!lock} onChange={(e)=>setPassword(e.target.value)} value={password}></Input> */}
								</Password>
							</>
						)}
					</Title>
				</DataRow>
			</TableWrap>
			<ButtonWrap style={{ margin: '0 auto' }}>
				{!edit ? (
					<CreateButton onClick={() => setEdit(true)}>수정</CreateButton>
				) : (
					<>
						<CreateButton onClick={updatePost}>저장</CreateButton>
						<CancelButton onClick={() => setEdit(false)}>취소</CancelButton>
					</>
				)}
			</ButtonWrap>
		</ContentWrap>
		</TableListSection>
	)
}

export default PostManagementTable

const ContentDataRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	min-height: 400px;

	/* border-top: 1px solid #E4E4E4; */
	width: 100%;
	/* width: 974px; */
	/* text-align: center; */
	font-size: 12px;
	border-width: 0px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
	height: 100%;
	background: #fafafa;
`

const DataRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;

	/* border-top: 1px solid #E4E4E4; */
	width: 100%;
	/* width: 974px; */
	/* text-align: center; */
	font-size: 12px;
	background: #ffffff;

	border-width: 0px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

const TitleHeader = styled.div`
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 150px;
	height: 46px;
	line-height: 46px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 0px 1px 0px;
	border-style: solid;
	border-color: #e4e4e4;
`

const Input = styled.input`
	width: 100%;

	border: 1px solid #cccccc;
	border-radius: 2px;

	&:focus {
		border: 1px solid #333333;
	}
`

const Title = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px;

	min-width: 100px;
	width: 824px;
	height: 46px;

	/* white */

	background: #ffffff;
	/* table/border */

	border-width: 1px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

const ContentHeader = styled.div`
	box-sizing: border-box;

	width: 150px;
	/* height: 400px; */
	/* height: auto; */
	/* table/bg */

	background: #fafafa;
	/* table/border */

	text-align: center;
	border-width: 0px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

const Content = styled.div`
	box-sizing: border-box;

	/* width: 90%; */

	padding: 10px 5px;
	width: 824px;
	min-height: 400px;
	/* white */

	background: #ffffff;
	/* table/border */

	border-width: 0px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

const ContentTextarea = styled.textarea`
	height: 100%;
	width: 100%;
	resize: none;
`

const Lock = styled.div`
	width: 150px;
	padding: 0 30px;
`

const Password = styled.div`
	width: 100%;
	padding: 5px;

	/* border-width: 1px 1px 1px 1px; */
	border-style: solid;
	border-color: #e4e4e4;
`
