import React, { useState } from 'react'
import { View } from 'styles/reactnative'
import PostManagementTable from './PostManagementTable'
import CommentManagementTable from '../Comment/CommentManagementTable'
import Header from '../../Header'
import Menu from '../../Menu'
import { useWindowDimensions } from 'app/hooks'
import MobileHeader from '../../MobileHeader'

type Props = {
	password?: boolean;
	setPassword?: React.Dispatch<React.SetStateAction<boolean>>
}
const PostManagement = ({ password, setPassword }: Props) => {
	const { width } = useWindowDimensions()
	const [path, setPath] = useState('')
	const [drawerOpen, setDrawerOpen] = useState(false)
	return (
		<View
			style={{
				flex: 1,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			{width > 1000 ? (
				<View style={{ width: '100%', alignItems: 'flex-start' }}>
					<Header />
					<Menu setPath={setPath} />
				</View>
			) : (
				<View style={{ width: '100%', alignItems: 'flex-start' }}>
					<MobileHeader
						otherPageStyle={true}
						setPath={setPath}
						drawerOpen={drawerOpen}
						setDrawerOpen={setDrawerOpen}
					/>
				</View>
			)}
			<>
			{
				password 
				&&  <PostManagementTable />
			}
			</>
			
			{/* <CommentManagementTable /> */}
		</View>
	)
}

export default PostManagement
