import React from 'react'
import KakaoMap from './Map'
import { CompanyInfo, CompanyName, InfoContainer, InfoTextWrap, InfoWrap, Title } from './styles'
import { useWindowDimensions } from 'app/hooks'

function Info() {
	const { width } = useWindowDimensions()
	return (
		<InfoContainer>
			<Title style={{ marginTop: 15 }}>Contact Us</Title>
			<InfoWrap>
				<KakaoMap />
				<InfoTextWrap>
					<CompanyName style={{ fontSize: 30 }}>씨아이랩스</CompanyName>
					<CompanyInfo>
						<p>031-901-0815</p>
						<p>devteam@cilabs.co.kr</p>
						<p>고양시 일산동구 성우사카르타워 404호</p>
					</CompanyInfo>
				</InfoTextWrap>
			</InfoWrap>
		</InfoContainer>
	)
}

export default Info
