import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { CToast } from 'app/components/Common'
import UserManagement from '../../pages/CMSPage/CMSBody/Users'
import { HeaderAdmin } from 'app/components'
import { Layout } from 'antd'
import { Header, SideBar } from 'app/organisms'
import SignupForm from 'app/pages/CMSPage/CMSBody/Users/SignupForm'
import API from 'utils/api'
import { useToken } from 'app/hooks'
const { Content } = Layout

export const UserPage = () => {
	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Helmet>
				<title>사용자관리</title>
			</Helmet>
			<CToast />
			<SideBar />
			<Layout className="site-layout" style={{ background: '#fff' }}>
				{/* <Header /> */}
				<HeaderAdmin />
				<Content>
					<UserManagement/>
				</Content>
			</Layout>
		</Layout>
	)
}
