import React, { useEffect, useState } from 'react'
import { Text, View } from 'styles/reactnative'

import { useToken, useUser } from 'app/hooks'
import SignupForm from 'app/pages/CMSPage/CMSBody/Users/SignupForm'
import API from 'utils/api'

const HeaderRightDetail = () => {
	const { data: username } = useUser()
	const { data: token, mutate: mutateToken } = useToken()
	const [myProfile, setMyProfile] = useState(false)
	const [data, setData] = useState<any>()

	const handleModal = () => {
		myProfile && setMyProfile(false)
	}

	const getMyProfile = () => {
		let apiurl = `/user/my/info`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setData(res.result.result)
				}
			})
			.catch((err: any) => {})
	} 

	const ProfileForm = (
		<SignupForm
			handleModal={handleModal}
			roles={[]}
			data={data}
			insertNewUser={()=>{}}
			userOnly={true}
		/>
	)

	useEffect(()=>{
		if(data)
			setMyProfile(true)
	},[data])

	return (
		<View style={{ cursor: 'pointer', marginRight: 10 }} onClick={() => {}}>
			<Text style={{ fontSize: 14 }} onClick={getMyProfile}>{username} 님</Text>
			{
				myProfile &&
				ProfileForm
			}
		</View>
	)
}

export default HeaderRightDetail
