import React, { useState } from 'react'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import { ButtonWrap, CreateButton, DataRowWrapHeader } from 'styles/globalPage-styles'
import {
	CancelButton,
	ContentColumn2,
	ContentColumn1,
	ContentColumn2Title,
	ContentColumn1Title,
	ContentWrap,
	RowWrap,
} from './styles'
import CIUtils from 'utils/ciutils'

import * as antd from 'antd'

type Props = {
	handleModal: () => void
	menus: any[]
	refreshList: () => void
	showModal?: boolean
}

function CreateForm({ showModal, handleModal, menus, refreshList }: Props) {
	const { data: token } = useToken()
	const [name, setName] = useState('')
	const [path, setPath] = useState('')
	const [iconType, setIconType] = useState('')

	const validate = () => {
		const find = menus.find(menu => menu.name === name || menu.path === path)

		if (find) return false

		return true
	}

	const create = () => {
		let apiurl = `/system/menus`

		if (!name || name.length > 20) {
			antd.message.error('메뉴명은 20자 이하로 작성해주세요')
			return
		}

		if (!CIUtils.validateEnglish(path)) {
			antd.message.error('경로는 20자 이하의 영문, 숫자로 작성해주세요')
			return
		}

		const validation = validate()

		if (!validation) {
			antd.message.error('메뉴명 또는 경로가 중복됩니다')
			return
		}

        API.post(apiurl, token + '', { name, path, order: menus.length, level: 1 })
        .then((res: any) => {
            if (res.result.success) {
				antd.message.success('등록되었습니다')
				refreshList()
				handleModal()
            }
        })
        .catch((err: any) => {
			console.log(err)        
		})
	}

	function settings(e: React.ChangeEvent<HTMLInputElement>) {
		const name = e.target.name
		const value = e.target.value

		switch (name){
			case 'name': setName(value); break;			
			case 'path': setPath(value); break;	
			case 'iconType' : setIconType(value); break;	
		}
	}

	return (
		<antd.Modal
			title={'메뉴등록'}
			footer={null}
			open={showModal}
			onOk={handleModal}
			onCancel={handleModal}
			width={420}>
			<ContentWrap>
				<DataRowWrapHeader>
					<ContentColumn1Title>구분</ContentColumn1Title>
					<ContentColumn2Title>내용</ContentColumn2Title>
				</DataRowWrapHeader>
				<RowWrap>
					<ContentColumn1>메뉴명</ContentColumn1>
					<ContentColumn2>
						<antd.Input
							type="text"
							name="name"
							onChange={settings}
							value={name}
							placeholder="메뉴명을 적어주세요"></antd.Input>
					</ContentColumn2>
				</RowWrap>
				<RowWrap>
					<ContentColumn1>경로</ContentColumn1>
					<ContentColumn2>
						<antd.Input
							type="text"
							name="path"
							onChange={settings}
							value={path}
							placeholder="경로를 적어주세요"></antd.Input>
					</ContentColumn2>
				</RowWrap>
			</ContentWrap>
			<ButtonWrap style={{ marginTop: 10 }}>
				<CreateButton onClick={create}>저장</CreateButton>
				<CancelButton onClick={handleModal}>취소</CancelButton>
			</ButtonWrap>
		</antd.Modal>
	)
}
export default CreateForm
