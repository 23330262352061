/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { useToken, useWindowDimensions } from 'app/hooks'
import HeaderRightSection from './HeaderRightSection'
import { View } from 'styles/reactnative'
import HeaderMenu from './HeaderMenu'
import { createUseStyles } from 'react-jss'
import API from 'utils/api'
import SignupForm from 'app/pages/CMSPage/CMSBody/Users/SignupForm'

type Props = {
	setShowModal?: React.Dispatch<React.SetStateAction<boolean>>
}
const HeaderAdmin = ({setShowModal}:Props) => {
	const { width } = useWindowDimensions()
	const [menus, setMenus] = useState([])
	const classes = useStyles()
	const { data: token } = useToken()

	const getMenus = async () => {
		try {
			const res = await API.send('GET', '/system/menus', null, {})
			if (res.result.success) {
				const mainMenu = res.result.result.filter(menu => menu.level === 1)
				let menuArr: any = []
				mainMenu.map((menu: any, index) => {
					const subMenu = res.result.result.filter(sub => menu.id === sub.parent_menuid)
					let data: any = []
					subMenu.map((item: any) => {
						data.push({
							name: item.name,
							path: `${menu.path}/${item.path}`,
						})
					})
					const menuData = {
						...menu,
						data,
					}
					menuArr.push(menuData)
				})

				setMenus(menuArr)
			}
		} catch (error) {}
	}


	useEffect(() => {
		getMenus()
	}, [])

	return (
		<View className={classes.headerSection}>
			<View className={classes.headerTop} style={{ paddingRight: width < 1200 ? 0 : 20 }}>
				<HeaderRightSection />
			</View>
			{token ? <HeaderMenu menus={menus} /> : null}
		</View>
	)
}

const useStyles = createUseStyles({
	headerSection: {
		width: '100%',
		height: 120,
		justifyContent: 'flex-start',
		borderBottom: '1px solid #eee',
	},
	headerTop: {
		width: '100%',
		height: 60,
		flexDirection: 'row',
		background: '#fff',
		alignSelf: 'flex-end',
		justifyContent: 'flex-end',
	},
})
export default HeaderAdmin
