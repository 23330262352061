import React from 'react'
import { View } from 'styles/reactnative'
import SystemSettingManagement from './System'
import RoleManagement from './Role'
import MenuManagement from './Menu'
import UserMenuManagementTable from './UserMenus'
import UserSettingManagement from './UserData'
import { useLocation } from 'react-router-dom'
import { useWindowDimensions } from 'app/hooks'

const SystemManagement = () => {
	const { height } = useWindowDimensions()
	const location = useLocation()
	const url = location.pathname
	const mainPath = url?.split('/')?.[2]
	const currentPath = url?.split('/')?.[3]

	return (
		<View
			style={{
				width: '100%',
				padding: 10,
				background: '#fff',
				paddingBottom: height / 3,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			{mainPath === 'system' && url?.split('/')?.[3] === undefined && <MenuManagement />}
			{currentPath === 'menu' && <MenuManagement />}
			{/* { path === 'board' && <BoardManagement />}  */}
			{currentPath === 'role' && <RoleManagement />}
			{currentPath === 'global' && <SystemSettingManagement />}
			{currentPath === 'usermenu' && <UserMenuManagementTable />}
			{currentPath === 'userdata' && <UserSettingManagement />}
		</View>
	)
}

export default SystemManagement
