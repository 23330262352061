import React, { useState } from 'react'
import { View } from 'styles/reactnative'
import MobileHeader from './MobileHeader'

import CustomComp from '../CustomComp'
import Info from '../Info'
import Footer from '../Footer'
import { useWindowDimensions } from 'app/hooks'

const CustomMobile = ({ path, setPath, customDatas }) => {
	const { width } = useWindowDimensions()
	const [currentPage, setCurrentPage] = useState(0)
	const [drawerOpen, setDrawerOpen] = useState(false)

	return (
		<View
			style={{
				width: '100%',
				height: '100% !important',
			}}>
			<MobileHeader
				currentPage={currentPage}
				path={path}
				setPath={setPath}
				drawerOpen={drawerOpen}
				setDrawerOpen={setDrawerOpen}
			/>

			{customDatas?.length ? (
				<CustomComp
					data={customDatas}
					path={path}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
				/>
			) : null}
			{path === 'contact' && (
				<div
					style={{
						width: '100%',
						height: width < 380 ? '100%' : '100vh',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}>
					<div
						style={{
							width: '100%',
							height: '60%',
						}}>
						<Info />
					</div>
					<div
						style={{
							width: '100%',
							height: '40%',
						}}>
						<Footer />
					</div>
				</div>
			)}
		</View>
	)
}

export default CustomMobile
