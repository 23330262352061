import React, { useState, useEffect } from 'react'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import { TitleWrap, TitleWrapLeft, CreateButton, TableWrap } from 'styles/globalPage-styles'
import moment from 'moment'
import styled from 'styled-components'
import * as antd from 'antd'
import CommentInput from './CommnetInput'

const CommentManagementTable = () => {
	console.log(window.location.pathname?.split('/'))
	const { data: token } = useToken()

	const [boardid, setBoardid] = useState<any>(
		parseInt(window.location.pathname?.split('/')?.[3]?.split('?')[0]) || 0
	)
	const [postid, setPostid] = useState<any>(
		parseInt(window.location.pathname?.split('/')?.[4]?.split('?')[0]) || 0
	)
    const [comments, setCommnets] = useState<any>([])
    const [edit, setEdit] = useState(false)
    const [editKey, setEditKey] = useState<number | null>(null)
    const [content, setContent] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    // const [content, setContent] = useState('')

	const getComments = () => {
		const boardPath = window.location.pathname?.split('/')?.[1]?.split('?')[0]
		// setBoardid(parseInt(window.location.pathname?.split('/')?.[3]?.split('?')[0]) || 0)
		setPostid(parseInt(window.location.pathname?.split('/')?.[2]?.split('?')[0]) || 0)
		const postide = parseInt(window.location.pathname?.split('/')?.[2]?.split('?')[0]) || 0

		let apiurl = `/board/${boardPath}/${postide}/comments?maxData=20&page=` + 1

        API.get(apiurl, token + '', {})
            .then((res: any) => {
                if (res.result.success) {
                    setCommnets(res.result.result.comments)
                    setTotalCount(res.result.result.totalCount)
                }
            })
            .catch((err: any) => {
                console.log(err)
            })
    }

	useEffect(() => {
		getComments()
	}, [])

	useEffect(() => {
		if (!refresh) {
			return
		}

		getComments()
		setRefresh(false)
	}, [refresh])

	const updateComment = (commentid: number) => {
		const boardPath = window.location.pathname?.split('/')?.[1]?.split('?')[0]

		let apiurl = `/board/${boardPath}/${postid}/comments/${commentid}`

		if (!content) {
			antd.message.info('내용을 입력해 주세요')
			return
		}

		API.put(apiurl, token + '', {
			content,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정되었습니다')
					setEditKey(null)
					setRefresh(true)
				}
			})
			.catch((err: any) => {
				// antd.message.error('error : ' + err)
			})
	}

	const createComment = (content: string, lock: boolean) => {

		const boardPath = window.location.pathname?.split('/')?.[1]?.split('?')[0]

		let apiurl = `/board/${boardPath}/${postid}/comments`

		if (!content) {
			antd.message.info('내용을 입력해 주세요')
			return
		}

		API.post(apiurl, token + '', {
			content,
			lock,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('등록되었습니다')
					setRefresh(true)
				}
			})
			.catch((err: any) => {
				//	antd.message.error('error : ' + err)
			})
	}

	const deleteComment = (id: number) => {
		const boardPath = window.location.pathname?.split('/')?.[1]?.split('?')[0]

		let apiurl = `/board/${boardPath}/${postid}/comments/${id}`

		API.delete(apiurl, token + '', {}).then(() => {
			antd.message.success('삭제완료')
			getComments()
		})
	}
	return (
        <CommentWrap>
			<TotalCount>
				{`댓글 (${totalCount})`}
			</TotalCount>
			<TableWrap>
				{comments.map((comment, key) => {
					return (
						<DataRow>
							<Info>
								<InfoItem style={{color: "#333333"}}>{comment?.writer}</InfoItem>
								<InfoItem style={{color: "#D6D6D6"}}>|</InfoItem>
								<InfoItem style={{color: "#828282"}}>
									{moment(comment?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD')}
								</InfoItem>
							</Info>
							<Content
								contentEditable={editKey === key ? true : false}
								onInput={e => setContent(e.currentTarget.innerText)}>
								{comment?.content}
							</Content>
						</DataRow>
					)
				})}
			</TableWrap>
			<CommentInput createComment={createComment} />
		</CommentWrap>
	)
}

export default CommentManagementTable

const CommentWrap = styled.div`
	width: 100%;
	max-width: 1200px;
	margin: 0 auto 150px auto;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0 10px;
`

const TotalCount = styled.div`
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	/* identical to box height */

	display: flex;
	align-items: center;

	/* base/text */

	color: #333333;

`
const DataRow = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;

	width: 974px;
	height: 63px;
`
const Info = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 10px;

	width: 100%;
`

const InfoItem = styled.span`
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	/* identical to box height */

	display: flex;
	align-items: center;
`
const ButtonWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 10px;

	/* width: 58px; */
	height: 17px;
`

const ModifyButton = styled.div`
	width: 35px;
	height: 17px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text-align: center; */

	/* content/accentBlue */

	color: #0a387d;
`

const DeleteButton = styled.div`
	width: 35px;
	height: 17px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text-align: center; */

	/* content/accentBlue */

	color: #d44242;
`
const Content = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	width: 100%;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;

	display: flex;
	align-items: center;

	color: #333333;
`