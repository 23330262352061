import React, { useState } from 'react'
import { HeaderButtonSection, HeaderButtonText } from './styles'
import HeaderDropdown from './HeaderDropdown'
import { useHeaderBtn } from '../../../app/hooks/stateStore/headerButton'
import { IconCaretDown, IconCaretUp } from '@tabler/icons'
import styled from 'styled-components'
import { DropdownContent } from './HeaderDropdown/styles'
import { act } from 'react-test-renderer'

interface IProps {
	label: string
	onPress?: () => void
	data?: any
	notIcon?: boolean
	active?: boolean
}

const HeaderButton = ({ label, onPress, data, notIcon, active }: IProps) => {
	const { data: headerBtn, mutate } = useHeaderBtn()
	const [hoverState, setHoverState] = useState(false)

	if (headerBtn?.hoverState === undefined) {
		mutate({
			hoverState: false,
		})
	}

	return (
		<NavigationButtonWrap
			style={
				active
					? {
							color: '#000000',
							backgroundColor: '#ffffff',
							fontWeight: 'bold',
					  }
					: {}
			}
			hover={headerBtn?.hoverState}
			onClick={ onPress }
			// onClick={data.length < 1 ? onPress : () => {}}
			onMouseOut={() => setHoverState(false)}
			onMouseOver={() => {
				mutate({
					hoverState: true,
				})
				setHoverState(true)
			}}>
			<NavigationButtonText>{label}</NavigationButtonText>
			{/* <HeaderDropdown data={data} /> */}
		</NavigationButtonWrap>
	)
}

export default React.memo(HeaderButton)

const NavigationButtonWrap = styled.div<{ hover?: boolean }>`
	/* Gnb/list */

	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	width: 116px;
	height: 50px;

	/* white */

	background: #2da0d2;
	/* main */

	border-top: 3px solid #2da0d2;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;

	color: #ffffff;

	&:hover {
		color: #000000;
		background-color: #ffffff;
		cursor: pointer;
		font-weight: bold;
	}

	${({ hover }) =>
		hover &&
		`&:hover ${DropdownContent} {
		display: block;
	}`}
`
const NavigationButtonText = styled.span`
	font-family: 'Noto Sans KR';
	font-size: 14px;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;
	letter-spacing: 1px;
`
