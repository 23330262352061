import styled from 'styled-components'

export const Name = styled.div`
	box-sizing: border-box;

	width: 210px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;
`

export const Date = styled.div`
	/* Auto layout */
	width: 200px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;
`

export const Active = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	/* table/border */
	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;
`
