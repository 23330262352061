import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import PaginatedItems from '../../components/Pagination'
import {
	Nocontent,
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	ButtonWrap,
	CreateButton,
	SDeleteButton,
	TableWrap,
	DataRowWrap,
	CheckboxWrap,
	NumberWrap,
	DeleteButtonWrap,
	DeleteButton,
	ControllerWrap,
	DataRowWrapHeader,
} from 'styles/globalPage-styles'
import setting from '../../../../../assets/icons/setting.png'
import PostForm from './PostForm'
import * as antd from 'antd'
import { Date, Name, Title } from './styles'
const BoardTable = () => {
	const navigate = useHistory()
	const { data: token } = useToken()

	const [create, setCreate] = useState(false)
	const [posts, setPosts] = useState<any[]>([])

	const [pageSize, setPageSize] = useState<number[]>([])
	const [page, setPage] = useState(1)
	const [boardPath, setBoardPath] = useState(
		window.location.pathname?.split('/')?.[3]?.split('?')[0]
	)
	const [checkedList, setCheckedList] = useState<any[]>([])
	const [boardname, setBoardname] = useState('')
	const [change, setChange] = useState(false)

	const changePage = (num: number) => {
		setPage(num)
	}

	const checked = (checked: boolean, id: number) => {
		let temp = [...checkedList]
		if (checked) {
			temp.push({ id: id })
		} else {
			temp = temp.filter(f => f.id !== id)
		}
		setCheckedList(temp)
	}

	const refreshList = () => {
		getPosts()
	}

	const deleteCheckedList = () => {
		if (checkedList?.length < 1) return

		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}

		checkedList?.map(c => {
			const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
			let apiurl = `/board/${boardPath}/${c.id}`

			return API.delete(apiurl, token + '', {})
				.then((res: any) => {
					if (res.result.success) {
					}
				})
				.catch((err: any) => {
					// toast('삭제에 실패하였습니다\n' + err)
				})
		})

		antd.message.success('삭제되었습니다')
		setCheckedList([])
		setChange(true)
	}
	useEffect(()=>{
		if(change) {
			getPosts()
			setChange(false)
		}
	},[change])


	const deletePost = (id: number) => {
		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}
		
		
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		let apiurl = `/board/${boardPath}/${id}`

		API.delete(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					refreshList()
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const getPosts = () => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		let apiurl = `/board/${boardPath}?maxData=20&page=` + 1

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setPageSize(Array.from({ length: res?.result?.result?.pageLength }, (v, i) => i + 1))
					setPosts(res?.result?.result?.posts)
					setBoardname(res?.result?.result?.boardname)
				}
			})
			.catch((err: any) => {
				//	antd.message.error(err + ' ')
			})
	}

	const goToPost = (postid: number) => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		const name = encodeURI(encodeURIComponent(boardname))
		navigate.push(`/admin/board/${boardPath}/${postid}?name=${name}`)
	}

	const handleModal = () => {
		setCreate(false)
	}

	useEffect(() => {
		getPosts()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, window.location.pathname])

	return (
		<div style={{ maxWidth: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
			{!create ? (
				<div>
					<TitleWrap>
						<TitleWrapLeft>
							<img src={setting} alt="icon"></img>
							<span style={{ fontSize: '20px', fontWeight: 'bold' }}>
								{boardname ? boardname : '게시판'}
							</span>
						</TitleWrapLeft>
						<TitleWrapRight>
							<span
								onClick={() => {
									navigate.push('/admin')
								}}>
								홈 &gt; 게시판설정 &gt; {boardname ? boardname : '게시판'}
							</span>
						</TitleWrapRight>
					</TitleWrap>
					<ControllerWrap>
						<ButtonWrap>
							<CreateButton onClick={() => setCreate(true)}>작성</CreateButton>
							<SDeleteButton onClick={deleteCheckedList}>선택삭제</SDeleteButton>
						</ButtonWrap>
					</ControllerWrap>
					<TableWrap>
						<DataRowWrapHeader>
							<CheckboxWrap>
								<input type="checkbox"></input>
							</CheckboxWrap>
							<NumberWrap>NO.</NumberWrap>
							<Title>제목</Title>
							<Name>작성자</Name>
							<Date>작성일시</Date>
							<DeleteButtonWrap>관리</DeleteButtonWrap>
						</DataRowWrapHeader>
						{posts?.map((post, index) => {
							console.log(post)
							return (
								<DataRowWrap key={index.toString()}>
									<CheckboxWrap>
										<input
											type="checkbox"
											onChange={e => checked(e.target.checked, post.id)}></input>
									</CheckboxWrap>
									<NumberWrap>{post.id}</NumberWrap>
									<Title onClick={() => goToPost(post?.id)}>{decodeURIComponent(post?.title)}</Title>
									<Name>{post?.writer}</Name>
									<Date>
										{moment(post?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}
									</Date>
									<DeleteButtonWrap>
										<DeleteButton onClick={() => deletePost(post?.id)}>삭제</DeleteButton>
									</DeleteButtonWrap>
								</DataRowWrap>
							)
						})}
						{posts?.length < 1 && <Nocontent>등록된 게시물이 없습니다</Nocontent>}
					</TableWrap>
					<PaginatedItems
						page={page}
						pageSize={pageSize}
						changePage={changePage}
						itemsPerPage={5}
					/>
				</div>
			) : (
				<PostForm boardid={Number(boardPath)} handleModal={handleModal} boardname={boardname} />
			)}
		</div>
	)
}

export default BoardTable
