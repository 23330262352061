import React from 'react'
import { View } from 'styles/reactnative'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'

import 'swiper/css'
import 'swiper/css/pagination'
import './styles.css'
const ProcessSlider = ({ item, currentPage }: any) => {
	const decodedContent: any = decodeURIComponent(item?.content)

	const imageUrls = decodedContent
		.match(/src="([^"]+)"/g)
		.map(match => match.replace('src="', '').replace('"', ''))

	const renderSlides = () => {
		return imageUrls.map((item, index) => (
			<SwiperSlide key={index} style={{ alignItems: 'flex-start' }}>
				<img
					src={item}
					alt="..."
					style={{
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						width: '100%',
						height: '100%',
						backgroundRepeat: 'no-repeat',
					}}
				/>
			</SwiperSlide>
		))
	}
	//  marginTop: currentPage === 4 ? -50 : 0
	return (
		<View style={{ width: '100vw' }}>
			<Swiper
				modules={[Pagination]}
				spaceBetween={1}
				slidesPerView={1}
				navigation
				pagination={{
					clickable: true,
				}}
				scrollbar={{ draggable: true }}
				onSwiper={swiper => console.log(swiper)}
				onSlideChange={() => console.log('slide change')}>
				{renderSlides()}
			</Swiper>
		</View>
	)
}

export default ProcessSlider
