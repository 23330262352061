import React, { useState } from 'react'
import prevIcon from '../../../../assets/icons/page_left_icon.png'
import nextIcon from '../../../../assets/icons/page_right_icon.png'
import PaginationItem from './PaginationItem'
import { MoveButtonBox } from './styles'

let pageC = 0

const PaginatedItems = ({ page, pageSize, changePage, itemsPerPage }) => {
	const pageCount = Math.ceil(pageSize.length / itemsPerPage)
	const [itemOffset, setItemOffset] = useState(0)

	const endOffset = itemOffset + itemsPerPage
	const currentItems = pageSize.slice(itemOffset, endOffset)

	const setPageCount = (str: string) => {
		if (str === 'prev' && pageC !== 0) {
			pageC = pageC - 1
			handlePageClick(pageC)
		}

		if (str === 'next' && pageC < pageCount - 1) {
			pageC = pageC + 1
			handlePageClick(pageC)
		}
	}

	// Invoke when user click to request another page.
	const handlePageClick = (p: number) => {
		const newOffset = (p * itemsPerPage) % pageSize.length
		setItemOffset(newOffset)
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: '5px' }}>
			<MoveButtonBox onClick={() => setPageCount('prev')}>
				<img src={prevIcon} alt={'...'} />
			</MoveButtonBox>

			{currentItems?.map((item: any, index: number) => (
				<PaginationItem key={index.toString()} item={item} page={page} setPage={changePage} />
			))}
			<MoveButtonBox onClick={() => setPageCount('next')}>
				<img src={nextIcon} alt={'...'} />
			</MoveButtonBox>
		</div>
	)
}

export default PaginatedItems
