/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import { TableListSection } from './styles'
import styled from 'styled-components'
import {
	TitleWrapLeft,
	TitleWrapRight,
	ButtonWrap,
	ControllerWrap,
} from 'styles/globalPage-styles'
import setting from '../../../../../assets/icons/setting.png'
import * as antd from 'antd'
import Editor from './editor'
import { ContentWrap } from '../../styles'
import moment from 'moment'
import PostForm from '../Board/PostForm'
import CommentManagementTable from '../Comment/CommentManagementTable'
import { useHistory } from 'react-router'

const PostManagementTable = () => {
	const { data: token } = useToken()

	const [post, setPost] = useState<any>()
	const [postid, setPostid] = useState<any>(
		parseInt(window.location.pathname?.split('/')?.[2]?.split('?')[0]) || 0
	)
	const [edit, setEdit] = useState(false)

	const [content, setContent] = useState('')

	const [title, setTitle] = useState('')
	const [writer, setWriter] = useState('')

	const [lock, setLock] = useState(false)
	const [password, setPassword] = useState<string | null>(null)
	const [modifyMode, setModifyMode] = useState(false)

	const [postdataChange, setPostdataChange] = useState(false)

	const navigation = useHistory()

	const hadleModifyMode = () => {
		setModifyMode(false)
	}

	const initData = () => {
		if (post) {
			setContent(post.content)
			setTitle(post.title)
			setLock(post.lock)
			setPassword(post.password)
			setWriter(post.writer)
		}
	}

	const getPost = () => {
		setPostid(parseInt(window.location.pathname?.split('/')?.[2]?.split('?')[0]) || 0)

		// const boardPath = window.location.pathname?.split('/')?.[2]?.split('?')[0]
		const boardPath = 'ideas'
		let apiurl = `/board/${boardPath}/${postid}`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setContent(res.result.result.content)
					setTitle(res.result.result.title)
					setPost(res.result.result)
					setLock(res.result.result.lock)
					setPassword(res.result.result.password)
					setWriter(res.result.result.writer)
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const validateData = () => {
		let data: any = {}

		if (post.content != content) {
			data = {
				...data,
				content,
			}
		}

		if (post.title != title) {
			data = {
				...data,
				title,
			}
		}

		if (post.lock != lock) {
			data = {
				...data,
				lock,
			}
		}

		if (post.password != password) {
			data = {
				...data,
				password,
			}
		}
		if (Object.keys(data).length < 1) return undefined
		return data
	}

	const updatePost = () => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]

		let apiurl = `/board/${boardPath}/${postid}`

		const data: any = validateData()

		if (!data) {
			antd.message.info('수정사항없음')
			return
		}

		API.put(apiurl, token + '', data)
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정되었습니다')
					let tempPost = { ...post }
					tempPost['content'] = content
					tempPost['title'] = title
					tempPost['password'] = password
					tempPost['lock'] = lock
					setPost(tempPost)
					// setPost(null)
					// setContent(res.result.result.content)
					// setTitle(res.result.result.title)
					// // setPost(res.result.result)
					// setPassword(res.result.result.password)
					// setLock()
					setEdit(false)
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const deletePost = () => {
		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) return

		const boardPath = 'ideas'
		let apiurl = `/board/${boardPath}/${post?.id}`

		API.delete(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('삭제되었습니다')
					navigation.push('/ideas')
				}
			})
			.catch((err: any) => {})
	}

	useEffect(() => {
		if (postdataChange) {
			getPost()
			setPostdataChange(false)
		}
	}, [postdataChange])

	useEffect(() => {
		if (!edit) initData()
	}, [edit])

	useEffect(() => {
		if (postid) getPost()
	}, [])

	return (
		<TableListSection>
			{
				!modifyMode
				?<ContentWrap>
				<TitleWrap>
						<MainTitle>
							Ideas
						</MainTitle>
						<SubTitle>
							생각만 하고 있던 아이디어를 전문 개발팀이 실현시켜드립니다
						</SubTitle>
				</TitleWrap>
				<TableWrap>
					<DataRow>
						<Title>
							{decodeURIComponent(title)}
						</Title>
						<Info>
							<Writer>
								{writer}
							</Writer>
							<Writer>
								|
							</Writer>
							<Writer>
								{moment(post?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD')}
							</Writer>
						</Info>
					</DataRow>
					<DataRow>
						<Content dangerouslySetInnerHTML={{ __html: decodeURIComponent(content) }} ></Content>
					</DataRow>
				</TableWrap>
				<ButtonWrap style={{ margin: '0 auto' }}>
					{
					!edit
					? 
					<>
					<CreateButton onClick={()=>setModifyMode(true)}>수정하기</CreateButton>
					<CancelButton onClick={deletePost}>삭제하기</CancelButton>	
					</>
					:<>
						<CreateButton onClick={updatePost}>등록하기</CreateButton>
						<CancelButton onClick={()=>setEdit(false)}>취소</CancelButton>		
					</>
					} 
				</ButtonWrap>
			<CommentManagementTable />
			</ContentWrap>
				: <PostForm handleModal={hadleModifyMode} data={post} setPostdataChange={setPostdataChange}/>
			}

		</TableListSection>
	)
}

export default PostManagementTable

const TitleWrap = styled.div`
	width: 100%;
	max-width: 1320px;
	text-align: center;
	margin: 90px auto 80px auto;

	background-color: #ffffff;
`

const MainTitle = styled.div`
	width: 100%;
	
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 60px;
	/* identical to box height, or 117% */

	text-align: center;
	letter-spacing: -0.015em;

	color: #000000;
`

const SubTitle = styled.div`
	width: 100%;

	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 26px;
	/* identical to box height, or 144% */

	text-align: center;
	letter-spacing: -0.015em;

	color: #333333;
`
const TableWrap = styled.div`
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: 100%;
	margin-bottom: 16px;
	padding: 0 10px;
`
const DataRow = styled.div`
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 30px 0;

	width: 100%;

	/* color/line */

	border-width: 1px 0px;
	border-style: solid;
	border-color: #F0F0F0;
`

const Title = styled.div`
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	/* identical to box height */

	display: flex;
	align-items: center;

	/* base/text */

	color: #333333;
`

const Info = styled.div`
	display: flex;
	gap: 10px; 
`

const Writer = styled.span`
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	display: flex;
	align-items: center;

	color: #828282;
`



const ContentDataRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	min-height: 400px;

	/* border-top: 1px solid #E4E4E4; */
	width: 100%;
	/* width: 974px; */
	/* text-align: center; */
	font-size: 12px;
	border-width: 0px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
	height: 100%;
	background: #fafafa;
`

const CreateButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;

	width: 137px;
	height: 49px;

	background: #333333;
	border-radius: 1px;
	/* border-color: #333333; */

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	display: flex;
	align-items: center;

	color: #FFFFFF;

	&:hover{
		cursor: pointer;
	}
`

const CancelButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;

	width: 137px;
	height: 49px;

	border: 0.5px solid #333333;
	border-radius: 1px;

	/* border-color: #333333; */
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	align-items: center;

	color: #333333;

	&:hover{
		cursor: pointer;
	}

`

const TitleHeader = styled.div`
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 150px;
	height: 46px;
	line-height: 46px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 0px 1px 0px;
	border-style: solid;
	border-color: #e4e4e4;
`


const ContentHeader = styled.div`
	box-sizing: border-box;

	width: 150px;
	/* height: 400px; */
	/* height: auto; */
	/* table/bg */

	background: #fafafa;
	/* table/border */

	text-align: center;
	border-width: 0px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

const Content = styled.div`
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	display: flex;
	/* align-items: center; */

	min-height: 300px;


	/* base/text */

	color: #333333;
`

const Lock = styled.div`
	width: 150px;
	padding: 0 30px;
`

const Password = styled.div`
	width: 100%;
	padding: 5px;

	/* border-width: 1px 1px 1px 1px; */
	border-style: solid;
	border-color: #e4e4e4;
`
