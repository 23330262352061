import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'styles/reactnative'
import { COLORS } from 'app/constants/STYLES'

import {
	SectionContainer,
	SectionWrap,
	LoginWrapper,
	LoginSubContainer,
	BtnContainer,
	LoginWidth,
	Divider,
} from './styles'
import { BtnApple, BtnGoogle, LoginHeader } from './AuthContainers'
import LoginForm from './Forms/LoginForm'
import VerifySection from './VerifySection'
import { ThemeContext } from 'theme/ThemeProvider'

const LoginSection = () => {
	const { isDarkMode } = React.useContext(ThemeContext)
	const [loginSuccess, setLoginSuccess] = useState('')
	const { t } = useTranslation()
	const background = isDarkMode ? COLORS.white : 'rgba(52, 49, 64, 1)'
	return (
		<SectionContainer>
			<LoginForm setLoginSuccess={setLoginSuccess} />

		</SectionContainer>
	)
}

export default React.memo(LoginSection)
