import { Layout, Menu } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Text, View } from 'styles/reactnative'
import API from 'utils/api'
import { useWindowDimensions } from 'app/hooks'
import HeaderLogo from 'app/components/HeaderAdmin/HeaderLogo'
import { FirstSider, SecondSider } from './styles'
import { CustomIcon } from 'app/components/Common'
import styled from 'styled-components'
import icon from '../../../assets/icons/setting.png'

const { Sider } = Layout

const SideBar = () => {
	const { width } = useWindowDimensions()
	const navigation = useHistory()
	const location = useLocation()
	const mainPath = location.pathname?.split('/')?.[2]

	const [collapsed, setCollapsed] = useState(false)
	const [submenus, setSubmenus] = useState<any[]>([])
	const [selectedMenu, setSelectedMenu] = useState('')
	const [skip, setSkip] = useState(false)

	useEffect(() => {
		if (width < 900) {
			setCollapsed(true)
		} else if (width > 901) {
			setCollapsed(false)
		}
	}, [width])
	const getMenus = useCallback(async () => {
		if (mainPath !== 'board') {
			try {
				const res = await API.send('GET', '/system/menus', null, {})
				if (res.result.success) {
					const mainMenu = res.result.result.find(d => d.path === mainPath)
					const subMenu = res.result.result.filter(sub => sub.parent_menuid === mainMenu.id)
					let temp: any = []
					subMenu?.map(menu => {
						temp.push({ ...menu, selected: false })
					})
					setSubmenus(temp)
				} else {
				}
			} catch (error) {}
		} else {
			try {
				const res = await API.send('GET', '/system/boards', null, {})
				if (res.result.success) {
					console.log(res.result.result)
					const temp = [{ path: '', name: '대시보드' }, ...res.result.result]
					setSubmenus(temp)
				} else {
				}
			} catch (error) {}
		}
	}, [mainPath])

	useEffect(() => {
		// if (submenus && submenus?.length > 0 && window.location.pathname?.split('/')?.[3]?.split('?')[0] !== '') {
		// 	navigate.push(`/admin/${window.location.pathname?.split('/')?.[2]?.split('?')[0]}/${submenus[0].path}`)
		// }
		if (
			window.location.pathname?.split('/')?.[2]?.split('?')[0] !== 'board' &&
			submenus?.length > 0 &&
			!skip
		) {
			navigation.push(
				`/admin/${window.location.pathname?.split('/')?.[2]?.split('?')[0]}/${submenus[0].path}`
			)
			setSkip(true)
		}
	}, [submenus])

	useEffect(() => {
		getMenus()
	}, [getMenus])

	const goTos = goTo => {
		navigation.push(goTo)
	}
	const MenuText = (goTo, text: any, children: any) => {
		return <Text onClick={() => goTos(goTo)}>{text}</Text>
	}

	const menuItems = submenus.map((item: any, index: any) => {
		return {
			key: `${index + 1}`,
			label: MenuText(`/admin/${mainPath}/${item.path}`, item?.name, false),
			icon: <CustomIcon type="SettingOutlined" />,
		}
	})
	const dataExist = menuItems?.length

	const goTo = (path: string) => {
		navigation.push(`/admin/${window.location.pathname?.split('/')?.[2]?.split('?')[0]}/${path}`)
	}

	const setting = (sub: any, index: number) => {
		let temp: any = []

		submenus?.map((menu, i) => {
			if (i !== index) temp.push({ ...menu, selected: false })
			else temp.push({ ...menu, selected: true })
		})
		setSkip(true)
		setSubmenus(temp)
		goTo(sub.path)
	}

	return (
		<View
			style={{
				// background: dataExist ? '#EFEFEF' : '#fff',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<View
				style={{
					height: 120,
					width: '100%',
					background: 'rgba(0, 0, 0, 0.2)',
					justifyContent: 'flex-start',
				}}>
				<View style={{ height: 59, width: '100%', background: '#fff' }}>
					<View style={{ position: 'absolute', left: 20 }}>
						<HeaderLogo />
					</View>
				</View>
				<View style={{ height: 60, background: '#2DA0D2', width: '100%' }} />
			</View>
			<View style={{ display: width < 900 ? 'none' : 'block' }}>

				
				{
					<div>
						{submenus?.map((sub, index) => {
							console.log(window.location.pathname?.split('/')?.[3]?.split('?')[0])
							if (
								sub.path == window.location.pathname?.split('/')?.[3]?.split('?')[0] ||
								('board' == window.location.pathname?.split('/')?.[2]?.split('?')[0] &&
									undefined == window.location.pathname?.split('/')?.[3]?.split('?')[0] &&
									index === 0)
							) {
								return <ClickedMenuWrap>
									<img src={icon} style={{width: "15px"}} />
									<div style={{width: "100%", textAlign:"center"}}>{sub.name}</div>
									</ClickedMenuWrap>
							} else {
								return <MenuWrap onClick={() => setting(sub, index)}>
									<img src={icon} style={{width: "15px"}} />
									<div style={{width: "100%", textAlign:"center"}}>{sub.name}</div>
									</MenuWrap>
							}
						})}
						{/* {
							submenus?.map((menu)=>{
								if (!menu?.seleted) {
									return <div style={{textAlign : "center", height: "50px", padding: "11px"}}
									onClick={()=>{navigation.push(`/admin/${mainPath}/${menu.path}`)}}>
										{menu?.name}

									</div>
								}else {
									return <div style={{textAlign : "center", height: "50px", padding: "11px", backgroundColor:"white"}}
									onClick={()=>{navigation.push(`/admin/${mainPath}/${menu.path}`)}}>
										{menu?.name}
									</div>
								}
							})
						} */}
					</div>
				}
				{dataExist ? (
					<FirstSider>
						<Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
							{/* <Menu defaultSelectedKeys={['1']} mode="inline" items={menuItems} /> */}
						</Sider>
					</FirstSider>
				) : (
					<SecondSider>
						<Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
							{/* <Menu defaultSelectedKeys={['1']} mode="inline" items={[]} /> */}
						</Sider>
					</SecondSider>
				)}
			</View>
		</View>
	)
}

export default SideBar

const MenuListWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`

const ClickedMenuWrap = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0px 20px;

	width: 200px;
	height: 50px;

	background: #ffffff;
	/* bg/primary */

	/* border-left: 3px solid #2E3031; */

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 50px;

	/* text/primary */

	color: #333333;
	/* border-bottom: 0.5px solid #cccccc; */
`

const MenuWrap = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 20px;

	width: 200px;
	height: 50px;

	/* stroke */

	/* border-bottom: 0.5px solid #cccccc; */

	/* background: #FFFFFF; */
	/* bg/primary */

	/* border-left: 3px solid #2E3031; */

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 50px;

	/* text/primary */

	color: #333333;
`