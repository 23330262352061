import React from 'react'
import { useRef, useEffect } from 'react'
import { MapContainer } from './styles'

declare global {
	interface Window {
		kakao: any
	}
}

function KakaoMap() {
	const { kakao } = window
	const ref = useRef(null)

	const map = async () => {
		const container = ref.current
		const mapOption = {
			center: new kakao.maps.LatLng(37.664, 126.767),
			level: 3,
		}
		const map = new kakao.maps.Map(container, mapOption)

		const markerPosition = new kakao.maps.LatLng(37.664, 126.767)
		const marker = new kakao.maps.Marker({
			position: markerPosition,
		})
		marker.setMap(map)
	}

	useEffect(() => {
		map()
	}, [])

	return (
		<MapContainer>
			<div
				className="kakao-map-container"
				id="map"
				ref={ref}
				style={{ width: '100%', height: '100%', zIndex: 0 }}
			/>
		</MapContainer>
	)
}

export default KakaoMap
