import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import { ButtonWrap, CreateButton, DataInputWrap } from 'styles/globalPage-styles'
import { CancelButton } from '../BoardManage/styles'

import * as antd from 'antd'
import type { DatePickerProps } from 'antd'
import { View } from 'styles/reactnative'

type Props = {
	handleModal: () => void
	popupData?: any
	refreshList?: () => void
	showModal?: boolean
}

function PopupForm({ showModal, handleModal, popupData, refreshList }: Props) {
	const { data: token } = useToken()

	const [title, setTitle] = useState(popupData ? popupData.title : '')
	const [content, setContent] = useState(popupData ? popupData.content : '')
	// const [imgSrc, setImgSrc] = useState(popupData ? popupData.img_src : popupData.img_src)
	const [width, setWidth] = useState(popupData ? popupData.width : 0)
	const [height, setHeight] = useState(popupData ? popupData.height : 0)
	const [locationX, setLocationX] = useState(popupData ? popupData.location_x : 0)
	const [locationY, setLocationY] = useState(popupData ? popupData.location_y : 0)
	const [mobile, setMobile] = useState(popupData ? popupData.mobile : false)
	const [active, setActive] = useState(popupData ? popupData.active : false)
	const [endedAt, setEndedAt] = useState(
		popupData ? moment(popupData?.ended_at).tz('Asia/Seoul').format('YYYY-MM-DD') : ''
	)
	const [edit, setEdit] = useState(false)

	const initData = () => {
		if (popupData) {
			setTitle(popupData.title)
			setContent(popupData.content)
			// setImgSrc(popupData.img_src)
			setWidth(popupData.width)
			setHeight(popupData.height)
			setLocationX(popupData.location_x)
			setLocationY(popupData.location_y)
			setMobile(popupData.mobile)
			setActive(popupData.active)
			setEndedAt(popupData.ended_at)
		}
	}

	const dateChange: DatePickerProps['onChange'] = (date, dateString) => {
		// console.log(date, dateString);
		setEndedAt(dateString)
	}

	function settings(e: React.ChangeEvent<HTMLInputElement>) {
		switch (e.target.name) {
			case 'title':
				setTitle(e.target.value)
				break
			case 'content':
				setContent(e.target.value)
				break
			// case 'imgSrc':
			// 	setImgSrc(e.target.value)
			// 	break
			case 'width':
				if (isNaN(Number(e.target.value))) break
				setWidth(Number(e.target.value))
				break
			case 'height':
				if (isNaN(Number(e.target.value))) break
				setHeight(Number(e.target.value))
				break
			case 'locationX':
				if (isNaN(Number(e.target.value))) break
				setLocationX(Number(e.target.value))
				break
			case 'locationY':
				if (isNaN(Number(e.target.value))) break
				setLocationY(Number(e.target.value))
				break
			case 'mobile':
				setMobile(e.target.checked)
				break
			case 'active':
				if (e.target.value == 'false') setActive(false)
				else setActive(true)
				break
			case 'endedAt':
				setEndedAt(e.target.value)
				break
		}
	}
	const create = () => {
		let apiurl = `/popup`

		if (!title || (title && title.length > 50)) {
			antd.message.error('타이틀은 1~50자 이내로 설정해주세요')
			return
		}

		if (!content) {
			antd.message.error('내용을 설정해주세요')
			return
		}

		if (!width || !height || isNaN(Number(width)) || isNaN(Number(height))) {
			antd.message.error('가로, 세로의 크기는 1이상의 숫자만 입력해주세요')
			return
		}

		if (isNaN(Number(locationX)) || isNaN(Number(locationY))) {
			antd.message.error('위치는 숫자만 입력해주세요')
			return
		}

		const today = new Date()
		const endd = new Date(endedAt)
		if (!endedAt || endd < today) {
			antd.message.error('날짜를 확인해주세요')
			return
		}

		API.post(apiurl, token + '', {
			title,
			width,
			height,
			locationX,
			locationY,
			content,
			// img_src: imgSrc,
			mobile,
			active,
			endedAt,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('등록완료')
					refreshList && refreshList()
					handleModal()
				}
			})
			.catch((err: any) => {})
	}

	const validate = () => {
		let updateData: any = {}

		if (popupData.title !== title) updateData = { ...updateData, title }
		if (popupData.content !== content) updateData = { ...updateData, content }
		// if (popupData.imgSrc != imgSrc) updateData = { ...updateData, img_src: imgSrc }
		// console.log(popupData.imgSrc, imgSrc)
		if (popupData.width !== width) updateData = { ...updateData, width }
		if (popupData.height !== height) updateData = { ...updateData, height }
		if (popupData.location_x !== locationX) updateData = { ...updateData, location_x: locationX }
		if (popupData.location_y !== locationY) updateData = { ...updateData, location_y: locationY }
		if (popupData.mobile !== mobile) updateData = { ...updateData, mobile }
		if (popupData.active !== active) updateData = { ...updateData, active }
			const end1 = moment(popupData?.ended_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')
			const end2 = moment(endedAt).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')
		if (end1 !== end2) updateData = { ...updateData, ended_At: endedAt }

		const keys = Object.keys(updateData)
		if (keys.length < 1) return undefined
		return updateData
	}

	const activeChange = (checked: boolean) => {
		setActive(checked)
	}

	const mobileChange = (checked: boolean) => {
		setMobile(checked)
	}

	const update = () => {
		let apiurl = `/popup/${popupData.id}`

		const updateData = validate()
		
		if (!updateData) {
			antd.message.error('수정사항이 없습니다')
			return
		}

		if (!title || (title && title.length > 50)) {
			antd.message.error('타이틀은 1~50자 이내로 설정해주세요')
			return
		}

		if (!content) {
			antd.message.error('내용을 설정해주세요')
			return
		}

		if (!width || !height || isNaN(Number(width)) || isNaN(Number(height))) {
			antd.message.error('가로, 세로의 크기는 1이상의 숫자만 입력해주세요')
			return
		}

		if (isNaN(Number(locationX)) || isNaN(Number(locationY))) {
			antd.message.error('위치는 숫자만 입력해주세요')
			return
		}

		API.put(apiurl, token + '', {
			updateData,
		})
			.then((res: any) => {
				if (res.result.success) {
					refreshList && refreshList()
					antd.message.success('수정완료')
					handleModal()
				}
			})
			.catch((err: any) => {})
	}

	useEffect(() => {
		if (!edit) initData()
	}, [edit])

	return (
		<antd.Modal
			title={!edit ? '팝업 정보' : '팝업 정보 수정'}
			footer={null}
			open={showModal}
			onOk={handleModal}
			onCancel={handleModal}
			width={420}>
			<TableContainer>
				<RowData>
					<RowDataNameHeader>구분</RowDataNameHeader>
					<RowDataContentHeader>내용</RowDataContentHeader>
				</RowData>
				<DataInputWrap>
					<RowDataName>타이틀</RowDataName>
					<RowDataContent>
						{!popupData ? (
							<antd.Input type="text" name="title" onChange={settings} value={title}></antd.Input>
						) : !edit ? (
							title
						) : (
							<antd.Input type="text" name="title" onChange={settings} value={title}></antd.Input>
						)}
					</RowDataContent>
				</DataInputWrap>
				<DataInputWrap>
					<RowDataName>내용</RowDataName>
					<RowDataContent>
						{!popupData ? (
							<antd.Input
								type="text"
								name="content"
								onChange={settings}
								value={content}></antd.Input>
						) : !edit ? (
							content
						) : (
							<antd.Input
								type="text"
								name="content"
								onChange={settings}
								value={content}></antd.Input>
						)}
					</RowDataContent>
				</DataInputWrap>
				{/* <DataInputWrap>
					<RowDataName>이미지</RowDataName>
					<RowDataContent>
						{!popupData ? (
							<antd.Input type="text" name="imgSrc" onChange={settings} value={imgSrc}></antd.Input>
						) : !edit ? (
							imgSrc
						) : (
							<antd.Input type="text" name="imgSrc" onChange={settings} value={imgSrc}></antd.Input>
						)}
					</RowDataContent>
				</DataInputWrap> */}
				<DataInputWrap>
					<RowDataName>사이즈</RowDataName>
					<RowDataContent>
						{!popupData ? (
							<antd.Space>
								<div style={{ width: '30px', textAlign: 'center' }}>가로</div>
								<antd.Input type="text" name="width" onChange={settings} value={width}></antd.Input>
								<div style={{ width: '30px', textAlign: 'center' }}>세로</div>
								<antd.Input
									type="text"
									name="height"
									onChange={settings}
									value={height}></antd.Input>
							</antd.Space>
						) : !edit ? (
							`가로 ${width}px / 세로 ${height}px`
						) : (
							<antd.Space>
								<div style={{ width: '30px', textAlign: 'center' }}>가로</div>
								<antd.Input type="text" name="width" onChange={settings} value={width}></antd.Input>
								<div style={{ width: '30px', textAlign: 'center' }}>세로</div>
								<antd.Input
									type="text"
									name="height"
									onChange={settings}
									value={height}></antd.Input>
							</antd.Space>
						)}
					</RowDataContent>
				</DataInputWrap>
				<DataInputWrap>
					<RowDataName>x위치</RowDataName>
					<RowDataContent>
						{!popupData ? (
							<antd.Space>
								<div style={{ width: '30px', textAlign: 'center' }}>x값</div>
								<antd.Input
									type="text"
									name="locationX"
									onChange={settings}
									value={locationX}></antd.Input>
								<div style={{ width: '30px', textAlign: 'center' }}>y값</div>
								<antd.Input
									type="text"
									name="locationY"
									onChange={settings}
									value={locationY}></antd.Input>
							</antd.Space>
						) : !edit ? (
							`X : ${locationX} / Y : ${locationY}`
						) : (
							<antd.Space>
								<div style={{ width: '30px', textAlign: 'center' }}>X값</div>
								<antd.Input
									type="text"
									name="locationX"
									onChange={settings}
									value={locationX}></antd.Input>
								<div style={{ width: '30px', textAlign: 'center' }}>Y값</div>
								<antd.Input
									type="text"
									name="locationY"
									onChange={settings}
									value={locationY}></antd.Input>
							</antd.Space>
						)}
					</RowDataContent>
				</DataInputWrap>
				<DataInputWrap>
					<RowDataName>모바일사용</RowDataName>
					<RowDataContent>
						{!popupData ? (
							<antd.Switch size="small" onChange={mobileChange} checked={mobile}></antd.Switch>
						) : !edit ? (
							mobile ? (
								'사용'
							) : (
								'미사용'
							)
						) : (
							<antd.Switch size="small" onChange={mobileChange} checked={mobile}></antd.Switch>
						)}
					</RowDataContent>
				</DataInputWrap>
				<DataInputWrap>
					<RowDataName>활성화</RowDataName>
					<RowDataContent>
						{!popupData ? (
							<antd.Switch size="small" onChange={activeChange}></antd.Switch>
						) : !edit ? (
							active ? (
								'활성화'
							) : (
								'비활성화'
							)
						) : (
							<antd.Switch size="small" onChange={activeChange} checked={active}></antd.Switch>
						)}
					</RowDataContent>
				</DataInputWrap>
				<DataInputWrap>
					<RowDataName>종료일</RowDataName>
					<RowDataContent>
						{!popupData ? (
							<antd.DatePicker name="endedAt" onChange={dateChange}></antd.DatePicker>
						) : !edit ? (
							moment(endedAt).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')
						) : (
							<antd.DatePicker name="endedAt" onChange={dateChange}></antd.DatePicker>
						)}
					</RowDataContent>
				</DataInputWrap>
			</TableContainer>
			<View style={{ marginTop: 10 }}>
				{!popupData ? (
					<ButtonWrap>
						<CreateButton onClick={create}>저장</CreateButton>
						<CancelButton onClick={handleModal}>취소</CancelButton>
					</ButtonWrap>
				) : !edit ? (
					<ButtonWrap>
						<CreateButton onClick={() => setEdit(true)}>수정</CreateButton>
					</ButtonWrap>
				) : (
					<ButtonWrap>
						<CreateButton onClick={update}>저장</CreateButton>
						<CancelButton onClick={() => setEdit(false)}>취소</CancelButton>
					</ButtonWrap>
				)}
			</View>
		</antd.Modal>
	)
}

export default PopupForm

const Modal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 20px;
	gap: 16px;

	position: absolute;
	width: 420px;
	left: 50%;
	top: 15%;
	margin-left: -210px;

	/* white */

	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(88, 88, 88, 0.25);
	border-radius: 2px;
`

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	gap: 26px;

	width: 380px;
`

const Title = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 4px;

	/* width: 85px; */
	height: 23px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;

	/* text/primary */

	color: #333333;
`
const TableContainer = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	width: 380px;
	/* height: 324px; */

	/* table/border */

	/* border: 1px solid #E4E4E4; */
`

const RowData = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	width: 380px;
	height: 36px;

	background: #ffffff;
`

const RowDataNameHeader = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 36px;
	line-height: 36px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`

const RowDataContentHeader = styled.div`
	box-sizing: border-box;

	width: 280px;
	height: 36px;
	line-height: 36px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`

const RowDataName = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 50px;
	line-height: 50px;

	background: #ffffff;
	/* table/border */

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`

const RowDataContent = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	/* flex-direction: column; */
	align-items: flex-start;
	padding: 0px;
	align-items: center;
	justify-content: space-between;
	width: 280px;
	height: 50px;

	padding: 0 5px;
	background: #ffffff;
	/* table/border */

	border-width: 0px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text/contents */

	color: #666666;
`

const Input = styled.input`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 10px;

	width: 100%;
	height: 26px;

	/* stroke */

	border: 1px solid #cccccc;
	border-radius: 2px;

	&:focus {
		border: 1px solid #333333;
	}
`

const SizeInput = styled.input`
	width: 86px;
	height: 26px;

	/* stroke */

	border: 1px solid #cccccc;
	border-radius: 2px;
`
