import styled from 'styled-components'

export const CDrawerSection = styled.div<{ display?: string }>`
	position: absolute;
	top: 0px;
	left: 0%;
	width: 420px;
	height: 100%;
	background-color: ${props => props.theme.COLORS?.drawerBgColor};
	position: fixed;
	z-index: 9999 !important;
	box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
	transform: translateX(0);
	transition: transform 0.3s ease-out;
	${({ display }) =>
		display === 'none' &&
		`
      transform: translateX(-100%); /* changed it */
    `}
`

export const DrawerHeaderSection = styled.div`
	display: flex;
	height: 50px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding-inline: 15px;
`
export const DrawerCloseIcon = styled.button`
	background-color: transparent;
	border: none;
	position: absolute;
	top: 10px;
	right: 15px;
	border-radius: 50px;
	padding: 5px;
	cursor: pointer;
	&:hover {
		background-color: ${props => props.theme.COLORS.gray};
	}
`
