import React from 'react'
import { CustomBtn, MobileAppName, MobileHeaderSection } from './styles'
import { View } from 'styles/reactnative'
import { CButton } from 'app/components/Common'
import { IconMenu, IconX } from '@tabler/icons'

import './mobileHeader.css'
import MobileDrawerContainer from './MobileDrawerContainer'
import { useHistory } from 'react-router-dom'
const MobileHeader = ({
	path,
	currentPage,
	setPath,
	drawerOpen,
	setDrawerOpen,
	otherPageStyle,
}: any) => {
	const navigate = useHistory()
	const handleToggleDrawer = () => {
		setDrawerOpen(!drawerOpen)
	}

	const headerColor = currentPage > 0 ? '#000' : '#fff'
	return (
		<MobileHeaderSection
			style={{
				background: path === 'home' && currentPage !== 0 ? 'rgba(255,255,255,0.3)' : 'transparent',
			}}>
			<View>
				<MobileAppName
					onClick={() => {
						navigate.push('/')
					}}
					style={{
						color: path !== 'home' && path !== 'about' ? '#000' : headerColor,
						fontWeight: 'bold',
						textShadow: '2px 2px 1px #aaa',
					}}>
					CILABS
				</MobileAppName>
			</View>
			<CustomBtn>
				<CButton
					onClick={handleToggleDrawer}
					style={{ marginRight: 10, zIndex: 99999 }}
					icon={
						drawerOpen ? (
							<IconX
								size={30}
								color={path !== 'home' && path !== 'about' ? '#000' : headerColor}
								style={{ fontWeight: 'bold' }}
							/>
						) : (
							<IconMenu
								size={30}
								color={path !== 'home' && path !== 'about' ? '#000' : headerColor}
								style={{ fontWeight: 'bold' }}
							/>
						)
					}
				/>
			</CustomBtn>
			<MobileDrawerContainer show={drawerOpen} setPath={setPath} />
		</MobileHeaderSection>
	)
}

export default MobileHeader
