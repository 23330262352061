import React, { useState } from 'react'
import styled from 'styled-components'
import { Close } from 'styles/globalPage-styles'
import titleIcon from '../../../../../assets/icons/memberInfoIcon.png'
import close from '../../../../../assets/icons/close.png'
import * as antd from 'antd'
import { useHistory } from 'react-router'

type Props = {
	handleModal: () => void
}

function PersonalModal({ handleModal }: Props) {
	const navigate = useHistory()


	const closeModal = () => {
		navigate.push('/ideas')
	}

	return (
		<ModalWrap>
			<Modal>
				<Title>개인정보의 수집 및 이용동의</Title>
				<p>
				이용자가 제공한 모든 정보는 다음의 목적을 위해 활용하며, 하기 목적 이외의 용도로는 사용되지 않습니다.
				</p>
				<p> 
					{
					`① 개인정보 수집 항목 및 수집·이용 목적   
					가) 수집 항목 (필수항목) 
					- 전화번호(휴대전화)
					나) 수집 및 이용 목적 
					-  아이디어 작성자와의 연락`
					}
				</p>
				<p> 
					{
					`② 개인정보 보유 및 이용기간 
					- 수집·이용 동의일로부터 개인정보의 수집·이용목적을 달성할 때까지     `
					}
				</p>
				<p> 
					{
					`③ 동의거부관리        
					- 귀하께서는 본 안내에 따른 개인정보 수집, 이용에 대하여 동의를 거부하실 권리가 있습니다. 다만, 귀하가 개인정보의 수집/이용에 동의를 거부하시는 경우에 아이디어 등록이 불가하다는 점을 알려드립니다.`
					}
				</p>
				<ButtonWrap>
					<CreateButton onClick={handleModal}>확인</CreateButton>
				</ButtonWrap>
			</Modal>
		</ModalWrap>
	)
}

export default PersonalModal

const ModalWrap = styled.div`
/* display: none; */
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	/* background: #f4f7f7; */
	background: rgba(0,0,0,0.5);
	z-index: 999;
`
const Modal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 10px 60px 10PX;
	gap: 15px;

	position: absolute;
	width: 700px;
	left: 50%;
	margin-left: -350px;
	top: 300px;

	background: #ffffff;
	/* opacity: 1; */
	box-shadow: 0px 0px 20px rgba(88, 88, 88, 0.25);

	@media only screen and (max-width: 1000px) {
		width: 300px;
		left: 50%;
		margin-left: -150px;
		top: 20%;
	}

`

const Title = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	/* width: 85px; */
	width: 100%;
	height: 70px;


	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	/* identical to box height, or 292% */

	text-align: center;
	letter-spacing: -0.015em;

	color: #000000;
`

const Notice =  styled.span`
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	/* identical to box height */

	text-align: left;
	width: 100%;

	color: #CE5858;
`
const ButtonWrap = styled.div`
	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 12px;

	width: 100%;
`

const CreateButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	width: 89px;
	height: 41px;

	background: #333333;
	border-radius: 1px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;

	color: #FFFFFF;
	
	&:hover{
		cursor: pointer;
	}
`
const CancelButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	width: 89px;
	height: 41px;

	background: #FFFFFF;
	border: 0.5px solid #333333;
	border-radius: 1px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	align-items: center;

	color: #333333;

	&:hover{
		cursor: pointer;
	}
`
const TableContainer = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	width: 380px;
	/* height: 324px; */

	/* table/border */

	/* border: 1px solid #E4E4E4; */
`

const RowData = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	width: 380px;
	height: 36px;

	background: #ffffff;
`

const RowDataNameHeader = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 36px;
	line-height: 36px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`

const RowDataContentHeader = styled.div`
	box-sizing: border-box;

	width: 280px;
	height: 36px;
	line-height: 36px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`

const RowDataName = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 50px;
	line-height: 50px;

	background: #ffffff;
	/* table/border */

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`

const RowDataContent = styled.div`
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-items: center;
	justify-content: space-between;
	width: 470px;
	height: 76px;

	background: #ffffff;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;

	color: #666666;
`

const Input = styled.input`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 10px;

	width: 100%;
	height: 26px;

	/* stroke */

	border: 1px solid #cccccc;
	border-radius: 2px;

	&:focus {
		border: 1px solid #333333;
	}
`

const SizeInput = styled.input`
	width: 86px;
	height: 26px;

	/* stroke */

	border: 1px solid #cccccc;
	border-radius: 2px;
`
