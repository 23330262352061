import styled from 'styled-components'

export const TableListSection = styled.div`
	width: 100%;
	background-color: transparent;
`

export const Type = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	/* table/border */
	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	/* identical to box height, or 16px */

	text-align: center;
`
export const Content = styled.div`
	box-sizing: border-box;

	width: 400px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
export const Sender = styled.div`
	box-sizing: border-box;

	width: 140px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;
`
export const Date = styled.div`
	/* Auto layout */
	width: 167px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;
`
export const Modal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 20px;
	gap: 16px;
	isolation: isolate;

	position: absolute;
	width: 742px;
	height: 699px;
	left: 50%;
	top: 15%;
	margin-left: -371px;

	/* white */

	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(88, 88, 88, 0.25);
	border-radius: 2px;
`

export const ModalTitle = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	gap: 26px;

	width: 702px;
	height: 23px;
`

export const ModalTitleLeft = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 4px;

	/* width: 85px; */
	height: 23px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;

	/* text/primary */

	color: #333333;
`

export const ModalTitleRight = styled.div`
	width: 16px;
	height: 16px;

	line-height: 16px;
`

export const TableContainer = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	width: 702px;
	height: 380px;

	/* table/border */
	border-width: 0px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const TypeColumnWrap = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 36px;

	/* table/bg */

	/* background: #FAFAFA; */
	/* table/border */

	border-width: 1px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`

export const ContentColumnWrap = styled.div`
	box-sizing: border-box;

	width: 602px;
	height: 36px;

	/* table/bg */

	/* background: #FAFAFA; */
	/* table/border */

	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`
export const DataRowWrap = styled.div`
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	width: 702px;
	height: 36px;
	line-height: 36px;

	/* background: #FFFFFF; */

	text-align: center;
`

export const DataName = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: auto;

	vertical-align: center;
	/* background: #FFFFFF; */
	/* table/border */

	border-width: 0px 1px 1px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;

	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`
export const TextDataRowWrap = styled.div`
	/* Auto layout */
	box-sizing: border-box;

	display: flex;
	/* flex-direction: row; */
	align-items: flex-start;

	/* border-top: 1px solid #E4E4E4; */
	width: 100%;
	/* display: grid; */
	height: 270px;
	/* line-height: 270px; */
	text-align: center;
	/* background-color: #ffffff; */

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`
export const TextContentName = styled.div`
	box-sizing: border-box;

	width: 100px;
	line-height: 270px;

	/* vertical-align: center; */
	/* background: #FFFFFF; */
	/* table/border */

	border-width: 0px 1px 1px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;

	/* identical to box height, or 16px */

	/* text-align: center; */

	/* text/contents */

	color: #666666;
`

export const TextContent = styled.div`
	box-sizing: border-box;

	width: 602px;
	height: 270px;
	padding: 5px 10px;
	/* background: #FFFFFF; */
	/* table/border */
	overflow-x: hidden;
	overflow-y: auto;

	text-align: left;
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* or 16px */

	/* text/contents */

	color: #666666;

	/* border-width: 0px 0px 1px 0px; */
	border-style: solid;
	border-color: #e4e4e4;
`

export const ListContainer = styled.div`
	box-sizing: border-box;

	width: 702px;
	min-height: 170px;
	/* overflow-x: auto; */
	overflow-x: hidden;
	overflow-y: auto;
	/* overflow: auto; */

	/* background: #FFFFFF; */
	/* table/border */

	border-width: 0 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const ReceiverTitle = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	width: 40%;
	height: 30px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 1px 0px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`

export const DateTitle = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	width: 60%;
	height: 30px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 0px 0px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`
export const ReceiverWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	width: 702px;
	height: 28px;
	line-height: 28px;

	/* background: #FFFFFF; */
	/* border-width: 1px 1px 1px 0px; */
	border-style: solid;
	border-color: #e4e4e4;
`

export const Receiver = styled.div`
	box-sizing: border-box;

	width: 40%;
	height: 28px;

	/* background: #FFFFFF; */
	text-align: center;
	/* table/border */

	border-width: 1px 1px 1px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	/* text/contents */

	color: #666666;
`
export const ReadAt = styled.div`
	box-sizing: border-box;

	width: 60%;
	height: 28px;

	/* background: #FFFFFF; */
	/* table/border */

	border-width: 1px 1px 1px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`
export const ModalContent = styled.div`
	box-sizing: border-box;

	width: 602px;
	height: 37px;

	/* background: #FFFFFF; */
	/* table/border */

	border-width: 0px 0px 1px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text/contents */

	color: #666666;
`
