import React from 'react'
import { View } from 'styles/reactnative'
import { ClickedPageNumberBox, PageNumberBox } from './styles'

const PaginationItem = ({ item, page, setPage }) => {
	return (
		<View style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
			{item === page ? (
				<ClickedPageNumberBox>{item}</ClickedPageNumberBox>
			) : (
				<PageNumberBox onClick={() => setPage(item)}>{item}</PageNumberBox>
			)}
		</View>
	)
}

export default PaginationItem
