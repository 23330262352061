import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import { useToken } from 'app/hooks'
// import FileUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
// import EnterPlugin from '@ckeditor/ckeditor5-enter/src/enter';
// import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository';
// import FileRepositoryPlugin from '@ckeditor/ckeditor5-upload/src/filerepository';
import { Editor as ClassicEditor } from '@ckeditor/ckeditor-custom'
// import ClassicEditor from '@ckeditor/ckeditor-custom'
import './editor.css'

const API_URL = `https://cms-api.cilabs.co.kr/v1/custom/pages`
const UPLOAD_ENDPOINT = 'upload_files'

type props = {
	setContent: any
	content: string
	edit: boolean
}

export default function Editor({ content, setContent, edit }: props) {
	const { data: token } = useToken()

	function uploadAdapter(loader) {
		return {
			upload: () => {
				return new Promise((resolve, reject) => {
					const body = new FormData()
					loader.file.then(file => {
						body.append('files', file)
						fetch(`${API_URL}/upload`, {
							method: 'post',
							headers: {
								Authorization: 'Bearer ' + token,
							},
							body: body,
						})
							.then(res => res.json())
							.then(res => {
								resolve({
									default: `https://cms-api.cilabs.co.kr/public/uploadImage/${res.result}`,
									// default: `http://3.34.30.194:3001/public/uploadImage/${res.result}`
								})
							})
							.catch(err => {
								reject(err)
							})
					})
				})
			},
		}
	}

	function uploadPlugin(editor) {
		editor.plugins.get('FileRepository').createUploadAdapter = loader => {
			return uploadAdapter(loader)
		}
	}

	return (
		<div style={{ height: '300px' }} className="form-wrapper">
			<CKEditor
				config={{
					extraPlugins: [uploadPlugin],
					// toolbar:{
					//   items: [ 'bold', 'italic', 'sourceEditing']

					// }
				}}
				data={content ? content : ''}
				editor={ClassicEditor}
				onReady={(editor: any) => {}}
				onBlur={(event, editor) => {}}
				onFocus={(event, editor) => {}}
				// onChange={(event, editor) => {
				//   handleChange(editor.getData())
				//   console.log(editor.getData())
				// }}
				onChange={(event, editor: any) => {
					const data2 = editor.getData()
					setContent(data2)
				}}
			/>
		</div>
	)
}
