import React, { useEffect, useState } from 'react'
import API from 'utils/api'
import { Nocontent, ButtonWrap, TableWrap } from 'styles/globalPage-styles'
import '../../custom.css'
import Header from '../../Header'
import Footer from '../../Footer'
import Menu from '../../Menu'
import PaginatedItems from '../../Paginations'
import setting from '../../../../../assets/icons/setting.png'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import PostForm from './PostForm'
import { ContentWrap, CustomPageWrap } from './styles'
import styled from 'styled-components'
import { HeaderSection } from '../../styles'
import MobileHeader from '../../MobileHeader'
import { useWindowDimensions } from 'app/hooks'
import { View } from 'styles/reactnative'
import LockModal from '../Post/LockModal'

type Props = {
	setPassword?: React.Dispatch<React.SetStateAction<boolean>>
}

function Board({ setPassword }: Props) {
	const [path, setPath] = useState('')
	const [pageSize, setPageSize] = useState<number[]>([])
	const [posts, setPosts] = useState<any[]>([])
	const [boardname, setBoardname] = useState('')
	const [page, setPage] = useState(1)
	const [create, setCreate] = useState(false)
	const [post, setPost] = useState<any>()
	const [showModal, setShowModal] = useState(false)

	const refreshList = () => {
		getPosts()
	}

	const getPosts = () => {
		// const boardPath = window.location.pathname?.split('/')?.[2]?.split('?')[0]
		// temp
		const boardPath = 'ideas'
		// let apiurl = `/board/${boardPath}?maxData=20&page=` + 1
		let apiurl = `/board/${boardPath}?maxData=20&page=` + page

		API.get(apiurl, null, {})
			.then((res: any) => {
				if (res.result.success) {
					setPageSize(Array.from({ length: res.result.result.pageLength }, (v, i) => i + 1))
					setPosts(res.result.result.posts)
					setBoardname(res.result.result.boardname)
					console.log(res.result)
				}
			})
			.catch((err: any) => {
				// console.log(err)
			})
	}

	const handleModal = (change?: boolean) => {
		if (change) {
			getPosts()
		}
		setCreate(false)
	}

	const changePage = (num: number) => {
		setPage(num)
	}

	const closeLockModal = () => {
		setPost(null)
		setShowModal(false)
	}

	const moveToPost = () => {
		setPassword && setPassword(true)
		closeLockModal()
	}

	useEffect(() => {
		if (post) {
			setShowModal(true)
		}
	}, [[post]])

	useEffect(() => {
		getPosts()
	}, [])
	const { width } = useWindowDimensions()
	const [drawerOpen, setDrawerOpen] = useState(false)
	return (
		<CustomPageWrap>
			{width > 1000 ? (
				<HeaderSection>
					<Header />
					<div style={{ position: 'sticky', top: '0px', zIndex: '999' }}>
						<Menu setPath={setPath} />
					</div>
				</HeaderSection>
			) : (
				<MobileHeader
					setPath={setPath}
					drawerOpen={drawerOpen}
					setDrawerOpen={setDrawerOpen}
					otherPageStyle={true}
				/>
			)}
			{!create ? (
				<ContentWrap>
					<TitleWrap>
						<MainTitle>Ideas</MainTitle>
						<SubTitle>생각만 하고 있던 아이디어를 전문 개발팀이 실현시켜드립니다</SubTitle>
					</TitleWrap>
					<ControllerWrap>
						<TotalCount>총 {posts?.length}건</TotalCount>
						<ButtonWrap>
							<CreateButton onClick={() => setCreate(true)}>글쓰기</CreateButton>
						</ButtonWrap>
					</ControllerWrap>
					<TableWrap>
						{/* <DataRowWrapHeader>
							<NumberWrap>NO.</NumberWrap>
							<Title>제목</Title>
							<Name>작성자</Name>
							<Date>작성일시</Date>
						</DataRowWrapHeader> */}
						{posts?.map((post, index) => {
							return (
								<DataRowWrap>
									{width > 1000 ? <NumberWrap>{post.id}</NumberWrap> : null}
									<Title style={{ textAlign: 'left' }} onClick={() => setPost(post)}>
										{decodeURIComponent(post?.title)}
									</Title>
									{width > 1000 ? (
										<>
											<Name>{decodeURIComponent(post?.writer)}</Name>
											<Date>{moment(post?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD')}</Date>
										</>
									) : (
										<View
											style={{
												flexDirection: 'row',
												alignItems: 'flex-start',
												justifyContent: 'flex-start',
											}}>
											<Name>{decodeURIComponent(post?.writer)}</Name>
											<Date>{moment(post?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD')}</Date>
										</View>
									)}
								</DataRowWrap>
							)
						})}
						{posts?.length < 1 && <Nocontent>등록된 게시물이 없습니다</Nocontent>}
					</TableWrap>
					<PaginatedItems
						page={page}
						pageSize={pageSize}
						changePage={changePage}
						itemsPerPage={5}
					/>
					{showModal && (
						<LockModal
							postId={post.id}
							postPassword={post.password}
							goToPost={moveToPost}
							handleModal={closeLockModal}
						/>
					)}
				</ContentWrap>
			) : (
				<PostForm handleModal={handleModal} />
			)}

			<Footer />
		</CustomPageWrap>
	)
}

export default Board

const TitleWrap = styled.div`
	width: 100%;
	max-width: 1320px;
	text-align: center;
	margin: 90px auto 20px auto;

	background-color: #ffffff;
	@media only screen and (max-width: 1000px) {
		width: 100%;
	}
`

const MainTitle = styled.div`
	width: 100%;

	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 60px;
	/* identical to box height, or 117% */

	text-align: center;
	letter-spacing: -0.015em;

	color: #000000;
	@media only screen and (max-width: 1000px) {
		font-size: 35px;
		align-self: flex-start !important;
		text-align: left;
		line-height: 1.5;
	}
`

const SubTitle = styled.div`
	width: 100%;

	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 26px;
	/* identical to box height, or 144% */

	text-align: center;
	letter-spacing: -0.015em;

	color: #333333;
	@media only screen and (max-width: 1000px) {
		font-size: 18px;
		align-self: flex-start !important;
		text-align: left;
		width: 70%;
	}
`
const ControllerWrap = styled.div`
	/* Auto layout */
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;

	background-color: #ffffff;
	@media only screen and (max-width: 1000px) {
		margin-bottom: 10px;
	}
`

const TotalCount = styled.div`
	gap: 10px;
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: #333333;
`

const CreateButton = styled.button`
	width: 123px;
	height: 49px;

	background: #333333;
	border-color: none;
	border-radius: 1px;

	text-align: center;
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;

	color: #ffffff;
`

const DataRowWrap = styled.div`
	box-sizing: border-box;

	display: grid;

	width: 100%;
	height: 80px;

	/* color/line */

	border-width: 1px 0px;
	border-style: solid;
	border-color: #f0f0f0;
	@media only screen and (min-width: 1000px) {
		grid-template-columns: repeat(12, 1fr);
	}
	@media only screen and (max-width: 1000px) {
		justify-content: center;
		align-items: flex-start;
		display: flex;
		flex-direction: column;
	}
`

const NumberWrap = styled.div`
	box-sizing: border-box;
	grid-column: 1 / 2;

	text-align: center;
	display: flex;
	align-items: center;
	/* justify-content: center; */
`

const Title = styled.div`
	box-sizing: border-box;
	grid-column: 2 / 9;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	display: flex;
	align-items: center;

	/* base/text */

	color: #333333;

	&:hover {
		cursor: pointer;
	}
`
const Name = styled.div`
	box-sizing: border-box;
	grid-column: 9 / 11;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	color: #828282;
	@media only screen and (max-width: 1000px) {
		padding: 0 0;
	}
`

const Date = styled.div`
	box-sizing: border-box;
	grid-column: 11 / 13;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	align-items: center;
	justify-content: center;

	color: #828282;
`
