import React, { useState, useEffect } from 'react'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import {
	ButtonWrap,
	CreateButton,
	DataInputWrap,
	DataRowWrapHeader,
} from 'styles/globalPage-styles'
import { CancelButton, ContentColumn2, ContentColumn1, ContentWrap } from './styles'

import CIUtils from 'utils/ciutils'
import * as antd from 'antd'
import { View } from 'styles/reactnative'

type Props = {
	handleModal: () => void
	boardData?: any
	refreshList: () => void
	boards: any
	showModal?: boolean
}

function CreateForm({ showModal, handleModal, boardData, refreshList, boards }: Props) {
	const { data: token } = useToken()
	const [type, setType] = useState(boardData ? boardData.type : '')
	const [path, setPath] = useState(boardData ? boardData.path : '')
	const [name, setName] = useState(boardData ? boardData.name : '')
	const [edit, setEdit] = useState(false)
	const BOARD_PATH = window.location.pathname?.split('/')?.[1]?.split('?')[0]

	const initData = () => {
		if (boardData?.name !== name) setName(boardData?.name)
		if (boardData?.type !== type) setType(boardData?.type)
		if (boardData?.path !== path) setPath(boardData?.path)
	}

	const datavalidate = () => {
		const find = boards.find(b => b.path === path)

		if (boardData) {
			if ((boardData?.path != path) && find) {
				antd.message.error('경로가 중복되는 게시판이 있습니다')
				return false
			}
		} else {
			if (find) {
				antd.message.error('경로가 중복되는 게시판이 있습니다')
				return false
			}
		}
		 
		if (!CIUtils.validateEnglish(path)) {
			antd.message.error('경로는 영문 또는 숫자를 사용하여 20자 이하로 작성해 주십시오')
			return false
		}

		if (!name || name.length < 1 || name.length > 20) {
			antd.message.error('게시판명은 20자 이하로 작성해 주십시오')
			return false
		}

		if (!type || type.length < 1 || type.length > 20) {
			antd.message.error('타입은 20자 이하로 작성해 주십시오')
			return false
		}

		return true
	}
	const createBoard = () => {
		let apiurl = '/system/boards'

		const result = datavalidate()

		if (!result) {
			return
		}

		API.post(apiurl, token + '', {
			name,
			type,
			BOARD_PATH,
			path,
		})

			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('등록완료')
					refreshList()
					handleModal()
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const validateBoard = () => {
		let updateData: any = {}

		if (boardData.name != name) {
			updateData = {
				...updateData,
				name,
			}
		}

		if (boardData.type != type) {
			updateData = {
				...updateData,
				type,
			}
		}

		if (boardData.path != path) {
			updateData = {
				...updateData,
				path,
			}
		}

		const keys = Object.keys(updateData)
		if (keys.length < 1) return undefined
		return updateData
	}

	const updateBoard = () => {
		let apiurl = `/system/boards/${boardData.id}`

		// const result = datavalidate()

		// if (!result) {
		// 	return
		// }

		const updateData = validateBoard()

		if (!updateData) {
			antd.message.info('수정사항없음')
			return
		}

		if (!datavalidate()) return

		API.put(apiurl, token + '', { updateData })
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정완료')
					refreshList()
					handleModal()
				}
			})
			.catch((err: any) => {})
	}

	function settings(e: React.ChangeEvent<HTMLInputElement>) {
		const name = e.target.name
		const value = e.target.value

		switch (name) {
			case 'name':
				setName(value)
				break
			case 'path':
				setPath(value)
				break
			case 'type':
				setType(value)
				break
		}
	}

	useEffect(() => {
		if (!edit) initData()
	}, [edit])

	return (
		<antd.Modal
			title={'게시판 등록'}
			footer={null}
			open={showModal}
			onOk={handleModal}
			onCancel={handleModal}
			width={420}>
			<ContentWrap>
				<DataRowWrapHeader>
					<ContentColumn1>구분</ContentColumn1>
					<ContentColumn2>내용</ContentColumn2>
				</DataRowWrapHeader>
				<DataInputWrap>
					<ContentColumn1>게시판명</ContentColumn1>
					<ContentColumn2>
						{boardData ? (
							!edit ? (
								name
							) : (
								<antd.Input
									type="text"
									name="name"
									onChange={settings}
									value={name}
									placeholder="게시판명을 적어주세요"
									readOnly={boardData ? !edit : false}></antd.Input>
							)
						) : (
							<antd.Input
								type="text"
								name="name"
								onChange={settings}
								value={name}
								placeholder="게시판명을 적어주세요"
								readOnly={boardData ? !edit : false}></antd.Input>
						)}
					</ContentColumn2>
				</DataInputWrap>
				<DataInputWrap>
					<ContentColumn1>경로</ContentColumn1>
					<ContentColumn2>
						{boardData ? (
							!edit ? (
								path
							) : (
								<antd.Input
									type="text"
									name="path"
									onChange={settings}
									value={path}
									placeholder="경로를 적어주세요"
									readOnly={boardData ? !edit : false}></antd.Input>
							)
						) : (
							<antd.Input
								type="text"
								name="path"
								onChange={settings}
								value={path}
								placeholder="경로를 적어주세요"
								readOnly={boardData ? !edit : false}></antd.Input>
						)}
					</ContentColumn2>
				</DataInputWrap>
				<DataInputWrap>
					<ContentColumn1>타입</ContentColumn1>
					<ContentColumn2>
						{boardData ? (
							!edit ? (
								type
							) : (
								<antd.Input
									type="text"
									name="type"
									onChange={settings}
									value={type}
									placeholder="exP공지, 질문.. "
									readOnly={boardData ? !edit : false}></antd.Input>
							)
						) : (
							<antd.Input
								type="text"
								name="type"
								onChange={settings}
								value={type}
								placeholder="exP공지, 질문.. "
								readOnly={boardData ? !edit : false}></antd.Input>
						)}
					</ContentColumn2>
				</DataInputWrap>
			</ContentWrap>
			<View style={{ marginTop: 10 }}>
				{boardData ? (
					!edit ? (
						<ButtonWrap>
							<CreateButton onClick={() => setEdit(true)}>수정</CreateButton>
						</ButtonWrap>
					) : (
						<ButtonWrap>
							<CreateButton onClick={updateBoard}>저장</CreateButton>
							<CancelButton onClick={() => setEdit(false)}>취소</CancelButton>
						</ButtonWrap>
					)
				) : (
					<ButtonWrap>
						<CreateButton onClick={createBoard}>저장</CreateButton>
						<CancelButton onClick={handleModal}>취소</CancelButton>
					</ButtonWrap>
				)}
			</View>
		</antd.Modal>
	)
}
export default CreateForm
