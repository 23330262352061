import React, { useState, useEffect } from 'react'
import { useToken, useUser } from 'app/hooks/'
import API from 'utils/api'
import CIUtils from 'utils/ciutils'

import * as antd from 'antd'
import {
	ButtonContainer,
	CancelButton,
	Column,
	ColumnTitle,
	Content,
	ContentTextarea,
	ContentTitle,
	CustomDataColumn,
	Form,
	SaveButton,
	UpdateButton,
	UserData,
} from './styles'
import { Close, ModalWrap } from 'styles/globalPage-styles'
import ti from '../../../../../assets/icons/memberInfoIcon.png'
import close from '../../../../../assets/icons/close.png'
import styled from 'styled-components'
import NaverRevokeButton from 'app/organisms/Auth/Forms/NaverRevokeButton'
import KakaoRevokeButton from 'app/organisms/Auth/Forms/KakaoRevokeButton'
import GoogleRevokeButton from 'app/organisms/Auth/Forms/GoogleRevokeButton'
import AppleRevokeButton from 'app/organisms/Auth/Forms/AppleRevokeButton'

type Props = {
	handleModal: () => void
	roles: any[]
	data?: any
	insertNewUser?: () => void
	userOnly?: boolean
	showModal?: boolean
}
function SignupForm({ showModal, handleModal, roles, data, insertNewUser, userOnly }: Props) {
	const { data: token } = useToken()
	const [name, setName] = useState(data ? data.user.name : '')
	const [nickname, setNickname] = useState(data ? data.user.nickname : '')
	const [profileimage, setProfileimage] = useState(data ? data.user.profileimage : '')
	const [password, setPassword] = useState(data ? data.user.password : '')
	const [phone, setPhone] = useState(data ? data.user.phone : '')
	const [email, setEmail] = useState(data ? data.user.email : '')
	const [count, setCount] = useState<number[]>([])
	// const [newData, setNewData] = useState<any[]>([])
	const [customData, setCustomData] = useState<any[]>([])
	const [edit, setEdit] = useState(false)
	const [deleteCustomDatas, setDeleteCustomDatas] = useState<any[]>([])
	const [role, setRole] = useState(data ? data.user.groupid : roles[0].id)
	const [customDatas, setCustomDatas] = useState<any[]>([])
	const [userCustomDatas, setUserCustomDatas] = useState<any[]>([])
	const [updateCustomDatas, setUpdateCustomDatas] = useState<any[]>([])
	const [initPassword, setInitPasswrod] = useState(false)
	const [changedPassword, setChangedPassword] = useState('')
	const [roleName, setRoleName] = useState(!data ? roles[0].name : '')
	const { mutate } = useUser()

	const initData = () => {
		if (data) {
			const find = roles.find(r => r.id == data?.user?.groupid)
			if (find) setRoleName(find.name)
			if (data?.user?.name !== name) setName(data?.user?.name)
			if (data?.user?.nickname !== nickname) setNickname(data?.user?.nickname)
			if (data?.user?.phone !== phone) setPhone(data?.user?.phone)
			if (data?.user?.groupid !== role) setRole(data?.user?.groupid)

			if (data?.userData) {
				let temp: any = []
				data.userData.map(data => {
					// const tmp = JSON.parse(JSON.stringify(data))
					// temp.push(tmp)
					return temp.push({ ...data })
				})
				setUserCustomDatas(temp)
			}

			setInitPasswrod(false)
			setChangedPassword('')
		}
	}

	useEffect(() => {
		if (!edit) initData()
	}, [edit])

	function settings(e: React.ChangeEvent<HTMLInputElement>) {
		switch (e.target.name) {
			case 'name':
				setName(e.target.value)
				break
			case 'nickname':
				setNickname(e.target.value)
				break
			case 'profileimage':
				setProfileimage(e.target.value)
				break
			case 'password':
				setPassword(e.target.value)
				break
			case 'phone':
				setPhone(e.target.value)
				break
			case 'email':
				setEmail(e.target.value)
				break
		}
	}

	const dataValidation = () => {
		if (!data && !CIUtils.validateEmail(email)) {
			antd.message.error('이메일을 확인해 주세요')
			return false
		}
		if (!CIUtils.validateName(name)) {
			antd.message.error('이름은 특수문자를 제외하고 1~20자로 입력해주세요')
			return false
		}
		if (!CIUtils.validateName(nickname)) {
			antd.message.error('닉네임은 특수문자를 제외하고 1~20자로 입력해주세요')
			return false
		}
		if (!data && !CIUtils.validatePassword(password)) {
			antd.message.error('비밀번호는 영문, 숫자, 특수문자를 사용하여 입력해주세요')
			return false
		}
		if (!CIUtils.validateNumber(phone)) {
			antd.message.error('전화번호는 숫자로만 9~12자로 입력해주세요')
			return false
		}

		return true
	}

	const createUser = () => {
		let apiurl = '/auth/signup'

		const result = dataValidation()

		if (!result) {
			return
		}

		API.post(apiurl, token + '', {
			name,
			nickname,
			profileimage,
			password,
			phone,
			email,
			role,
			userCustomDatas,
		})
			.then((res: any) => {
				if (res?.result?.success) {
					antd.message.success('등록되었습니다')
					insertNewUser && insertNewUser()
					handleModal()
				} else if (!res?.result?.success && res?.result?.msgcode === 4) {
					antd.message.error('중복되는 아이디입니다')
				}
				// const {id, created_at} = res.result.result
				// const user = {
				// 	created_at: created_at,
				// 	email: email,
				// 	groupid: role,
				// 	id: id,
				// 	logined_at: null,
				// 	name: name,
				// 	nickname: nickname,
				// 	phone: phone,
				// 	profileimage: profileimage,
				// 	updated_at: created_at
				// }
				else {
					antd.message.error('등록에 실패하였습니다')
				}
			})
			.catch((err: any) => {})
	}

	const validate = () => {
		let updateData: any = {}

		if (data.user.name !== name) updateData = { ...updateData, name }
		if (data.user.nickname !== nickname) updateData = { ...updateData, nickname }
		if (data.user.profileimage !== profileimage) updateData = { ...updateData, profileimage }
		// if (data.user.password !== password) updateData = {...updateData, password}
		if (data.user.phone !== phone) updateData = { ...updateData, phone }
		if (data.user.groupid !== role) updateData = { ...updateData, groupid: role }

		let updateCustomDataArr: any = []
		userCustomDatas.map((cd, index) => {
			const find = data.userData.find(d => d.key === cd.key)

			if (find.value !== cd.value) {
				updateCustomDataArr.push({ key: find.key, value: cd.value })
			}
		})

		return { updateData, updateCustomDataArr }
	}

	const updateUser = () => {
		let apiurl = ''

		if (userOnly) apiurl = `/user/my/info`
		else apiurl = `/users/${data.user.id}`

		const result = dataValidation()

		if (!result) {
			return
		}

		if (initPassword && !changedPassword) {
			const confirm = window.confirm('비밀번호가 입력되지 않았습니다. 그대로 진행하시겠습니까?')

			if (!confirm) return
		}

		if (initPassword && changedPassword) {
			if (!CIUtils.validatePassword(changedPassword)) {
				antd.message.error('비밀번호는 영문, 숫자, 특수문자를 사용하여 입력해주세요')
				return
			}
		}

		let { updateData, updateCustomDataArr } = validate()

		if (changedPassword) {
			updateData = { ...updateData, password: changedPassword }
		}

		const keys = Object.keys(updateData)

		if (keys.length < 1 && updateCustomDataArr.length < 1 && !changedPassword) {
			antd.message.info('수정사항이 없습니다')
			return
		}

		API.put(apiurl, token + '', {
			updateData,
			updateCustomDatas: updateCustomDataArr,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정되었습니다')
					insertNewUser && insertNewUser()
					handleModal()

					if (userOnly && name !== data.user.name) {
						mutate(name)
					}
				}
				// const {id, created_at} = res.result.result
				// const user = {
				// 	created_at: created_at,
				// 	email: email,
				// 	groupid: role,
				// 	id: id,
				// 	logined_at: null,
				// 	name: name,
				// 	nickname: nickname,
				// 	phone: phone,
				// 	profileimage: profileimage,
				// 	updated_at: created_at
				// }

				// handleModal();
				// const user = {
				// 	created_at: data?.user?.created_at,
				// 	email: data?.user?.email,
				// 	groupid: role,
				// 	id: data?.user?.id,
				// 	logined_at: data?.user?.logined_at,
				// 	name: name,
				// 	nickname: nickname,
				// 	phone: phone,
				// 	profileimage: profileimage,
				// 	updated_at: data?.user?.updated_at,
				// }
				// return user
			})
			.catch((err: any) => {})
	}

	const newForm = () => {
		const lastCount = count[count.length - 1] + 1
		const temp = [...count, lastCount]
		setCount(temp)
	}

	const deleteCustomData = (data: any, index: number) => {
		let temp = [...deleteCustomDatas]
		temp.push(data.key)
		setDeleteCustomDatas(temp)

		let temp2 = [...customData]
		temp2[index] = { key: data.key, value: data.value, delete: true }
		setCustomData(temp2)
	}

	const customDataSetting = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		let tempArr = [...userCustomDatas]

		if (e.target.name === 'key') {
			tempArr[index].key = e.target.value
		} else {
			tempArr[index].value = e.target.value
		}

		setUserCustomDatas(tempArr)
	}

	const updateCustomData = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		let tempArr = [...userCustomDatas]

		tempArr[index].value = e.target.value

		setUpdateCustomDatas(tempArr)
	}

	// useEffect(()=>{
	// 	if (count.length > 0) {
	// 		let tempArr = [...newData]
	// 		tempArr.push({key: '', value: ''})
	// 		setNewData(tempArr)
	// 	}
	// },[count])

	const getUserData = () => {
		let apiurl = '/system/userdata?maxData=20&page=' + 1

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					console.log(res.result.result)
					setCustomDatas(res.result.result.settings)
					// setPageSize(Array.from({length: res.result.result.pageLength}, (v,i)=>i+1));
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	useEffect(() => {
		if (customDatas) {
			let temp: any = []
			customDatas.map(m => {
				temp.push({ key: m.key, value: m.value })
			})
			setUserCustomDatas(temp)
		}
	}, [customDatas])

	useEffect(() => {
		if (!data) getUserData()
		else if (data?.userData) {
			let temp: any = []
			data.userData.map(data => {
				// const tmp = JSON.parse(JSON.stringify(data))
				// temp.push(tmp)
				temp.push({ ...data })
			})
			setUserCustomDatas(temp)
		}
	}, [])

	return (
		<ModalWrap>
			<Modal>
				{/* title={'회원정보'}
			footer={null}
			open={showModal}
			onOk={handleModal}
			onCancel={handleModal}> */}
				<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
					<div>회원정보</div>
					<div>
						<Close>
							<img src={close} alt={'img...'} onClick={handleModal} />
						</Close>
					</div>
				</div>
				{/* <SignupFormHeader>
				<SignupTitle>
					<img style={{ width: '18px', height: '18px' }} src={titleIcon} alt={'img...'} />
					회원정보
				</SignupTitle>
				<Close onClick={handleModal}>
					<img src={close} alt={'img...'} />
				</Close>
			</SignupFormHeader> */}
				<Form>
					<UserData>
						<ColumnTitle>구분</ColumnTitle>
						<ContentTitle>내용</ContentTitle>
					</UserData>
					<UserData>
						<Column>이메일</Column>
						{data && <Content>{data?.user?.email}</Content>}
						{!data && (
							<Content>
								<antd.Input type="text" name="email" onChange={settings} value={email}></antd.Input>
							</Content>
						)}
					</UserData>
					{!data && (
						<UserData>
							<Column>비밀번호</Column>
							<Content>
								<antd.Input
									type="password"
									name="password"
									onChange={settings}
									value={password}></antd.Input>
							</Content>
						</UserData>
					)}
					<UserData>
						<Column>이름</Column>
						{data ? (
							!edit ? (
								<Content>{data?.user?.name}</Content>
							) : (
								<Content>
									<antd.Input
										type="text"
										name="name"
										onChange={settings}
										value={edit ? name : data?.user?.name}></antd.Input>
								</Content>
							)
						) : (
							<Content>
								<antd.Input type="text" name="name" onChange={settings} value={name}></antd.Input>
							</Content>
						)}
					</UserData>
					<UserData>
						<Column>닉네임</Column>
						{data ? (
							!edit ? (
								<Content>{data?.user?.nickname}</Content>
							) : (
								<Content>
									<antd.Input
										type="text"
										name="nickname"
										onChange={settings}
										value={edit ? nickname : data?.user?.nickname}></antd.Input>
								</Content>
							)
						) : (
							<Content>
								<antd.Input
									type="text"
									name="nickname"
									onChange={settings}
									value={nickname}></antd.Input>
							</Content>
						)}
					</UserData>
					<UserData>
						<Column>휴대폰</Column>
						{data ? (
							!edit ? (
								<Content>{data?.user?.phone}</Content>
							) : (
								<Content>
									<antd.Input
										type="text"
										name="phone"
										onChange={settings}
										value={edit ? phone : data?.user?.phone}></antd.Input>
								</Content>
							)
						) : (
							<Content>
								<antd.Input type="text" name="phone" onChange={settings} value={phone}></antd.Input>
							</Content>
						)}
					</UserData>
					{
						userOnly && (
							<>
							<NaverRevokeButton />
							<KakaoRevokeButton />
							<GoogleRevokeButton />
							<AppleRevokeButton />
							</>
						)
					}
					{!userOnly && (
						<UserData>
							<Column>권한</Column>
							<Content>
								{data ? (
									!edit ? (
										roles?.map(role => {
											if (role?.id === data?.user?.groupid) return role.name
										})
									) : (
										<antd.Space>
											<antd.Select
												defaultValue={roleName}
												size={'middle'}
												style={{ width: 355 }}
												onChange={e => {
													setRole(Number(e))
												}}>
												{roles?.map((role, index) => {
													console.log(role)
													return (
														<antd.Select.Option
															key={index.toString()}
															value={role?.id}
															selected={role?.id === data?.user?.groupid}>
															{role?.name}
														</antd.Select.Option>
													)
												})}
											</antd.Select>
										</antd.Space>
									)
								) : (
									<antd.Select
										defaultValue={roleName}
										size={'middle'}
										style={{ width: 355 }}
										onChange={e => {
											setRole(Number(e))
										}}>
										{roles?.map((role, index) => {
											return (
												<antd.Select.Option
													key={index.toString()}
													value={role?.id}
													selected={role?.id === data?.user?.groupid}>
													{role?.name}
												</antd.Select.Option>
											)
										})}
									</antd.Select>
								)}
							</Content>
						</UserData>
					)}
					{data ? (
						!edit ? null : (
							<UserData>
								<Column>비밀번호초기화</Column>
								<Content>
									{!initPassword ? (
										<span onClick={() => setInitPasswrod(true)}>초기화</span>
									) : (
										<antd.Input
											type="password"
											name="password"
											onChange={e => setChangedPassword(e.target.value)}
											value={changedPassword}></antd.Input>
									)}
								</Content>
							</UserData>
						)
					) : null}
					{data
						? !edit
							? userCustomDatas?.map((customData, index) => {
									return (
										<UserData key={index.toString()}>
											<CustomDataColumn>{customData.key}</CustomDataColumn>
											{<ContentTextarea>{customData.value}</ContentTextarea>}
										</UserData>
									)
							  })
							: userCustomDatas?.map((customData, index) => {
									return (
										<UserData key={index.toString()}>
											<Column>{customData.key}</Column>
											{
												<Content>
													<antd.Input
														type="text"
														name="value"
														onChange={e => updateCustomData(e, index)}
														value={customData.value}></antd.Input>
												</Content>
											}
										</UserData>
									)
							  })
						: null}
					{!data &&
						userCustomDatas?.map((customData, index) => {
							return (
								<UserData key={index.toString()}>
									<Column>{customData.key}</Column>
									{
										<Content>
											<antd.Input
												type="text"
												name="value"
												onChange={e => customDataSetting(e, index)}
												value={customData.value}></antd.Input>
										</Content>
									}
								</UserData>
							)
						})}
				</Form>
				{/* {
						customData?.map((data, index)=>{
							return(
								<div style={{ display: "flex", alignItems: "center", marginBottom: "15px"}}>
									<input style={{backgroundColor: data?.delete ? 'gray' : 'white'}} name="key" defaultValue = {data.key} readOnly={true}></input>
									<input style={{backgroundColor: data?.delete ? 'gray' : 'white'}} name="value" defaultValue = {data.value} onChange={(e) => customDataSetting(e, index)} readOnly={!edit || data?.delete}></input>
									
									{
										edit && <span style={{ marginLeft: "5px" }}onClick={()=>deleteCustomData(data, index)}>X</span>
									}
								</div>
							)
						})
					} */}
				{/* {
						count?.map((n, index)=>{
							return(
							<div style={{ display: "flex", alignItems: "center", marginBottom: "15px"}}> 
								<input name="key" onChange={(e) => newCustomDataSetting(e, index)}></input>
								<input name="value" onChange={(e) => newCustomDataSetting(e, index)} readOnly={!newData[index]}></input>
							</div>
							)

						})
					} */}
				{/* {
						(edit || !data) &&
						<>
							<div style={{padding: "5px", marginBottom: "15px"}}>
								<span onClick={newForm}>➕</span>
							</div>
							<button type="button" onClick={!data ? createUser : updateUser}>등록</button>
						</>
					} */}
				<ButtonContainer>
					{!data && (
						<SaveButton type="button" onClick={createUser}>
							등록
						</SaveButton>
					)}
					{data && !edit && (
						<UpdateButton type="button" onClick={() => setEdit(true)}>
							수정
						</UpdateButton>
					)}
					{data && edit && (
						<>
							<SaveButton type="button" onClick={updateUser}>
								저장
							</SaveButton>
							<CancelButton type="button" onClick={() => setEdit(false)}>
								취소
							</CancelButton>
						</>
					)}
				</ButtonContainer>
			</Modal>
		</ModalWrap>
	)
}

export default SignupForm

export const Modal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 20px;
	gap: 16px;
	isolation: isolate;

	position: absolute;
	width: 510px;

	left: 50%;
	top: 15%;
	margin-left: -255px;

	/* white */

	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(88, 88, 88, 0.25);
	border-radius: 2px;
`
