import React, { useRef } from 'react'

import { CustomContentWrap } from '../styles'
import ProcessSlider from '../ProcessSlider'
import CustomTextAnimation from '../CustomTextAnimation'
import HomeFooter from '../Footer'
import Footer from '../CustomMobile/MobileFooter'
import Info from '../Info'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { CButton } from 'app/components/Common'
import { IconArrowDown } from '@tabler/icons'
import { useWindowDimensions } from 'app/hooks'

const CustomComp = ({ data, path, currentPage, setCurrentPage }) => {
	const { width } = useWindowDimensions()
	const swiperRef = useRef<any>(null)
	const handleNext = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext()
		}
	}

	const handlePrev = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev()
		}
	}
	const handleGoToFirstSlide = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideTo(0)
		}
	}
	const handlePageChange = number => {
		setCurrentPage(number?.activeIndex)
	}

	const home = path === 'home'
	const customData = home
		? [...data, { type: 'animation' }, { type: 'map' }]
		: [...data, { type: 'footer' }]

	return (
		<div style={{ width: '100vw', height: '100vh' }}>
			{currentPage > 0 && (
				<CButton
					onClick={() => {
						handleGoToFirstSlide()
					}}
					title={'Top'}
					style={{
						backgroundColor: '#fff',
						width: 40,
						height: 40,
						borderRadius: 50,
						position: 'fixed',
						zIndex: 9999,
						right: 20,
						bottom: width < 400 ? 50 : 100,
						boxShadow: `7px 3px 5px grey`,
					}}
				/>
			)}
			{currentPage === 0 && (
				<CButton
					onClick={() => {
						handleNext()
					}}
					icon={<IconArrowDown color={'#fff'} size={30} />}
					style={{
						height: 50,
						width: 50,
						position: 'fixed',
						zIndex: 10,
						bottom: 50,
						left: '43vw',
						alignSelf: 'center',
					}}
				/>
			)}
			<Swiper
				ref={swiperRef}
				direction={'vertical'}
				effect={'slide'}
				onRealIndexChange={handlePageChange}
				className="mySwiper">
				{customData?.map((item, index) => {
					if (path === 'home') {
						return (
							<SwiperSlide key={index.toString()} style={{ alignItems: 'flex-start' }}>
								{index !== 4 && item?.type !== 'animation' && item?.type !== 'map' && (
									<CustomContentWrap
										style={{
											height: '100%',
											width: '100%',
											alignSelf: 'center',
											justifyContent: [0, 1].indexOf(currentPage) != -1 ? 'center' : 'flex-start',
										}}
										dangerouslySetInnerHTML={{
											__html: decodeURIComponent(item?.content),
										}}
									/>
								)}
								{index === 4 && <ProcessSlider item={item} currentPage={currentPage} />}
								{item?.type === 'animation' && <CustomTextAnimation />}
								{item?.type === 'map' && (
									<div
										style={{
											height: '100vh',
											width: '100vw',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'flex-start',
										}}>
										<Info />
										<HomeFooter />
									</div>
								)}
							</SwiperSlide>
						)
					} else {
						return (
							<SwiperSlide key={index.toString()} style={{ alignItems: 'flex-start' }}>
								{item?.type !== 'footer' && (
									<CustomContentWrap
										style={{
											height: '100%',
											width: '100%',
											alignSelf: 'center',
											justifyContent:
												path === 'about' && currentPage === 1 ? 'center' : 'flex-start',
										}}
										dangerouslySetInnerHTML={{
											__html: decodeURIComponent(item?.content),
										}}
									/>
								)}

								{item?.type === 'footer' && (
									<div
										style={{
											height: width < 400 ? '80vh' : '70vh',
											width: '100vw',
											alignSelf: 'flex-end',
										}}>
										<Footer />
									</div>
								)}
							</SwiperSlide>
						)
					}
				})}
			</Swiper>
		</div>
	)
}

export default CustomComp
