import React, { useEffect } from 'react'
import '../../custom.css'
import { useHistory } from 'react-router-dom'

function PostError() {
	const navigation = useHistory()

	useEffect(()=>{
		alert('잘못된 접근 경로입니다')
		navigation.push('/ideas')
	},[])

	return (
		null
	)
}

export default PostError