import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { MotionItem } from './styles'
import { useHistory, useLocation } from 'react-router-dom'
import API from 'utils/api'
const MobileDrawerItem = ({ setPath }) => {
	const navigate = useHistory()
	const location = useLocation()
	const currentPaths = location.pathname.split('/')

	const nav = (url: string) => navigate.push(url)

	const [menus, setMenus] = useState<any[]>([])

	const getMenus = async () => {
		try {
			const res = await API.send('GET', '/system/usermenus', null, {})

			if (res.result.success) {
				const mainMenu = res.result.originalMenu.filter(menu => menu.level === 1)

				let menuArr: any = []

				mainMenu.map(menu => {
					const subMenu = res.result.originalMenu.filter(sub => menu.id === sub.parent_menuid)
					let data: any = []
					subMenu.map(item =>
						data.push({
							title: item.name,
							goTo: `/${menu.path}/${item.path}`,
						})
					)
					const menuData = {
						...menu,
						data,
					}
					return menuArr.push(menuData)
				})

				setMenus(menuArr)
			} else {
			}
		} catch (error) {}
	}

	useEffect(() => {
		getMenus()
	}, [])

	return (
		<MotionItem style={{ width: '100%', position: 'absolute', height: '70vh' }}>
			{menus.map((item, index) => (
				<motion.a
					key={index.toString()}
					onClick={() => {
						setPath(item.path)
						nav(`/${item.path}`)
					}}
					whileHover={{ scale: 1.1 }}
					variants={{
						closed: {
							opacity: 0,
						},
						open: { opacity: 1 },
					}}
					className="motion-item"
					style={{
						marginTop: 30,

						textDecoration: 'none',
						fontSize: '1.75rem',
						fontWeight: 600,
						display: 'block',
						fontFamily: 'Noto Sans KR',
						cursor: 'pointer',
						color: '#000',
						textShadow: `
							-1px -1px 0 #e1e1e1,
							-1px 0px 0 #e1e1e1,
							-1px 1px 0 #e1e1e1,
							0px -1px 0 #e1e1e1,
							0px 1px 0 #e1e1e1,
							1px -1px 0 #e1e1e1,
							1px 0px 0 #e1e1e1,
							1px 1px 0 #e1e1e1
						`,
					}}>
					{item?.name}
				</motion.a>
			))}
		</MotionItem>
	)
}

export default MobileDrawerItem
