import { useWindowDimensions } from 'app/hooks'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Text, View } from 'styles/reactnative'

const generateRandomDirection = () => {
	const directions = ['left', 'right', 'top', 'bottom', 'topLeft']
	const randomIndex = Math.floor(Math.random() * directions.length)
	return directions[randomIndex]
}

const generateAnimation = direction => {
	let animation

	switch (direction) {
		case 'left':
			animation = keyframes`
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
        }
      `
			break
		case 'right':
			animation = keyframes`
        0% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(100%);
        }
      `
			break
		case 'top':
			animation = keyframes`
        0% {
          transform: translateY(100%);
        }
        100% {
          transform: translateY(-100%);
        }
      `
			break
		case 'bottom':
			animation = keyframes`
        0% {
          transform: translateY(-100%);
        }
        100% {
          transform: translateY(100%);
        }
      `
			break
		case 'topLeft':
			animation = keyframes`
        0% {
          transform: translate(100%, 100%);
        }
        100% {
          transform: translate(-100%, -100%);
        }
      `
			break
		default:
			animation = keyframes`
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
        }
      `
	}

	return animation
}

const Container = styled.div`
	overflow: hidden;
`

const MarqueeItem = styled.div<{ direction?: any }>`
	display: inline-block;
	margin-right: 20px;
	white-space: nowrap;
	letter-spacing: 5px;
	text-transform: uppercase;
	font-size: 40px;
	font-weight: bold;
	animation: ${({ direction }) => generateAnimation(direction)} 5s linear infinite;
	@media screen and (max-width: 600px) {
		font-size: 30px;
	}
`

const CustomTextAnimation = () => {
	const { width } = useWindowDimensions()
	const data = [
		Array(15).fill('stock'),
		Array(15).fill('fintech'),
		Array(15).fill('security'),
		Array(15).fill('coin'),
	]

	return (
		<Container
			style={{
				width: window.innerWidth * data.length,
				flexDirection: 'column',
				justifyContent: 'space-between',
				display: 'flex',
				alignItems: 'flex-start',
			}}>
			<View
				style={{
					width: '100vw',
					alignItems: 'flex-start',
					marginLeft: 15,
					paddingTop: 50,
				}}>
				<Text
					style={{
						color: '#000',
						fontWeight: 'bold',
						fontSize: width < 600 ? 30 : 40,
						lineHeight: 2,
						letterSpacing: 2,
						marginTop: 10
					}}>
					Portfolio
				</Text>
				<Text style={{ color: '#000', fontWeight: 400, fontSize: 18 }}>
					보안상의 이유로 키워드만 공개합니다.
				</Text>
			</View>
			<View
				style={{
					height: '30%',
					width: '100%',
					paddingTop: 50,
					alignSelf: 'flex-end',
					display: 'flex',
				}}>
				{data.map((array, index) => {
					const direction = generateRandomDirection()
					return (
						<div key={index} style={{ height: '30vh' }}>
							{array.map((item, i) => (
								<MarqueeItem
									key={i}
									direction={direction}
									style={{
										fontFamily: 'Noto Sans KR',
										color: '#fff',
										textShadow: `
									-1px -1px 0 #999,
									-1px 0px 0 #999,
									-1px 1px 0 #999,
									0px -1px 0 #999,
									0px 1px 0 #999,
									1px -1px 0 #999,
									1px 0px 0 #999,
									1px 1px 0 #999
								`,
									}}>
									{item}
								</MarqueeItem>
							))}
						</div>
					)
				})}
			</View>
		</Container>
	)
}

export default CustomTextAnimation
