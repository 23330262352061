import React from 'react'
import moment from 'moment'
import {
	Close,
	CreateButton,
	DataRowWrapHeader,
	DataRowWrap,
	ModalWrap,
} from 'styles/globalPage-styles'
import {
	Modal,
	ModalTitle,
	ModalTitleLeft,
	TableContainer,
	ModalContent,
	ReadAt,
	Receiver,
	ReceiverWrap,
	DateTitle,
	ReceiverTitle,
	ListContainer,
	TextContent,
	DataName,
	TextDataRowWrap,
	TextContentName,
} from './styles'
import titleIcon from '../../../../../assets/icons/memberInfoIcon.png'
import close from '../../../../../assets/icons/close.png'

type Props = {
	data: any
	handleModal: () => void
}

function NotificationModal({ data, handleModal }: Props) {
	return (
		<ModalWrap>
			<Modal>
				<ModalTitle>
					<ModalTitleLeft>
						<img src={titleIcon} alt={'...'} />
						알림 정보
					</ModalTitleLeft>
					<Close onClick={handleModal}>
						<img src={close} alt={'...'} />
					</Close>
				</ModalTitle>
				<TableContainer>
					<DataRowWrapHeader>
						<DataName>구분</DataName>
						<ModalContent>내용</ModalContent>
					</DataRowWrapHeader>
					<DataRowWrap>
						<DataName>작성일</DataName>
						<ModalContent>
							{moment(data[0]?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}
						</ModalContent>
					</DataRowWrap>
					<DataRowWrap>
						<DataName>발신자</DataName>
						<ModalContent>{data[0]?.sender}</ModalContent>
					</DataRowWrap>
					<TextDataRowWrap>
						<TextContentName>내용</TextContentName>
						<TextContent>{data[0].content}</TextContent>
					</TextDataRowWrap>
				</TableContainer>
				<ListContainer>
					<ReceiverWrap>
						<ReceiverTitle>수신자</ReceiverTitle>
						<DateTitle>확인날짜</DateTitle>
					</ReceiverWrap>
					{data?.map(d => {
						return (
							<ReceiverWrap>
								<Receiver>{d.receiver}</Receiver>
								<ReadAt>
									{d.read_at
										? moment(d.read_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')
										: '-'}
								</ReadAt>
							</ReceiverWrap>
						)
					})}
				</ListContainer>
				<CreateButton onClick={handleModal}>확인</CreateButton>
			</Modal>
		</ModalWrap>
		// <div style={{ width: "300px", height: "400px", paddingTop: "30px"}}>
		// 	<div style={{ border: "1px solid white", backgroundColor: "white", color: "black", marginBottom: "10px"}}>작성일 : {
		// 	moment(data[0]?.created_at) .tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}</div>
		// 	<div style={{ border: "1px solid white", backgroundColor: "white", color: "black", marginBottom: "10px", minHeight: "200px" }}>{data[0].content}</div>
		// 	<div style={{ border: "1px solid white", backgroundColor: "white", color: "black", marginBottom: "10px" }}>발신자 : {data[0].sender}</div>
		// 	<table  style={{ border: "1px solid white", backgroundColor: "white", color: "black", marginBottom: "10px" }}>
		// 		<tr>
		// 			<td style={{width: "100px"}}>수신자</td>
		// 			<td style={{width: "50px"}}>읽음</td>
		// 			<td style={{width: "150px"}}>읽은날짜</td>
		// 		</tr>

		// 	</table>

		// </div>
	)
}

export default NotificationModal
