import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Header } from 'app/organisms/'
import { CToast } from 'app/components/Common'
import BoardManagement from '../../pages/CMSPage/CMSBody/Board'
import {
	ContentContainer,
	ContentRowSplit,
	ContentWrapper,
} from 'styles/globalPage-styles'
import LeftMenu from 'app/organisms/LeftMenu'
import { useToken } from 'app/hooks'
import { useHistory } from 'react-router-dom'

export const LogoutPage = () => {
	const { mutate } = useToken('', false)
	const navigate = useHistory()

	useEffect(() => {
		mutate(null)
		navigate.push('/admin/login')
	}, [])

	return null
}
