import React from 'react'
import { useHistory } from 'react-router-dom'
import logo from '../../../assets/images/CILABS.png'
import { HeaderContainer1, HeaderLogo } from './styles'

const Header = () => {
	const navigate = useHistory()

	const nav = (url: string) => navigate.push(url)

	return (
		<>
			<HeaderContainer1>
				<HeaderLogo
					onClick={() => {
						nav('/')
					}}>
					<img src={logo} alt={'logo'} height={'80%'} />
				</HeaderLogo>
			</HeaderContainer1>
		</>
	)
}

export default Header
