import React, { useEffect, useState } from 'react'
import API from 'utils/api'
import './custom.css'
import Header from './Header'
import Footer from './Footer'
import Menu from './Menu'
import Info from './Info'
import { CustomPageWrap } from './styles'
import { useWindowDimensions } from 'app/hooks'

import 'swiper/css'
import 'swiper/css/pagination'
import { CustomContentWrap } from './styles'

import styled from 'styled-components'
import FloatButtonIcon from '../../../assets/icons/floating_btn.png'
import ideaText from '../../../assets/icons/ideaText.png'

import { useHistory } from 'react-router'
import ProcessSlider from './ProcessSlider'

import CustomMobile from './CustomMobile'
import CustomPopup from './CustomPopup'

const Custom = () => {
	const { width } = useWindowDimensions()
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [customDatas, setCustomDatas] = useState<any>([])
	const [customDatasForMobile, setCustomDatasForMobile] = useState<any>([])
	const [customDatasForWeb, setCustomDatasForWeb] = useState<any>([])
	const [showText, setShowText] = useState(false)

	const [path, setPath] = useState('')
	const [view, setView] = useState('web')
	const navigation = useHistory()
	const [popups, setPopups] = useState<any[]>([])

	const getPopups = () => {
		try {
			API.get(`/popup/page/all?active=true&maxData=20&page=1`, null, {}).then(res => {
				if (res?.result?.success) {
					setPopups(res?.result?.result?.popups)
				}
			})
		} catch (error) {
			console.log(error)
		}

	}

	const handleResize = () => {
		setWindowWidth(window.innerWidth)
	}

	const setCustomDatasByLayout = (dats: any[]) => {
		let webfilter = dats.filter(r => r.layout == 'web')
		let mobilefilter = dats.filter(r => r.layout == 'mobile')

		if (webfilter.length < 1) {
			webfilter = mobilefilter
		}

		if (mobilefilter.length < 1) {
			mobilefilter = webfilter
		}

		setCustomDatasForWeb(webfilter)
		setCustomDatasForMobile(mobilefilter)

		if (windowWidth > 1000) {
			setCustomDatas(webfilter)
		} else {
			setCustomDatas(mobilefilter)
		}
	}

	const getContent = (url: string) => {
		setCustomDatasForWeb([])
		setCustomDatasForMobile([])
		setCustomDatas([])

		API.get(`/custom/pages/index/${url}`, null, {}).then(res => {
			if (res?.result?.length > 0) {
				setCustomDatasByLayout(res.result)
			} else {
				setCustomDatas([])
			}
		})
	}

	useEffect(() => {
		if (view == 'web') {
			setCustomDatas(customDatasForWeb)
		} else {
			setCustomDatas(customDatasForMobile)
		}
	}, [view])

	useEffect(() => {
		if (view == 'web' && windowWidth < 1000) {
			setView('mobile')
		} else if (view == 'mobile' && windowWidth > 1000) {
			setView('web')
		}
	}, [windowWidth])

	useEffect(() => {
		getPopups();
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		const url = window.location.pathname?.split('/')?.[1]?.split('?')[0]

		if (!url) {
			setPath('home')
			getContent('home')
		} else {
			setPath(url)
			getContent(url)
		}
	}, [window.location.pathname])

	return (
		<CustomPageWrap>
			{width > 1000 ? (
				<>
					{path === 'home' && (
						<FloatButton>
							<div style={{ display: showText ? '' : 'none' }}>
								<img src={ideaText} alt={'...'} />
							</div>
							<div>
								<img
									alt={'...'}
									src={FloatButtonIcon}
									onMouseEnter={() => {
										setShowText(true)
									}}
									onMouseLeave={() => {
										setShowText(false)
									}}
									onClick={() => {
										navigation.push('/ideas')
									}}
								/>
							</div>
						</FloatButton>
					)}

					<Header />
					<div style={{ position: 'sticky', top: '0px', zIndex: '999' }}>
						<Menu setPath={setPath} />
					</div>
					{customDatas?.map((item, index) => {
						let data = decodeURIComponent(item?.content)
						let slider = false

						if (data.includes('[slider]')) {
							slider = true
						}

						const input = data
						const regex = /\[slider\]([\s\S]*?)\[\/slider\]/
						const sliderResult: any = regex.exec(input)
						const sliderContent = sliderResult?.[1]
						const imgRegex = /<img[^>]+src="([^">]+)"/g
						data = data.replace(/\[slider\]([\s\S]*?)\[\/slider\]/g, '')
						let imgResult: any
						const imgSources: any = []
						while ((imgResult = imgRegex?.exec(sliderContent)) !== null) {
							if (imgResult[1]) {
								imgSources.push(imgResult[1])
							}
						}

						return (
							<>
								{slider ? (
									<ProcessSlider item={item} />
								) : (
									<CustomContentWrap
										key={index.toString()}
										style={{ backgroundColor: '#fff' }}
										width={`${item?.width}${item?.widthunit}`}
										height={`${item?.height}${item?.heightunit}`}
										dangerouslySetInnerHTML={{
											__html: decodeURIComponent(item?.content),
										}}
									/>
								)}
							</>
						)
					})}
					{(path === 'home' || path === 'contact') && <Info />}
					<Footer />
					{
						popups?.map((popup: any, index: number) => {
							return <CustomPopup 
								index={index}
								width={`${popup.width}${popup.widthUnit ? popup.widthUnit : 'px'}`}
								height={`${popup.height}${popup.heightUnit ? popup.heightUnit : 'px'}`}
								locationX={popup.location_x}
								locationY={popup.location_y}
								content={popup.content}
							/>
						})
					}
				</>
			) : (
				<>
					<CustomMobile path={path} setPath={setPath} customDatas={customDatas} />
				
					{
						popups?.map((popup: any, index: number) => {
							if(popup?.mobile)
							return <CustomPopup 
								index={index}
								width={`${popup.width}${popup.widthUnit ? popup.widthUnit : 'px'}`}
								height={`${popup.height}${popup.heightUnit ? popup.heightUnit : 'px'}`}
								locationX={popup.location_x}
								locationY={popup.location_y}
								content={popup.content}
							/>
						})
					}
				</>
				
			)}
		</CustomPageWrap>
	)
}

export default Custom

const FloatButton = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	position: fixed;
	bottom: 10px;
	right: 10px;
	z-index: 999;

	&:hover {
		cursor: pointer;
	}
`

export const CustomPopupWrap = styled.div<{ width?: string; height?: string, x?: string, y?: string,  }>`
	position: absolute;
	width: 300px;
	height: 300px;
	left:  ${props => props.x};
	top:  ${props => props.y};

	margin: 0 auto 300px auto;
	/* display: flex;
	align-items: center; */
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 1000px) {
		/* margin: 0 auto 100px auto; */

		width: 100%;
		margin: 0;
	}
`