import styled from 'styled-components'

export const SecondSider = styled.div`
	.ant-layout-sider-trigger {
		background-color: #fff !important;
		color: #000 !important;
		display: none;
		/* change Sider button toggle color */
	}

	.ant-layout-sider {
		background-color: #fff !important;
		/* change Sider background color */
		padding: 0;
	}

	.ant-menu-item-selected > a,
	.ant-menu-item-active > a {
		color: white !important;
		/* change active menu color */
	}
`
export const FirstSider = styled.div`
	padding: 0;;

	& .ant-layout-sider-trigger {
		background-color: #fff !important;
		color: #000 !important;
	}

	& .ant-layout-sider {
		padding: 0;
	}
	& .ant-menu-item {
		background-color: #f5f5f5;
		margin: 0;
		width: 100%;
		border: 0;
		border-radius: 0;
		color: #000 !important;
	}

	& .ant-menu-item-selected,
	.ant-menu-item-active {
		background-color: #fff !important;
		color: #000 !important;
	}

	& .ant-menu-item-selected > a,
	& .ant-menu-item-active > a {
		background-color: red !important;
		color: #000 !important;
	}
`
