import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { HeaderButtonWrap } from './styles'
import { useToken, useUser } from 'app/hooks'
import { View } from 'styles/reactnative'
import { apiError } from 'utils/ExpensiveFunction'
import HeaderButton from './HeaderButton'
import API from 'utils/api'
import styled from 'styled-components'
import logo from '../../../assets/images/logo.png'
import SignupForm from 'app/pages/CMSPage/CMSBody/Users/SignupForm'

type Menu = {
	id: number
	name: string
	path: string
	order: number
	level: number
	parent_menuid: number | null
	data?: {
		title: string
		goto: string
	}[]
}

const Header = () => {
	const { t } = useTranslation()
	const navigate = useHistory()
	const location = useLocation()
	const currentPaths = location.pathname.split('/')

	const { data: token, mutate: mutateToken } = useToken()
	const { data: username, mutate: mutateUsername } = useUser()

	const [displayNav, setDisplayNav] = useState('none')

	const nav = (url: string) => navigate.push(url)

	const [showProfileModal, setShowProfileModal] = useState(false)
	const [menus, setMenus] = useState<Menu[]>([])
	const toggleNavBar = () => {
		setDisplayNav(prevState => (prevState === 'none' ? 'flex' : 'none'))
	}

	const [data, setData] = useState<any>()
	const [myProfile, setMyProfile] = useState(false)

	const logout = () => {
		mutateToken(null)
		nav('/admin/login')
	}

	const getMenus = async () => {
		try {
			const res = await API.send('GET', '/system/menus', null, {})

			if (res.result.success) {
				const mainMenu = res.result.result.filter(
					menu => menu.level === 1
				)

				let menuArr: any = []

				mainMenu.map(menu => {
					const subMenu = res.result.result.filter(
						sub => menu.id === sub.parent_menuid
					)

					let data: any = []
					subMenu.map(item => {
						data.push({
							title: item.name,
							goTo: `/admin/${menu.path}/${item.path}`,
						})
					})
					const menuData = {
						...menu,
						data,
					}
					menuArr.push(menuData)
				})

				setMenus(menuArr)
			} else {
				const errorResult = apiError(t, res.result.msgcode || '')
			}
		} catch (error) {}
	}

	const handleModal = () => {
		myProfile && setMyProfile(false)
	}

	const getMyProfile = () => {
		let apiurl = `/user/my/info`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setData(res.result.result)
				}
			})
			.catch((err: any) => {})
	} 

	const ProfileForm = (
		<SignupForm
			handleModal={handleModal}
			roles={[]}
			data={data}
			insertNewUser={()=>{}}
			userOnly={true}
		/>
	)


	// const getMyProfile = () => {
	// 	setShowProfileModal(true)
	// }

	useEffect(()=>{
		if(data)setMyProfile(true)
	},[data])
	useEffect(() => {
		getMenus()
	}, [])

	return (
		<HeaderContainer>
			<HeaderContainer1>
				<HeaderLogo
					onClick={() => {
						nav('/admin')
					}}>
					<img src={logo} alt={'logo'} height={'80%'} />
				</HeaderLogo>
				<HeaderRight>
					{username && <HeaderUsername onClick={()=>getMyProfile()}>{username} 님</HeaderUsername>}
					<span>|</span> 
					{ token && <HeaderRightSpan onClick={logout}>로그아웃1</HeaderRightSpan>}
				</HeaderRight>
			</HeaderContainer1>
			<NavigationBarContainer>
				<HeaderButtonWrap display={displayNav}>
					{/* <AppLogo width={100} onPress={() => nav('/')} /> */}
					<View style={{ flexDirection: 'row' }}>
						{token && (
							<View style={{ flexDirection: 'row' }}>
								{menus.map(menu => {
									return (
										<HeaderButton
											label={menu.name}
											data={menu.data && menu.data}
											onPress={() =>
												nav(`/admin/${menu.path}`)
											}
											notIcon={true}
											active={
												currentPaths.indexOf(
													menu.path
												) >= 0
											}
										/>
									)
								})}
							</View>
						)}
					</View>
					{/* Menu Right Side */}
					<View style={{ flexDirection: 'row' }}>
						{/* {!token ? <UnAuthHeaderMenu /> : null}*/}
					{/* {token ? <AuthHeaderMenu /> : null} */}
					</View>
				</HeaderButtonWrap>
			</NavigationBarContainer>
			{
				myProfile &&
				ProfileForm
			}
		</HeaderContainer>
	)
}

export default Header

const HeaderContainer = styled.div`
	width: 100%;
`

const HeaderContainer1 = styled.div`
	/* position: absolute; */
	width: 1204px;
	height: 65px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	/* padding: 15px 0;
	 */
	padding: 15px 0px 15px 25px;
	background-color: #ffffff;
`

const HeaderLogo = styled.div`
	width: 160px;
	height: 34.62px;
	margin-left: 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
`

const HeaderRight = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 4px;
	gap: 5px;

	height: 28px;
`

const HeaderUsername = styled.span`
	/* width: 59px; */
	height: 20px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;

	/* text/secondary */

	color: #4e5664;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;

	&:hover{
		cursor: pointer;
		text-decoration: underline;
	}
`

const HeaderRightSpan = styled.span`
	/* width: 59px; */
	height: 20px;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;

	/* text/secondary */

	color: #4e5664;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;

	&:hover{
		cursor: pointer;
	}
`

const NavigationBarContainer = styled.div`
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: flex-start;

	/* main */

	background: #2da0d2;
`
