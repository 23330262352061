import styled from 'styled-components'

export const TableContainer = styled.div``
export const TableListSection = styled.div`
	width: 100%;
	background-color: transparent;
`

export const Form = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 0;

	width: 100%;
	/* height: 221px; */

	/* table/border */
`
export const UserData = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	/* width: 380px; */
	height: 47px;
	line-height: 47px;

	background: #ffffff;
`

export const ColumnTitle = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 50px;

	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const ContentTitle = styled.div`
	box-sizing: border-box;

	width: 370px;
	height: 50px;

	/* table/bg */

	background: #fafafa;
	/* table/border */
	font-size: 12px;
	text-align: center;

	/* text/tertiary */

	color: #999999;

	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const Column = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 50px;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;

	background: #ffffff;
	/* table/border */

	border-width: 1px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const Content = styled.div`
	box-sizing: border-box;

	/* Auto layout */
	padding: 0 5px;

	width: 370px;
	height: 50px;

	background: #ffffff;
	/* table/border */

	font-size: 13px;

	/* text/contents */

	color: #666666;

	border-width: 1px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const CustomDataColumn = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 60px;
	line-height: 60px;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;

	background: #ffffff;
	/* table/border */

	border-width: 1px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
export const ContentTextarea = styled.div`
	width: 370px;
	height: 60px;

	padding: 5px 10px;

	overflow-x: hidden;
	overflow-y: auto;

	resize: none;

	background: #ffffff;
	/* table/border */

	font-size: 13px;

	/* text/contents */

	color: #666666;

	border-width: 1px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;

	/* align-items: flex-start; */
	padding: 0px;
	gap: 8px;

	text-align: center;
	width: 100%;
	height: 30px;
`

export const UpdateButton = styled.button`
	padding: 0px 10px;

	width: 66px;
	height: 30px;

	margin: 15px auto;

	/* content/accentBlue */

	border: none;
	background: #0a387d;
	border-radius: 2px;
	font-size: 12px;
	font-weight: 500;
	/* text/white */

	color: #ffffff;
`

export const SaveButton = styled.button`
	padding: 0px 10px;

	width: 66px;
	height: 30px;

	/* margin: 10px auto; */
	/* content/accentBlue */

	font-size: 12px;
	line-height: 17px;

	/* text/white */

	color: #ffffff;

	background: #0a387d;
	border: none;
	border-radius: 2px;
`

export const CancelButton = styled.button`
	padding: 0px 10px;

	width: 66px;
	height: 30px;

	font-size: 12px;
	line-height: 17px;

	/* text/white */

	color: #ffffff;

	/* content/accentRed */

	background: #d44242;
	border: none;
	border-radius: 2px;
`
