import React from 'react'
import { View } from 'styles/reactnative'
import NotificationManagementTable from './NotificationManagementTable'
import { useWindowDimensions } from 'app/hooks'

const NotificationManagement = () => {
	const { height } = useWindowDimensions()
	return (
		<View
			style={{
				width: '100%',
				padding: 10,
				background: '#fff',
				paddingBottom: height / 3,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<NotificationManagementTable />
		</View>
	)
}

export default NotificationManagement
