import React, { useState, useEffect } from 'react'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import { TitleWrap, TitleWrapLeft, TableWrap } from 'styles/globalPage-styles'
import moment from 'moment'
import styled from 'styled-components'
import * as antd from 'antd'

type Props = {
	createComment: (content: string, lock: boolean) => void
}

function CommentInput({ createComment }: Props) {
	const [content, setContent] = useState('')
	const [lock, setLock] = useState(false)

	const create = () => {
		createComment(content, lock)

		setContent('')
	}

	return (
		<ContentWrap>
			<Comment
				placeholder="댓글을 작성해 주세요"
				onChange={e => setContent(e.target.value)}
				value={content}></Comment>
				<CreateButton onClick={create}>작성</CreateButton>

			{/* <label  style={{ color: "white"}} htmlFor="lock">비공개</label>
            <input type="checkbox" id="lock" onChange={(e)=>setLock(e.target.checked)}></input>
            <button onClick={create}>등록</button> */}
		</ContentWrap>
	)
}

export default CommentInput

const ContentWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 20px;

	width: 100%;
`
const Comment = styled.textarea`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 20px;

	overflow-y: auto;
	overflow-x: hidden;

	width: 90%;
	height: 50px;

	background: #FFFFFF;
	border: 1px solid #D4D4D4;
	border-radius: 1px;

	resize:none;
`

const CreateButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;

	width: 85px;
	height: 50px;

	background: #7E7E7E;
	border-radius: 1px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	/* identical to box height */

	display: flex;
	align-items: center;

	color: #FFFFFF;
	&:hover{
		cursor: pointer;
	}

`