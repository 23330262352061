// import React from 'react'
// import { View } from 'styles/reactnative'
// import BoardManagementTable from './BoardManagementTable'
// import BoardTable from '../Board/BoardTable'

// type Props = {
// 	boardPath: string
// }
// const BoardManagement = ({ boardPath }: Props) => {
// 	return (
// 		<View
// 			style={{
// 				flex: 1,
// 				alignItems: 'flex-start',
// 				justifyContent: 'flex-start',
// 			}}>
// 			{!boardPath ? <BoardManagementTable boardPath={boardPath} /> : <BoardTable />}
// 		</View>
// 	)
// }

// export default BoardManagement
import React from 'react'
import { View } from 'styles/reactnative'
import BoardManagementTable from './BoardManagementTable'
import BoardTable from '../Board/BoardTable'
import { useWindowDimensions } from 'app/hooks'

const BoardManagement = () => {
	const { height } = useWindowDimensions()
	return (
		<View
			style={{
				width: '100%',
				padding: 10,
				background: '#fff',
				paddingBottom: height / 3,
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
			}}>
			<BoardManagementTable />
		</View>
	)
}

export default BoardManagement
