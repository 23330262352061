import styled from 'styled-components'

export const AdminHeaderSection = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	width: 100%;
	padding-inline: 25px;
`
