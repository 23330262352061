import React, { useEffect, useState } from 'react'
import { View } from 'styles/reactnative'
import { useToken } from 'app/hooks'
import HeaderLogout from './HeaderLogout'
import HeaderRightDetail from './HeaderRightDetail'
import API from 'utils/api'
import SignupForm from 'app/pages/CMSPage/CMSBody/Users/SignupForm'


const HeaderRightSection = () => {
	const { data: token } = useToken()

	return (
		<View
			style={{
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}>
				{token ? <HeaderRightDetail /> : null}
			<View style={{ marginRight: 10 }}>|</View>
			<HeaderLogout />
		</View>
	)
}

export default HeaderRightSection
