import React, { useContext } from 'react'
import { ToastContainer } from 'react-toastify'
import { IconX } from '@tabler/icons'
import { COLORS } from 'app/constants'
import { ThemeContext } from 'theme/ThemeProvider'
import CButton from '../CButton'
const CToast = () => {
	const { bgColor, textw, gray } = useContext(ThemeContext).theme.COLORS
	const CloseButton = ({ closeToast }) => (
		<CButton
			icon={<IconX size={14} color={COLORS.secondary} />}
			style={{
				fontSize: '1rem',
				background: gray,
				color: textw,
				borderRadius: 50,
				border: 'none',
				padding: 3,
			}}
			onClick={closeToast}
		/>
	)

	return (
		<ToastContainer
			position="top-center"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="colored"
			closeButton={CloseButton}
			toastStyle={{
				backgroundColor: bgColor,
				color: textw,
				borderRadius: '5px',
			}}
		/>
	)
}

export default React.memo(CToast)
