import styled from 'styled-components'
import { animated } from 'react-spring'
export const MobileHeaderSection = styled.div`
	height: 70px;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-inline: 16px;
	z-index: 9999 !important;
`
export const MobileAppName = styled.div`
	color: #ffffff;
	font-weight: 600;
	font-size: 26px;
	font-family: 'Montserrat', sans-serif;
	letter-spacing: -0.015em;
	flex: none;
	order: 0;
	flex-grow: 0;
	cursor: pointer;
	z-index: 9999;
`
export const AnimDiv = styled(animated.div)<any>``
export const CustomBtn = styled.div`
	z-index: 9999 !important;
`
export const MotionItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`
