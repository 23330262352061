import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'
//768px
export const HeaderContainer = styled.div`
	position: static;

	background-color: rgba(0, 0, 0, 0.5);
	top: 0;
	z-index: 2;
	width: 100%;
	color: #ccc;
	font-size: 1.4rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	@media (min-width: 900px) {
		justify-content: center;
	}
`

// eslint-disable-next-line prettier/prettier
export const HeaderButtonWrap = styled.div<{ display: string }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
	max-width: 1280px;
	margin-left: 200px;
	justify-content: flex-start;
`

export const NavBarToggle = styled.span`
	position: absolute;
	right: 20px;
	cursor: pointer;
	align-self: center;
	color: rgba(255, 255, 255, 0.8);
	font-size: 24px;
	@media (min-width: 900px) {
		display: none;
	}
`
export const HeaderButtonSection = styled.div<{ hover?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 90px;
	height: 32px;

	&:hover {
		color: ${COLORS.white};
		background-color: ${COLORS.primary};
	}
	color: ${props => props.theme.COLORS.textw};
`
export const HeaderButtonText = styled.div`
	margin-left: 1rem;
	padding-right: 0.3rem;
	&:hover {
		color: ${COLORS.white};
	}
`

export const MobileView = styled.div`
	display: flex;
	align-items: center;
	width: 90%;
	padding-left: 20px;
	@media (min-width: 900px) {
		display: none;
	}
`
