import React, { useEffect, useState } from 'react'
import './custom.css'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Board from './Community/Board/Board'
import PostManagement from './Community/Post'
import PostError from './Community/Board/PostError'
import Custom from './Custom'

function CustomManage() {
	const [password, setPassword] = useState(false)

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/">
					<Custom />
				</Route>
				<Route exact path="/ideas">
					<Board setPassword={setPassword} />
				</Route>
				<Route exact path="/ideas/:id">
					{
						password
						?<PostManagement password={password} setPassword={setPassword}/>
						:<PostError />
					}
				</Route>
				<Route exact path="/:id" component={Custom} /> 
			</Switch>
		</BrowserRouter>
	)
}

export default CustomManage