import React, { useState, useEffect } from 'react'
import { useToken, useUser } from 'app/hooks/'
import API from 'utils/api'
import styled from 'styled-components'

type Props = {
	index: number
	width: string
	height: string
	locationX: number
	locationY: number
	content: string
}
function CustomPopup({ index, width, height, locationX, locationY, content }: Props) {
	let [show, setShow] = useState(true)

	return (
		<>
		{
			show
			?<CustomPopupWrap
				key={index.toString()}
				style={{ backgroundColor: '#fff' }}
				width={`${width}`}
				height={`${height}`}
				x={`${locationX}`}
				y={`${locationY}`}
			>
				<div onClick={()=>{setShow(false)}}>x</div>
				<div dangerouslySetInnerHTML={{
					__html: decodeURIComponent(content),
				}}></div>
			</CustomPopupWrap>
			: null
		}
		
		</>
	)
}

export default CustomPopup

export const CustomPopupWrap = styled.div<{ width?: string; height?: string, x?: string, y?: string,  }>`
	position: fixed;
	width: ${props => props.width};
	height: ${props => props.height};
	min-width: 300px;
	max-height: 300px;
	left:  ${props => props.x};
	top:  ${props => props.y};

	margin: 0 auto 300px auto;
	/* display: flex;
	align-items: center; */
	overflow-x: auto;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 999;
	@media screen and (max-width: 1000px) {
		/* margin: 0 auto 100px auto; */

		width: 100%;
		margin: 0;
	}
`