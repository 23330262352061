import styled, { css } from 'styled-components'

export const AdminLayoutWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	flex: 1;
`
export const AdminSidebarWrapper = styled.div<{ open: boolean }>`
	position: relative;
	width: 249px;
	transform: translateX(${props => (props.open ? '0' : '-100%')});
	transition: transform 0.3s ease-in-out;
	@media screen and (max-width: 600px) {
		width: 100%;
	}
`
export const AdminHeaderBody = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`

export const AdminHeaderWrapper = styled.div<{ visible: boolean }>`
	position: fixed;
	top: 0;
	left: ${props => (props.visible ? '249px' : '0')};
	right: 0;
	height: 70px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	transition: left 0.3s ease-in-out;
	border-bottom: 1px solid #e9e9e9;
	@media screen and (max-width: 600px) {
		left: ${props => (props.visible ? '249px' : '0')};
	}
`

export const AdminBodyLayoutWrapper = styled.div<{ open: boolean }>`
	margin: 0;
	margin-top: 70px;
	flex: 1;
	display: flex;
	transition: margin-left 0.3s ease-in-out;
	margin-left: ${props => (props.open ? '0' : '-249px')};
	@media screen and (max-width: 600px) {
		margin-left: ${props => (props.open ? '-249px' : '-496px')};
	}
`

export const MenuIconWrapper = styled.div<{ open: boolean }>`
	margin-left: 20px;
	display: block;
	transition: transform 0.3s ease-in-out;
	cursor: pointer;
	${props =>
		props.open &&
		css`
			transform: rotate(180deg);
		`}
`

export const AdminBodySection = styled.div<{ maxHeight: number }>`
	width: 100%;
	background-color: #fff;
	max-height: ${props => props.maxHeight - 70}px;
	overflow-y: scroll;
	overflow-x: hidden;
`
export const AdminBodyWrapper = styled.div`
	padding: 10px;
`
