export const ACCESS_TOKEN = 'LOCAL@ACCESS_TOKEN'
export const LOGIN_USERNAME = 'LOCAL@LOGIN_USERNAME'
export const STORE_HEADER = 'LOCAL@STORE_HEADER'
export const STORE_HEADER_BUTTON = 'LOCAL@STORE_HEADER_BUTTON'
export const STORE_TRADE = 'LOCAL@STORE_TRADE'
export const STORE_MODAL_TRADE = 'LOCAL@STORE_TRADEMODAL'
export const STORE_EXCHANGE = 'LOCAL@STORE_EXCHANGE'
export const STORE_PRICE = 'LOCAL@STORE_PRICE'
export const STORE_FAVOURITE = 'LOCAL@STORE_FAVOURITE'
export const STORE_DEPOSIT = 'LOCAL@DEPOSITSTORE'

export const TRADE_SOCKET = 'LOCAL@TRADE_SOCKET'
export const FLASH_QUEUE = 'LOCAL@FLASHQUEUE'

export const THEME_STORE = 'LOCAL@THEMESTORE'
