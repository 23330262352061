import React from 'react'
import { Helmet } from 'react-helmet-async'
import { CToast } from 'app/components/Common'
import CustomManagement from '../../pages/CMSPage/CMSBody/Custom'
import { HeaderAdmin } from 'app/components'
import { Layout } from 'antd'
import { SideBar } from 'app/organisms'
const { Content } = Layout

export const CustomPage = () => {
	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Helmet>
				<title>시스템설정</title>
			</Helmet>
			<CToast />
			<SideBar />
			<Layout className="site-layout" style={{ background: '#fff' }}>
				<HeaderAdmin />
				<Content>
					<CustomManagement />
				</Content>
			</Layout>
		</Layout>
	)
}
