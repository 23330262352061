import React from 'react'
import { useHistory } from 'react-router-dom'
import CILABS from 'assets/images/CILABS_WHITE.png'
import Frame from 'assets/images/Frame2.png'
import Kakao from 'assets/icons/kakao.png'
import Tele from 'assets/icons/tele.png'
import { Container, Top, SocialIconWrap, MenuWrap, Menu, Bottom, Info } from '../../styles'

const Footer = () => {
	const navigate = useHistory()

	const nav = (url: string) => navigate.push(url)
	const pageMove = (url: string) => {
		window.open(url)
	}

	return (
		<>
			<Container>
				<Top>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							height: 120,
						}}>
						<img src={CILABS} alt="..." />
						<SocialIconWrap style={{ marginTop: 30 }}>
							<img src={Tele} alt="..." onClick={() => pageMove('https://t.me/CILABS_kr')}></img>
							<img
								src={Kakao}
								alt="..."
								onClick={() => pageMove('http://pf.kakao.com/_DxnKcxj')}></img>
						</SocialIconWrap>
					</div>
					<MenuWrap>
						<Menu onClick={() => nav('/about')}>About Us</Menu>
						<Menu onClick={() => nav('/services')}>Services</Menu>
						<Menu onClick={() => nav('/portfolio')}>Portfolio</Menu>
						<Menu onClick={() => nav('/ideas')}>Ideas</Menu>
						<Menu onClick={() => nav('/contact')}>Contact</Menu>
					</MenuWrap>
				</Top>
				<Bottom style={{ marginTop: 10 }}>
					<img style={{ height: 'fit-content' }} src={Frame}></img>
					<Info style={{ marginTop: 10 }}>
						<p>씨아이랩스 | 경기도 고양시 일산동구 무궁화로 43-55, 성우사카르타워 4층 404호</p>
						<p style={{ marginTop: 20 }}>031.901.0815 | devteam@cilabs.co.kr</p>
					</Info>
				</Bottom>
			</Container>
		</>
	)
}

export default Footer
