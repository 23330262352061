import React, { useState, useEffect } from 'react'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import {
	DataRowWrapHeader,
	ButtonWrap,
	CreateButton,
	DataInputWrap,
} from 'styles/globalPage-styles'
import { TableContainer, RowDataName, TitleContent, RowDataContent, CheckboxWrap } from './styles'

import { CancelButton } from '../../BoardManage/styles'

import * as antd from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { View } from 'styles/reactnative'
import styled from 'styled-components'

type Props = {
	handleModal: () => void
	data?: any
	roles: any[]
	refreshList: () => void
	showModal?: boolean
}

function RoleForm({ showModal, handleModal, data, roles, refreshList }: Props) {
	const { data: token } = useToken()

	const [name, setName] = useState(data ? data[0].groupName : '')
	const [roleArr, setRoleArr] = useState<any[]>([])
	const [edit, setEdit] = useState(false)

	const [boardRoleArr, setBoardRoleArr] = useState<any[]>([])

	const initData = () => {}

	const getMenu = () => {
		let apiurl = `/system/menus`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					const mainMenus = res.result.result.filter(menu => menu.level === 1)
					let tempArr: any = []
					mainMenus.map(menu => {
						tempArr.push({
							type: menu.path,
							name: menu.name,
							select: false,
							insert: false,
							update: false,
							delete: false,
						})
					})
					setRoleArr(tempArr)
				}
			})
			.catch((err: any) => {})
	}

	const getBoards = () => {
		let apiurl = `/system/boards`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					console.log(res.result)
					let tempArr: any = []
					res.result.result.map(board => {
						tempArr.push({
							type: board.path,
							name: board.name,
							select: false,
							insert: false,
							update: false,
							delete: false,
						})
					})
					setBoardRoleArr(tempArr)
				}
			})
			.catch((err: any) => {})
	}

	const create = () => {
		let apiurl = '/system/role'

		if (!name || name.length > 20) {
			antd.message.info('권한명은 20자 이내로 작성해주세요')
			return
		}

		const find = roles.find(r => r.name === name)

		if (find) {
			antd.message.info('중복되는 권한명이 있습니다')
			return
		}

		API.post(apiurl, token + '', {
			name,
			roleArr,
			boardRoleArr,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('등록되었습니다')
					refreshList()
					handleModal()
				}
			})
			.catch((err: any) => {})
	}

	const validate = () => {
		let tempData: any = {}

		if (data[0].groupName != name) {
			tempData = {
				...tempData,
				name,
			}
		}

		const compareRole = [...roleArr, ...boardRoleArr]

		console.log(compareRole)

		let tempArr: any = []
		let tempIdx = 0
		data.map((item, index) => {
			if (item.select != compareRole[index].select)
				tempArr[tempIdx] = { ...tempArr[tempIdx], select: compareRole[index].select }
			if (item.insert != compareRole[index].insert)
				tempArr[tempIdx] = { ...tempArr[tempIdx], insert: compareRole[index].insert }
			if (item.update != compareRole[index].update)
				tempArr[tempIdx] = { ...tempArr[tempIdx], update: compareRole[index].update }
			if (item.delete != compareRole[index].delete)
				tempArr[tempIdx] = { ...tempArr[tempIdx], delete: compareRole[index].delete }
			if (tempArr[tempIdx]) {
				tempArr[tempIdx] = { ...tempArr[tempIdx], type: item.type, subtype: item.subtype }
				tempIdx++
			}
		})
		console.log(tempArr);
		

		if (tempArr.length > 0) {
			tempData = {
				...tempData,
				roleArr: tempArr,
			}
		}

		if (Object.keys(tempData).length < 1) return undefined
		return tempData
	}

	const update = () => {
		if (data?.length < 1) return

		let apiurl = `/system/role/${data[0].groupid}`

		let updateData = validate()

		if (!name || name.length > 20) {
			antd.message.info('권한명은 20자 이내로 작성해주세요')
			return
		}

		if (data[0].groupName != name) {
			const find = roles.find(r => r.name === name)

			if (find) {
				antd.message.info('중복되는 권한명이 있습니다')
				return
			}

			updateData = { ...updateData, name }
		}

		if (!updateData) {
			antd.message.info('수정사항이 없습니다')
			return
		}

		API.put(apiurl, token + '', { updateData })
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정완료')
					refreshList()
					handleModal()
				}
			})
			.catch((err: any) => {})
	}

	const settings = (e: CheckboxChangeEvent, path: string, index: number) => {
		const tempArr = [...roleArr]
		if (e.target.name) tempArr[index][e.target.name] = e.target.checked
		setRoleArr(tempArr)
	}

	const boardSettings = (e: CheckboxChangeEvent, index: number) => {
		console.log(e.target.name)
	
		const tempArr = [...boardRoleArr]
		if (e.target.name) tempArr[index][e.target.name] = e.target.checked

		console.log(tempArr)
		setBoardRoleArr(tempArr)
	}

	useEffect(()=>{
		console.log(boardRoleArr);
		
	},[boardRoleArr])

	useEffect(() => {
		if (!edit) initData()
	}, [edit])

	useEffect(() => {
		if (!data) {
			getMenu()
			getBoards()

			// getBoards()
		} else {
			setName(data[0].groupName)

			const boardData = data.filter((d)=>d.subtype != null)
			const menuData = data.filter((d)=>d.subtype == null)

			let tempArr1: any = [] 

			boardData.map((item, index) => {
				let sel = false
				let ins = false
				let upd = false
				let del = false

				console.log(item)

				if (item.select) sel = true
				if (item.insert) ins = true
				if (item.update) upd = true
				if (item.delete) del = true

				tempArr1[index] = {
					type: item.path,
					subtype: item.path,
					name: item.name,
					select: sel,
					insert: ins,
					update: upd,
					delete: del,
				}
			})
			setBoardRoleArr(tempArr1)

			let tempArr2: any = [] 
			menuData.map((item, index) => {
				let sel = false
				let ins = false
				let upd = false
				let del = false

				if (item.select) sel = true
				if (item.insert) ins = true
				if (item.update) upd = true
				if (item.delete) del = true

				tempArr2[index] = {
					type: item.path,
					name: item.name,
					select: sel,
					insert: ins,
					update: upd,
					delete: del,
				}
			})

			setRoleArr(tempArr2)
		}
	}, [])

	return (
		<antd.Modal
			title={!edit ? '권한 정보' : '권한 정보 수정'}
			footer={null}
			open={showModal}
			onOk={handleModal}
			onCancel={handleModal}
			width={850}>
			<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
				<TableContainer>
					<DataRowWrapHeader>
						<RowDataName>구분</RowDataName>
						<TitleContent>내용</TitleContent>
					</DataRowWrapHeader>
					<DataInputWrap>
						<RowDataName>권한명</RowDataName>
						<RowDataContent>
							{!data ? (
								<antd.Input
									type="text"
									name="name"
									onChange={e => setName(e.target.value)}
									value={name}></antd.Input>
							) : !edit ? (
								name
							) : (
								<antd.Input
									type="text"
									name="name"
									onChange={e => setName(e.target.value)}
									value={name}></antd.Input>
							)}
						</RowDataContent>
					</DataInputWrap>
					{roleArr?.map((role, index) => {
						return (
							<DataInputWrap>
								<RowDataName>{role.name}</RowDataName>
								<RowDataContent>
									<CheckboxWrap>
										<antd.Checkbox
											name="select"
											onChange={e => settings(e, role.path, index)}
											defaultChecked={data && role.select}
											disabled={!data ? false : !edit ? true : false}></antd.Checkbox>
										<label>조회</label>
									</CheckboxWrap>
									<CheckboxWrap>
										<antd.Checkbox
											type="checkbox"
											name="insert"
											onChange={e => settings(e, role.path, index)}
											defaultChecked={data && role.insert}
											disabled={!data ? false : !edit ? true : false}></antd.Checkbox>
										<label>생성</label>
									</CheckboxWrap>
									<CheckboxWrap>
										<antd.Checkbox
											type="checkbox"
											name="update"
											onChange={e => settings(e, role.path, index)}
											defaultChecked={data && role.update}
											disabled={!data ? false : !edit ? true : false}></antd.Checkbox>
										<label>수정</label>
									</CheckboxWrap>
									<CheckboxWrap>
										<antd.Checkbox
											type="checkbox"
											name="delete"
											onChange={e => settings(e, role.path, index)}
											defaultChecked={data && role.delete}
											disabled={!data ? false : !edit ? true : false}></antd.Checkbox>
										<label>삭제</label>
									</CheckboxWrap>
								</RowDataContent>
							</DataInputWrap>
						)
					})}
				</TableContainer>
				<TableContainer >
					<DataRowWrapHeaderTemp style={{ padding: "0 5px"}}>
						<div style={{width:"100%", textAlign: "center"}}>게시판 권한설정</div>
					</DataRowWrapHeaderTemp>
					<DataRowWrapHeader>
						<RowDataName>구분</RowDataName>
						<TitleContent>내용</TitleContent>
					</DataRowWrapHeader>
					{boardRoleArr?.map((board, index) => {
						return (
							<DataInputWrapTemp>
								<RowDataName>{board.name}</RowDataName>
								<RowDataContent>
									<CheckboxWrap>
										<antd.Checkbox
											name="select"
											onChange={e => boardSettings(e, index)}
											defaultChecked={data && board.select}
											disabled={!data ? false : !edit ? true : false}></antd.Checkbox>
										<label>조회</label>
									</CheckboxWrap>
									<CheckboxWrap>
										<antd.Checkbox
											type="checkbox"
											name="insert"
											onChange={e => boardSettings(e, index)}
											defaultChecked={data && board.insert}
											disabled={!data ? false : !edit ? true : false}></antd.Checkbox>
										<label>생성</label>
									</CheckboxWrap>
									<CheckboxWrap>
										<antd.Checkbox
											type="checkbox"
											name="update"
											onChange={e => boardSettings(e, index)}
											defaultChecked={data && board.update}
											disabled={!data ? false : !edit ? true : false}></antd.Checkbox>
										<label>수정</label>
									</CheckboxWrap>
									<CheckboxWrap>
										<antd.Checkbox
											type="checkbox"
											name="delete"
											onChange={e => boardSettings(e, index)}
											defaultChecked={data && board.delete}
											disabled={!data ? false : !edit ? true : false}></antd.Checkbox>
										<label>삭제</label>
									</CheckboxWrap>
								</RowDataContent>
							</DataInputWrapTemp>
						)
					})}
				</TableContainer>
			</div>
			<View style={{ marginTop: 10 }}>
				{!data ? (
					<ButtonWrap>
						<CreateButton onClick={create}>저장</CreateButton>
						<CancelButton onClick={handleModal}>취소</CancelButton>
					</ButtonWrap>
				) : !edit ? (
					<ButtonWrap>
						<CreateButton onClick={() => setEdit(true)}>수정</CreateButton>
					</ButtonWrap>
				) : (
					<ButtonWrap>
						<CreateButton onClick={update}>저장</CreateButton>
						<CancelButton onClick={handleModal}>취소</CancelButton>
					</ButtonWrap>
				)}
			</View>
		</antd.Modal>

		// <form>
		// 	<div style={{ display: "flex", alignItems: "center", margin: "30px 0 15px 0"}}>
		// 		<label style={{ width: "50px" }}>권한명</label>
		// 		<input style={{ width: "200px" }} type="text" onChange={(e)=>setName(e.target.value)} value={name}></input>
		// 	</div>
		// 	<table style={{ padding: "5px", marginBottom: "15px"}}>
		// 		<tr  style={{ height: "20px" }}>
		// 			<td  style={{ width: "50px" }}>메뉴</td>
		// 			<td  style={{ width: "50px" }}>조회</td>
		// 			<td  style={{ width: "50px" }}>생성</td>
		// 			<td  style={{ width: "50px" }}>수정</td>
		// 			<td  style={{ width: "50px" }}>삭제</td>
		// 		</tr>
		// 		{
		// 			roleArr.map((role, index) => {
		// 				return(
		// 					<tr style={{ height: "20px" }}>
		// 						<td>{role.name}</td>
		// 						<td>
		// 							<input type="checkbox" name="select" onChange={(e) => settings(e, role.path, index)} defaultChecked={data && role.select}></input>
		// 						</td>
		// 						<td>
		// 							<input type="checkbox" name="insert" onChange={(e) => settings(e, role.path, index)} defaultChecked={data && role.insert}></input>
		// 						</td>
		// 						<td>
		// 							<input type="checkbox" name="update" onChange={(e) => settings(e, role.path, index)} defaultChecked={data && role.update}></input>
		// 						</td>
		// 						<td>
		// 							<input type="checkbox" name="delete" onChange={(e) => settings(e, role.path, index)} defaultChecked={data && role.delete}></input>
		// 						</td>
		// 					</tr>
		// 				)

		// 			})
		// 		}
		// 	</table>
		// 	{/* <button type="button" onClick={!data? createRole : updateMenu}>등록</button> */}
		// 	<button type="button" onClick={!data ? create: update}>등록</button>
		// </form>
	)
}

export default RoleForm

const DataRowWrapHeaderTemp = styled.div`
	/* Auto layout */

	display: flex;
	/* flex-direction: row; */
	align-items: flex-start;

	border-top: 1px solid #e4e4e4;
	border-left: 1px solid #e4e4e4;
	border-right: 1px solid #e4e4e4;
	/* border-color: #e4e4e4;
	border-width: 1px 1px 0 1px; */
	border: 1px solid e4e4e4;
	width: 100%;
	/* display: grid; */
	height: 36px;
	line-height: 36px;
	text-align: center;
	background-color: #fafafa;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`
export const DataInputWrapTemp = styled.div`
	/* Auto layout */

	display: flex;
	/* flex-direction: row; */
	align-items: flex-start;

	border-top: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;

	width: 100%;
	/* display: grid; */
	height: 50px;
	line-height: 50px;
	text-align: center;
	background-color: #ffffff;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`