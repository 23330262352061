import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'

export const TelegramWrapper = styled.div`
	height: 10%;
	width: 80%;
	align-items: center;
	align-content: center;
	justify-content: space-evenly;
	padding-left: 20px;
	padding-right: 20px;
	display: flex;
	cursor: pointer;
	background-color: ${COLORS.white};
	border-radius: 15px;
	&:hover {
		opacity: 0.5;
	}
	@media only screen and (max-width: 600px) {
		width: 90%;
		margin-top: 20px;
	}
	@media only screen and (max-width: 968px) {
		width: 90%;
		margin-top: 20px;
		padding-top: 30px;
		padding-bottom: 30px;
	}
`

export const IconRightButton = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50px;

	padding-top: 2px;
	padding-left: 3px;
	background-color: ${COLORS.grayWhite};
`
export const IconButton = styled.div`
	width: 35px;
	height: 35px;
	border-radius: 50px;
	align-items: center;
	justify-content: center;
	align-self: center;
	align-content: center;
	padding-top: 5px;
	padding-left: 4px;
	background-color: ${COLORS.lightBlue};
`
