import React, { useState, useEffect } from 'react'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import {
	DataRowWrapHeader,
	ButtonWrap,
	CreateButton,
	DataInputWrap,
} from 'styles/globalPage-styles'
import { CancelButton } from '../../BoardManage/styles'

import CIUtils from 'utils/ciutils'

import * as antd from 'antd'
import { RowDataContent, RowDataName, TableContainer } from './styles'
import { View } from 'styles/reactnative'

type Props = {
	handleModal: () => void
	data?: any
	refreshList: () => void
	customDatas: any[]
	showModal?: boolean
}

function SettingModal({ showModal, data, handleModal, refreshList, customDatas }: Props) {
	const { data: token } = useToken()

	const [key, setKey] = useState(data ? data.key : '')
	const [value, setValue] = useState(data ? data.value : '')
	const [description, setDescription] = useState(data ? data.description : '')
	const [edit, setEdit] = useState(false)

	const initData = () => {
		if (data) {
			setKey(data.key)
			setValue(data.value)
			setDescription(data.description)
		}
	}

	const create = () => {
		let apiurl = '/system/userdata'

		if (!CIUtils.validateEnglish(key)) {
			antd.message.error('고유키는 영문, 숫자만 사용하여 20자 이내로 작성해주세요')
			return
		}

		if (!value) {
			antd.message.error('기본값을 작성해주세요')
			return
		}

		if (!description) {
			antd.message.error('키에 대한 설명을 작성해주세요')
			return
		}

		const find = customDatas.find(c => c.key === key)

		if (find) {
			antd.message.error('중복되는 키가 있습니다')
			return
		}

		API.post(apiurl, token + '', {
			key,
			value,
			description,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('등록완료')
					refreshList()
					handleModal()
				}
			})
			.catch((err: any) => {})
	}

	const validate = () => {
		let updateData: any = {}
		if (description !== data?.description) updateData = { ...updateData, description }
		if (value !== data?.value) updateData = { ...updateData, value }

		if (Object.keys(updateData).length < 1) return undefined
		return updateData
	}

	const update = () => {
		let apiurl = '/system/userdata'

		if (!value) {
			antd.message.error('기본값을 작성해주세요')
			return
		}

		if (!description) {
			antd.message.error('키에 대한 설명을 작성해주세요')
			return
		}

		let updateData = validate()

		if (!updateData) {
			antd.message.error('수정사항이 없습니다')
			return
		}

		API.put(apiurl, token + '', {
			key,
			updateData,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정되었습니다')
					refreshList()
					handleModal()
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	useEffect(() => {
		if (!edit) initData()
	}, [edit])

	return (
		<antd.Modal
			title={!edit ? '사용자설정' : '사용자설정 수정'}
			footer={null}
			open={showModal}
			onOk={handleModal}
			onCancel={handleModal}
			width={420}>
			<TableContainer>
				<DataRowWrapHeader>
					<RowDataName>구분</RowDataName>
					<RowDataContent>내용</RowDataContent>
				</DataRowWrapHeader>
				<DataInputWrap>
					<RowDataName>고유키</RowDataName>
					<RowDataContent>
						{!data ? (
							<antd.Input
								type="text"
								name="key"
								onChange={e => setKey(e.target.value)}
								value={key}></antd.Input>
						) : (
							key
						)}
					</RowDataContent>
				</DataInputWrap>
				<DataInputWrap>
					<RowDataName>기본값</RowDataName>
					<RowDataContent>
						{!data ? (
							<antd.Input
								type="text"
								name="value"
								onChange={e => setValue(e.target.value)}
								value={value}></antd.Input>
						) : !edit ? (
							value
						) : (
							<antd.Input
								type="text"
								name="value"
								onChange={e => setValue(e.target.value)}
								value={value}></antd.Input>
						)}
					</RowDataContent>
				</DataInputWrap>
				<DataInputWrap>
					<RowDataName>내용</RowDataName>
					<RowDataContent>
						{!data ? (
							<antd.Input
								type="text"
								name="description"
								onChange={e => setDescription(e.target.value)}
								value={description}></antd.Input>
						) : !edit ? (
							description
						) : (
							<antd.Input
								type="text"
								name="description"
								onChange={e => setDescription(e.target.value)}
								value={description}></antd.Input>
						)}
					</RowDataContent>
				</DataInputWrap>
			</TableContainer>
			<View style={{ marginTop: 10 }}>
				{!data ? (
					<ButtonWrap>
						<CreateButton onClick={create}>저장</CreateButton>
						<CancelButton onClick={handleModal}>취소</CancelButton>
					</ButtonWrap>
				) : !edit ? (
					<ButtonWrap>
						<CreateButton onClick={() => setEdit(true)}>수정</CreateButton>
					</ButtonWrap>
				) : (
					<ButtonWrap>
						<CreateButton onClick={update}>저장</CreateButton>
						<CancelButton onClick={handleModal}>취소</CancelButton>
					</ButtonWrap>
				)}
			</View>
		</antd.Modal>
	)
}

export default SettingModal
