import React, { useState, useEffect } from 'react'
import { useToken, useWindowDimensions } from 'app/hooks/'
import API from 'utils/api'
import {
	ControllerWrap,
	ButtonWrap,
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	CreateButton,
	UpdateButton,
	SDeleteButton,
} from 'styles/globalPage-styles'
import styled from 'styled-components'
import setting from '../../../../../../assets/icons/setting.png'

import downicon from '../../../../../../assets/icons/icon=down.png'
import upicon from '../../../../../../assets/icons/icon=up.png'
import downleft from '../../../../../../assets/icons/icon=down-left.png'
import { CancelButton } from '../../BoardManage/styles'
import CreateForm from './CreateForm'
import * as antd from 'antd'
import CIUtils from 'utils/ciutils'

const UserMenuManagementTable = () => {
	const { data: token } = useToken()
	const { width } = useWindowDimensions()

	const [createModal, setCreateModal] = useState(false)
	const [data, setData] = useState<any>()
	// let [update, setUpdate]  = useState(false)
	const [edit, setEdit] = useState(false)
	const [mainMenus, setMainMenus] = useState<any>([])
	const [subMenus, setSubMenus] = useState<any>([])
	const [originalMainMenus, setOriginalMainMenus] = useState<any>([])
	const [originalMenus, setOriginalMenus] = useState<any>([])
	const [deleteMenus, setDeleteMenus] = useState<any>([])

	const [name, setName] = useState(data ? data.name : '')
	const [path, setPath] = useState(data ? data.path : '')
	const [parent, setParent] = useState(data ? data.parent_menuid : 0)

	const [clickedMenu, setClickedMenu] = useState<any>()

	const deepCopy = (array: any) => {
		let temp: any = []
		array.map(item => temp.push({ ...item }))
		setOriginalMenus(temp)
	}

	const getMenus = () => {
		let apiurl = `/system/usermenus`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					let mainMenu = res.result.result.filter(menu => menu.level === 1)

					const orginalMainMenu = res.result.originalMenu.filter(menu => menu.level === 1)

					let tempSubMenu: any = []
					mainMenu.map((menu, index) => {
						const subMenu = res.result.result.filter(sub => menu.id === sub.parent_menuid)

						subMenu.map(item => {
							tempSubMenu.push({ ...item })
						})
					})

					setMainMenus(mainMenu)
					setSubMenus(tempSubMenu)
					deepCopy(res.result.result)
					// setOriginalMenus(res.result.result)
					setOriginalMainMenus(orginalMainMenu)
				}
			})
			.catch((err: any) => {})
	}

	const validateUpdateMenu = () => {
		const menu = clickedMenu
		const parent_menuid = parent

		if (menu.name !== name && name.length > 20) {
			antd.message.info('메뉴명은 20자이하로 작성해주세요')
			return false
		}

		if (menu.path !== path && !CIUtils.validateEnglish(path)) {
			antd.message.info('경로는 20자 이하의 영문, 숫자로 작성해주세요')
			return false
		}

		if (menu.parent_menuid === parent_menuid) {
			let tempMainMenus: any = []

			menu.level === 1 ? (tempMainMenus = [...mainMenus]) : (tempMainMenus = [...subMenus])

			const index = tempMainMenus.indexOf(menu)
			const find = tempMainMenus.find(m => m.id !== menu.id && (m.name === name || m.path === path))

			if (find) {
				antd.message.info('경로 또는 이름이 중복되는 메뉴가 있습니다')
				return false
			}

			const { order, level } = menu
			return { order, level }
		} else {
			if (menu.parent_menuid && !parent_menuid) {
				const find = mainMenus.find(m => m.name === name || m.path === path)

				if (find) {
					antd.message.info('경로 또는 이름이 중복되는 메뉴가 있습니다')
					return false
				}

				const order = mainMenus.length + 1
				const level = 1
				return { order, level }
			} else if (!menu.parent_menuid && parent_menuid) {
				const find = subMenus.find(
					m => m.parent_menuid === parent_menuid && (m.name === name || m.path === path)
				)

				if (find) {
					antd.message.info('경로 또는 이름이 중복되는 메뉴가 있습니다')
					return false
				}
				//ccc
				const bro = subMenus.filter(m => m.parent_menuid === parent_menuid)

				const order = bro ? bro.length + 1 : 1
				const level = 2
				return { order, level }
			} else {
				const find = subMenus.find(
					m => m.parent_menuid === parent_menuid && (m.name === name || m.path === path)
				)

				if (find) {
					antd.message.info('경로 또는 이름이 중복되는 메뉴가 있습니다')
					return false
				}

				const bro = subMenus.filter(m => m.parent_menuid === parent_menuid)

				const order = bro ? bro.length + 1 : 1
				const level = 2
				return { order, level }
			}
		}
	}

	const update = () => {
		let apiurl = `/system/usermenus`

		const updateMenus = validate()

		if (updateMenus.length < 1 && deleteMenus.length < 1) {
			antd.message.info('수정사항이 없습니다')
			return
		}

		API.put(apiurl, token + '', {
			updateMenus,
			deleteMenus,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정되었습니다')
					setEdit(false)
					getMenus()
				}
			})
			.catch((err: any) => {})
	}

	useEffect(() => {
		getMenus()
	}, [])

	const move = (direction: 'top' | 'up' | 'down' | 'bottom') => {
		if (!clickedMenu) return

		let menu = clickedMenu

		if (direction === 'up' && menu.order === 1) {
			return
		}

		if (menu.level === 1) {
			if (direction === 'down' && menu.order === mainMenus.length) {
				return
			}

			let tempMenus = [...mainMenus]
			const index = tempMenus.indexOf(menu)

			if (direction === 'up') {
				tempMenus[index].order = tempMenus[index].order - 1
				tempMenus[index - 1].order = tempMenus[index].order + 1
				let tempMenu = tempMenus[index]
				tempMenus[index] = tempMenus[index - 1]
				tempMenus[index - 1] = tempMenu
			} else {
				tempMenus[index].order = tempMenus[index].order + 1
				tempMenus[index + 1].order = tempMenus[index].order - 1
				let tempMenu = tempMenus[index]
				tempMenus[index] = tempMenus[index + 1]
				tempMenus[index + 1] = tempMenu
			}
			setMainMenus(tempMenus)
		}

		if (menu.level === 2) {
			const filter = subMenus.filter(s => s.parent_menuid === menu.parent_menuid)
			if (direction === 'down' && menu.order === filter.length) {
				return
			}

			let tempMenus = [...subMenus]
			const index = tempMenus.indexOf(menu)

			if (direction === 'up') {
				tempMenus[index].order = tempMenus[index].order - 1
				tempMenus[index - 1].order = tempMenus[index].order + 1
				let tempMenu = tempMenus[index]
				tempMenus[index] = tempMenus[index - 1]
				tempMenus[index - 1] = tempMenu
			}

			if (direction === 'down') {
				tempMenus[index].order = tempMenus[index].order + 1
				tempMenus[index + 1].order = tempMenus[index].order - 1
				let tempMenu = tempMenus[index]
				tempMenus[index] = tempMenus[index + 1]
				tempMenus[index + 1] = tempMenu
			}
			setSubMenus(tempMenus)
		}
	}

	const deleteMenu = () => {
		const menu = clickedMenu

		if (!clickedMenu) return

		if (menu.id == 45) {
			antd.message.error('게시판 관리에 대한 삭제는 불가합니다')
			return
		}

		setDeleteMenus(prev => [...prev, { id: menu.id }])
		sliceMenu(menu)

		setName('')
		setPath('')
		setParent(0)
		setClickedMenu(null)
	}

	const sliceMenu = (menu: any, menuArr?: any) => {
		if (menu.level === 1) {
			let temp = [...mainMenus]
			const index = temp.indexOf(menu)
			let front = temp.slice(0, index)
			let back = temp.slice(index + 1, temp.length)

			if (back.length > 1) {
				back.map(m => [(m.order = m.order - 1)])
			}

			temp = [...front, ...back]
			setMainMenus(temp)
		}

		if (menu.level === 2) {
			let temp: any = []
			if (menuArr) temp = [...menuArr]
			else temp = [...subMenus]
			// let temp = [...subMenus]
			const index = temp.indexOf(menu)
			let front = temp.slice(0, index)
			let back = temp.slice(index + 1, temp.length)

			let order = 1
			// let lastOrder = menu.lastOrder - 1
			temp = [...front, ...back]

			temp.map(m => {
				if (m.parent_menuid === menu.parent_menuid) {
					m.order = order
					// m.lastOrder = lastOrder
					order++
				}
			})
			setSubMenus(temp)
		}
	}

	const validate = () => {
		let updateMenus: any = []
		// let newMenus: any = []

		let temp = [...mainMenus, ...subMenus]

		temp.map(menu => {
			const find = originalMenus.find(originMenu => originMenu.id === menu.id)

			if (!find) {
				// newMenus.push(menu)
			} else {
				const menu1 = JSON.stringify(menu)
				const menu2 = JSON.stringify(find)

				if (menu1 === menu2) {
					return
				}

				let updateMenu: any = {}
				updateMenu = { id: menu.id, name: menu.name }

				if (find.name !== menu.name) {
					updateMenu = { ...updateMenu, name: menu.name }
				}

				if (find.path !== menu.path) {
					updateMenu = { ...updateMenu, path: menu.path }
				}

				if (find.order !== menu.order) {
					updateMenu = { ...updateMenu, order: menu.order }
				}

				if (find.level !== menu.level) {
					updateMenu = { ...updateMenu, level: menu.level }
				}

				if (find.parent_menuid !== menu.parent_menuid) {
					updateMenu = { ...updateMenu, parent_menuid: menu.parent_menuid }
				}
				updateMenus.push(updateMenu)
			}
		})
		// mainMenus.map((menu, index) => {
		// 	const find = originalMainMenus.find((originMenu) => originMenu.id === menu.id)

		// 	if (!find) {
		// 		// newMenus.push(menu)
		// 	} else {
		// 		const menu1 = JSON.stringify(menu)
		// 		const menu2 = JSON.stringify(find)

		// 		if (menu1 === menu2) {
		// 			return
		// 		}

		// 		let updateMenu: any = {}
		// 		updateMenu = {id : menu.id, name: menu.name}

		// 		if (find.name !== menu.name) {
		// 			updateMenu = {...updateMenu, name: menu.name}
		// 		}

		// 		if (find.path !== menu.path) {
		// 			updateMenu = {...updateMenu, path: menu.path}
		// 		}

		// 		if (find.order !== menu.order) {
		// 			updateMenu = {...updateMenu, order: menu.order}
		// 		}

		// 		if (find.level !== menu.level) {
		// 			updateMenu = {...updateMenu, level: menu.level}
		// 		}

		// 		if (find.parnet_menuid !== menu.parnet_menuid) {
		// 			updateMenu = {...updateMenu, parnet_menuid: menu.parnet_menuid}
		// 		}
		// 		updateMenus.push(updateMenu)
		// 	}
		// })

		// subMenus.map((menu, index) => {
		// 	const find = originalSubnMenus.find((originMenu) => originMenu.id === menu.id)

		// 	if (!find) {
		// 		// newMenus.push(menu)
		// 	} else {
		// 		const menu1 = JSON.stringify({id: menu.id, name: menu.name, path: menu.path, order: menu.order, level: menu.level, parent_menuid: menu.parent_menuid})
		// 		const menu2 = JSON.stringify(find)

		// 		if (menu1 === menu2) {
		// 			return
		// 		}

		// 		let updateMenu: any = {}
		// 		updateMenu = {id : menu.id, name: menu.name}

		// 		if (find.name !== menu.name) {
		// 			updateMenu = {...updateMenu, name: menu.name}
		// 		}

		// 		if (find.path !== menu.path) {
		// 			updateMenu = {...updateMenu, path: menu.path}
		// 		}

		// 		if (find.order !== menu.order) {
		// 			updateMenu = {...updateMenu, order: menu.order}
		// 		}

		// 		if (find.level !== menu.level) {
		// 			updateMenu = {...updateMenu, level: menu.level}
		// 		}

		// 		if (find.parnet_menuid !== menu.parnet_menuid) {
		// 			updateMenu = {...updateMenu, parnet_menuid: menu.parnet_menuid}
		// 		}
		// 		updateMenus.push(updateMenu)
		// 	}
		// })

		return updateMenus
	}

	const updateMenuData = () => {
		if (
			clickedMenu?.name !== name ||
			clickedMenu?.path !== path ||
			clickedMenu?.parent_menuid !== parent
		)
			setEdit(true)
		else setEdit(false)
	}

	const tempUpdate = () => {
		const result = validateUpdateMenu()

		if (!result) {
			return
		}

		if (!name || name.length > 20) {
			antd.message.error('메뉴명은 20자 이하로 작성해주세요')
			return
		}

		if (!CIUtils.validateEnglish(path)) {
			antd.message.error('경로는 20자 이하의 영문, 숫자로 작성해주세요')
			return
		}


		const { order, level } = result

		let temp: any = []
		level === 1 ? (temp = [...mainMenus]) : (temp = [...subMenus])

		let newMenu: any = {}

		if (clickedMenu?.parent_menuid === parent) {
			const index = temp.indexOf(clickedMenu)
			temp[index].name = name
			temp[index].path = path
			level === 1 ? setMainMenus(temp) : setSubMenus(temp)
		} else if (clickedMenu?.level === level) {
			newMenu = {
				id: clickedMenu?.id,
				order: order,
				level: level,
				name: name,
				path: path,
				parent_menuid: parent ? parent : null,
			}
			temp.push(newMenu)
			sliceMenu(clickedMenu, temp)
			// setSubMenus(temp)
			// sliceMenu(clickedMenu, temp)
		} else {
			if (level === 1) {
				newMenu = {
					id: clickedMenu?.id,
					level: level,
					order: order,
					name: name,
					path: path,
					parent_menuid: parent ? parent : null,
				}
				temp = [...mainMenus, newMenu]
				setMainMenus(temp)
			} else {
				newMenu = {
					id: clickedMenu?.id,
					order: order,
					level: level,
					name: name,
					path: path,
					parent_menuid: parent ? parent : null,
				}
				temp = [...subMenus, newMenu]
				setSubMenus(temp)
				// sliceMenu(clickedMenu, temp)
			}
			sliceMenu(clickedMenu)
		}
		// if (clickedMenu?.level !== level) {
		// 	newMenu = { id: clickedMenu?.id, lastOrder: order, level: level, name: name, path: path, parent_menuid: parent ? parent : null }
		// 	temp.push(newMenu)
		// 	sliceMenu(clickedMenu, temp)
		// }
		setEdit(false)
		setClickedMenu(newMenu)
	}

	const settingParentMenu = (parentid: string) => {
		setParent(Number(parentid))
	}

	useEffect(() => {
		updateMenuData()
	}, [name, path, parent])

	useEffect(() => {
		setName(clickedMenu?.name)
		setPath(clickedMenu?.path)
		setParent(clickedMenu?.parent_menuid)
	}, [clickedMenu])

	return (
		<div style={{ maxWidth: '100%', overflowX: 'auto', background: '#fff' }}>
			<TitleWrap>
				<TitleWrapLeft>
					<img src={setting} alt="icon"></img>
					<span style={{ fontSize: '20px', fontWeight: 'bold' }}>메뉴 설정</span>
				</TitleWrapLeft>
				<TitleWrapRight>
					<span>홈 &gt; 시스템 &gt; 사용자메뉴</span>
				</TitleWrapRight>
			</TitleWrap>
			<ControllerWrap>
				<ButtonWrap>
					<UpdateButton onClick={() => setCreateModal(true)}>+ 추가</UpdateButton>
					<SDeleteButton onClick={deleteMenu}>삭제</SDeleteButton>
				</ButtonWrap>
			</ControllerWrap>
			<Content
				style={{
					maxWidth: '100%',
					flexDirection: width < 1000 ? 'column' : 'row',
				}}>
				<SectionLeft>
					<MenuList>
						<Header>카테고리</Header>
						<List>
							{mainMenus?.map((menu, index) => {
								if (clickedMenu?.id === menu?.id) {
									return (
										<>
											<ClickedMenuItem onClick={() => setClickedMenu(menu)}>
												{menu.name}
											</ClickedMenuItem>
											{subMenus.map((submenu, index) => {
												if (submenu.parent_menuid === menu.id) {
													if (clickedMenu?.id === submenu?.id) {
														return (
															<ClickedSubMenuItem onClick={() => setClickedMenu(submenu)}>
																<img src={downleft} />
																{submenu.name}
															</ClickedSubMenuItem>
														)
													} else {
														return (
															<SubMenuItem onClick={() => setClickedMenu(submenu)}>
																<img src={downleft} />
																{submenu.name}
															</SubMenuItem>
														)
													}
												}
											})}
										</>
									)
								} else {
									return (
										<>
											<MenuItem onClick={() => setClickedMenu(menu)}>{menu.name}</MenuItem>
											{subMenus.map((submenu, index) => {
												if (submenu.parent_menuid === menu.id) {
													if (clickedMenu?.id === submenu?.id) {
														return (
															<ClickedSubMenuItem onClick={() => setClickedMenu(submenu)}>
																<img src={downleft} />
																{submenu.name}
															</ClickedSubMenuItem>
														)
													} else {
														return (
															<SubMenuItem onClick={() => setClickedMenu(submenu)}>
																<img src={downleft} />
																{submenu.name}
															</SubMenuItem>
														)
													}
												}
											})}
										</>
									)
								}
							})}
						</List>
						<ArrowButtonWrap>
							{/* <img src={topicon} onClick={()=>move('top')}/> */}
							<img src={upicon} onClick={() => move('up')} />
							<img src={downicon} onClick={() => move('down')} />
							{/* <img src={bottomicon} onClick={()=>move('bottom')}/> */}
						</ArrowButtonWrap>
					</MenuList>
				</SectionLeft>
				<SectionRight>
					{clickedMenu && (
						<>
							<MenuForm style={{ width: '100%' }}>
								<FormItemTitle>상위메뉴</FormItemTitle>
								<Select onChange={e => settingParentMenu(e.target.value)}>
									<option value={0}>없음</option>
									{mainMenus.map(menu => {
										if (menu.id != 45)
											return (
												<option
													value={menu.id}
													selected={parent ? (parent === menu.id ? true : false) : false}>
													{menu.name}
												</option>
											)
									})}
								</Select>
								<FormItemTitle>카테고리명</FormItemTitle>
								<Input
									type="text"
									name="name"
									onChange={e => setName(e.target.value)}
									value={name}></Input>
								<FormItemTitle>경로</FormItemTitle>
								<Input
									type="text"
									name="path"
									onChange={e => setPath(e.target.value)}
									value={path}></Input>
							</MenuForm>
							{edit && (
								<ButtonWrap>
									<CancelButton onClick={tempUpdate}>적용</CancelButton>
								</ButtonWrap>
							)}
						</>
					)}
				</SectionRight>
			</Content>

			<ButtonWrap>
				<CreateButton onClick={update}>저장</CreateButton>
			</ButtonWrap>
			{createModal && (
				<CreateForm
					showModal={createModal}
					handleModal={() => setCreateModal(false)}
					menus={originalMainMenus}
					refreshList={getMenus}
				/>
			)}
		</div>
	)
}

export default UserMenuManagementTable

const Select = styled.select`
	width: 360px;
	border: 1px solid #cccccc;
	border-radius: 2px;
	padding: 5px 10px;
`

const Input = styled.input`
	width: 360px;
	border: 1px solid #cccccc;
	border-radius: 2px;
	padding: 5px 10px;
`
const MenuInfoWrap = styled.div``

const Content = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding-bottom: 8px;
	gap: 36px;

	width: 974px;
	/* height: 538px; */
`

const BottomLine = styled.div`
	margin-top: 8px;
	width: 974px;
	height: 8px;
	border-top: 1px solid #425788;
`

const SectionLeft = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 12px;

	/* width: 50%; */
	height: 538px;

	/* stroke */

	border: 1px solid #cccccc;
`

const MenuList = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0px;
	/* gap: 12px; */

	overflow-y: hidden;
	overflow-x: hidden;
	width: 360px;
	height: 538px;

	/* stroke */

	/* border: 1px solid #CCCCCC; */
`

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 0px;

	width: 360px;
	height: 46px;

	color: #ffffff;

	font-size: 15px;
	/* font-weight: bold; */

	/* bg/primary */

	background: #2e3031;
	border: 1px solid #2e3031;

	margin-bottom: 12px;
`

const List = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0px;

	overflow-y: auto;
	overflow-x: hidden;

	width: 360px;
	height: 480px;
`

const MenuItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px 30px;

	width: 360px;
	height: 36px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;

	/* text/primary */

	color: #333333;
`

const ClickedMenuItem = styled.div`
	background-color: #d5ecf6;

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px 30px;

	width: 360px;
	height: 36px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;

	/* text/primary */
`

const SubMenuItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px 0px 8px 40px;

	width: 360px;
	height: 36px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;

	/* text/primary */

	color: #333333;
`

const ClickedSubMenuItem = styled.div`
	background-color: #d5ecf6;

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 8px 0px 8px 40px;

	width: 360px;
	height: 36px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;

	/* text/primary */
`

const ArrowButtonWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 12px 16px;
	gap: 6px;

	width: 360px;
	height: 48px;
`

const SectionRight = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	/* padding: 12px 0px; */

	/* width: 578px; */
`

const MenuForm = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0px;

	gap: 20px;

	width: 578px;
`

const FormItemTitle = styled.div`
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;

	/* text/primary */

	color: #333333;

	width: 360px;
`

// type PProps = {
// 	handleModal: () => void
// 	mainMenus: any
// 	// createMenu?: (parent_menuid: number, name: string, path: string) => void
// 	createMenu?: (parent_menuid: number, name: string, path: string) => any;
// 	parentMenu?: any
// 	menuData?: any
// 	updateMenu?: (menu: any, name: string, path: string, parent_menuid: number) =>any;
// 	refreshList: () => void;
// }
// function MenuForm({ handleModal, mainMenus, createMenu, menuData, parentMenu, updateMenu, refreshList }: PProps) {
// 	const { data: token } = useToken()

// 	const [name, setName] = useState(menuData? menuData.name : '')
// 	const [path, setPath] = useState(menuData? menuData.path : '')
// 	const [parent, setParent] = useState(menuData? menuData.parent_menuid : 0)

// 	const create = () => {
// 		if (createMenu) {
// 			const order = createMenu(parent, name, path)

// 			if (!order) return
// 			createAPI(order)
// 		}
// 		handleModal()
// 	}

// 	const createAPI = (order: number) => {
// 		let apiurl = '/system/menus'

// 		if (parent === 45) {
// 			toast('게시판 생성은 시스템>게시판설정')
// 			return
// 		}

// 		API.post(apiurl, token + '', {
// 			 name, path, order, level: !parent ? 1 : 2, mainMenuid: !parent ? null : parent
// 		})
// 			.then((res: any) => {
// 				if (res.result.success) {
// 					toast('등록완료')
// 					refreshList()
// 				}
// 			})
// 			.catch((err: any) => {

// 			})
// 	}

// 	function settings (e: React.ChangeEvent<HTMLInputElement>) {
// 		const name = e.target.name;
// 		const value = e.target.value;

// 		switch (name){
// 			case 'menu': setName(value); break;
// 			case 'path': setPath(value); break;
// 			case 'parent':
// 				if (isNaN(Number(value))) {
// 					break;
// 				} else {
// 					setParent(Number(value));
// 					break;;
// 				}
// 		}
// 	}

// 	return(
// 		<form>
// 			{
// 				parentMenu &&
// 				<div>
// 					<label>{parentMenu.name}</label>
// 				</div>
// 			}
// 			{
// 				<div>
// 				<label>상위메뉴</label>
// 				<select onChange={(e)=>setParent(Number(e.target.value))}>
// 					<option value={0}>없음</option>
// 					{
// 						mainMenus.map((menu) => {
// 							return(
// 								<option value={menu.id} selected={parent? parent === menu.id ? true : false : false}>{menu.name}</option>
// 							)
// 						})
// 					}
// 				</select>
// 				</div>
// 			}
// 			<div>
// 				<label>메뉴명</label>
// 				<input type="text" name="menu" onChange={settings} value={name}></input>
// 			</div>
// 			<div>
// 				<label>경로</label>
// 				<input type="text" name="path" onChange={settings} value={path}></input>
// 			</div>
// 			<button type="button" onClick={createMenu ? create : updateMenu ? update : ()=>{}}>등록</button>
// 		</form>
// 	)
// }
