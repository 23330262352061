import styled from 'styled-components'

export const TableContainer = styled.div``
export const TableListSection = styled.div`
	width: 100%;
	background-color: transparent;
`

export const Title = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	width: 490px;
	height: 36px;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
`
export const Name = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	width: 150px;
	height: 36px;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;
`
export const Date = styled.div`
	box-sizing: border-box;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 167px;
	height: 36px;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;
`
