import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { FIREBASE_CONFIG, VAPID_KEY } from 'app/constants/APIKEYS';

const firebaseapp = initializeApp(FIREBASE_CONFIG);
const messaging = getMessaging(firebaseapp);

export function getFMSToken () {
    getToken(messaging, {
        vapidKey:
            VAPID_KEY,
        })
        .then((currentToken) => {
			return currentToken
        })
        .catch((err) => {
			console.log("An error occurred while retrieving token. ", err);
			return false
        });
}

export function requestPermission() {
	Notification.requestPermission().then(permission => {
		if (permission === 'granted') {
			// return getFMSToken
		}
	})
}
