import React, { useState } from 'react'
import styled from 'styled-components'
import { Close } from 'styles/globalPage-styles'
import titleIcon from '../../../../../assets/icons/memberInfoIcon.png'
import close from '../../../../../assets/icons/close.png'
import * as antd from 'antd'
import { useHistory } from 'react-router'

type Props = {
	postId?: number
	postPassword: string
	handleModal: () => void
	goToPost?: () => void
}

function LockModal({ postId, postPassword, handleModal, goToPost }: Props) {
	const navigate = useHistory()
	const [password, setPassword] = useState('')
	const [passwordMatch, setPasswordMatch] = useState(true)

	const goToPosT = (postid: number) => {
		const boardPath = window.location.pathname?.split('/')?.[2]?.split('?')[0]
		const boardname = 'ideas'
		const name = encodeURI(encodeURIComponent(boardname))
		// navigate.push(`/board/${boardPath}/${postid}?name=${name}`);
		navigate.push(`/ideas/${postid}?name=${name}`)
		goToPost && goToPost()
	}

	const confirm = () => {
		if (password === postPassword && postId) {
			goToPosT(postId)
			// handleModal()
		} else {
			setPasswordMatch(false)
			return
		}
	}

	const closeModal = () => {
		// navigate.push('/ideas')
		handleModal()
	}

	return (
		<ModalWrap>
			<Modal>
				<Title>비밀번호</Title>
				<RowDataContent>
					<antd.Input
						style={{ height: '50px', borderColor: !passwordMatch ? '#CE5858' : '' }}
						type="password"
						name="password"
						placeholder="비밀번호를 입력해주세요"
						onChange={e => setPassword(e.target.value)}></antd.Input>
					<Notice>{passwordMatch ? '' : '비밀번호가 일치하지 않습니다'}</Notice>
				</RowDataContent>
				<ButtonWrap>
					<CreateButton onClick={confirm}>확인</CreateButton>
					<CancelButton onClick={closeModal}>취소</CancelButton>
				</ButtonWrap>
			</Modal>
		</ModalWrap>
	)
}

export default LockModal

const ModalWrap = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 999;
`
const Modal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 0px 60px 0;
	gap: 15px;

	position: absolute;
	width: 700px;
	left: 50%;
	margin-left: -350px;
	top: 300px;

	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(88, 88, 88, 0.25);
	@media only screen and (max-width: 1000px) {
		width: 93%;
		justify-content: center;
		left: 12px;
		margin-left: 0px;
	}
`

const Title = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	/* width: 85px; */
	width: 100%;
	height: 70px;

	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	/* identical to box height, or 292% */

	text-align: center;
	letter-spacing: -0.015em;

	color: #000000;
`

const Notice = styled.span`
	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	/* identical to box height */

	text-align: left;
	width: 100%;

	color: #ce5858;
`
const ButtonWrap = styled.div`
	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 12px;

	width: 100%;
`

const CreateButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	width: 89px;
	height: 41px;

	background: #333333;
	border-radius: 1px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;

	color: #ffffff;

	&:hover {
		cursor: pointer;
	}
`
const CancelButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	width: 89px;
	height: 41px;

	background: #ffffff;
	border: 0.5px solid #333333;
	border-radius: 1px;

	font-family: 'LINE Seed Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	display: flex;
	align-items: center;

	color: #333333;

	&:hover {
		cursor: pointer;
	}
`
const TableContainer = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	width: 380px;
	/* height: 324px; */

	/* table/border */

	/* border: 1px solid #E4E4E4; */
`

const RowData = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	width: 380px;
	height: 36px;

	background: #ffffff;
`

const RowDataNameHeader = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 36px;
	line-height: 36px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`

const RowDataContentHeader = styled.div`
	box-sizing: border-box;

	width: 280px;
	height: 36px;
	line-height: 36px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`

const RowDataName = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 50px;
	line-height: 50px;

	background: #ffffff;
	/* table/border */

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`

const RowDataContent = styled.div`
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-items: center;
	justify-content: space-between;
	width: 470px;
	height: 76px;

	background: #ffffff;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;

	color: #666666;
	@media only screen and (max-width: 1000px) {
		width: 90%;
	}
`

const Input = styled.input`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 10px;

	width: 100%;
	height: 26px;

	/* stroke */

	border: 1px solid #cccccc;
	border-radius: 2px;

	&:focus {
		border: 1px solid #333333;
	}
`

const SizeInput = styled.input`
	width: 86px;
	height: 26px;

	/* stroke */

	border: 1px solid #cccccc;
	border-radius: 2px;
`
