import React from 'react'
import { useHistory } from 'react-router-dom'
import { CView } from 'app/components/Common'
import { COLORS } from 'app/constants'
import { Text } from 'styles/reactnative'
import { useToken } from 'app/hooks'

const HeaderLogout = () => {
	const navigation = useHistory()
	const { data: token, mutate } = useToken()
	return (
		<CView
			style={{
				float: 'right',
				height: 31,
				paddingInline: 10,
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: 5,
				cursor: 'pointer',
				userSelect: 'none',
			}}
			onClick={() => {
				token ? mutate(null) : navigation.push('/admin/login')
			}}>
			<Text
				size={14}
				style={{
					color: COLORS.black,
					fontWeight: '500',
					lineHeight: 1,
				}}>
				{token ? '로그아웃' : '로그인'}
			</Text>
		</CView>
	)
}

export default HeaderLogout
