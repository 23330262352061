import React, { useState, useEffect } from 'react'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import {
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	ButtonWrap,
	CreateButton,
	SDeleteButton,
	TableWrap,
	DataRowWrap,
	CheckboxWrap,
	NumberWrap,
	DeleteButtonWrap,
	DeleteButton,
	ControllerWrap,
	DataRowWrapHeader,
	Nocontent,
} from 'styles/globalPage-styles'
import setting from '../../../../../../assets/icons/setting.png'
import styled from 'styled-components'
import RoleForm from './RoleForm'
import * as antd from 'antd'

const RoleManagementTable = () => {
	const { data: token } = useToken()

	const [create, setCreate] = useState(false)
	const [update, setUpdate] = useState(false)
	const [roles, setRoles] = useState<any[]>([])
	const [groupid, setGroupid] = useState(0)
	const [data, setData] = useState()
	const [pageSize, setPageSize] = useState<number[]>([])
	const [page, setPage] = useState(1)
	const [checkedList, setCheckedList] = useState<any>([])

	const deleteCheckedList = async () => {
		if (checkedList?.length < 1) return

		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}

		const result: any = await deleteList()
		const { count, error } = result

		if (count === checkedList.length) {
			antd.message.success('삭제되었습니다')
			setCheckedList([])
			getRoles()
		} else if (count === 0) {
			antd.message.error('삭제가 불가합니다' + error)
		} else {
			antd.message.error('일부 권한은 삭제가 불가합니다' + error)
			setCheckedList([])
			getRoles()
		}
	}

	const deleteList = async () => {
		let count = 0
		let error: any

		for (let i = 0; i < checkedList.length; i++) {
			let apiurl = `/system/role/${checkedList[i].id}`

			await API.delete(apiurl, token + '', {})
				.then((res: any) => {
					if (res.result.success) {
						count++
					}
				})
				.catch((err: any) => {
					error = err.message
				})
		}

		return { count, error }
	}

	const checked = (checked: boolean, id: number) => {
		let temp = [...checkedList]
		if (checked) {
			temp.push({ id: id })
		} else {
			temp = temp.filter(f => f.id !== id)
		}
		setCheckedList(temp)
	}

	const refreshList = () => {
		getRoles()
	}

	const deleteRole = (id: number) => {
		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}
		
		
		let apiurl = `/system/role/${id}`

		API.delete(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					refreshList()
				}
			})
			.catch((err: any) => {
				antd.message.error('삭제가 불가합니다' + err)
			})
	}

	const getRoles = () => {
		let apiurl = `/system/role`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setRoles(res.result.result)
					setPageSize(Array.from({ length: res.result.result.pageLength }, (v, i) => i + 1))
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const getRole = () => {
		let apiurl = `/system/role/${groupid}`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setData(res.result.result)
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const handleModal = () => {
		create && setCreate(false)
		update && setUpdate(false)
		groupid && setGroupid(0)
	}

	const UpdateRoleCMP = (
		<RoleForm
			showModal={update}
			handleModal={handleModal}
			data={data}
			roles={roles}
			refreshList={refreshList}
		/>
	)
	const CreateRoleCMP = (
		<RoleForm
			showModal={create}
			handleModal={handleModal}
			roles={roles}
			refreshList={refreshList}
		/>
	)

	useEffect(() => {
		if (groupid) getRole()
	}, [groupid])

	useEffect(() => {
		if (data) setUpdate(true)
	}, [data])

	useEffect(() => {
		getRoles()
	}, [page])

	return (
		<div style={{ width: 800, maxWidth: '100%', overflowX: 'auto', background: '#fff' }}>
			<TitleWrap>
				<TitleWrapLeft>
					<img src={setting} alt="icon"></img>
					<span style={{ fontSize: '20px', fontWeight: 'bold' }}>권한설정</span>
				</TitleWrapLeft>
				<TitleWrapRight>
					<span>홈 &gt; 시스템 &gt; 권한설정</span>
				</TitleWrapRight>
			</TitleWrap>
			<ControllerWrap>
				<ButtonWrap>
					<CreateButton onClick={() => setCreate(true)}>등록</CreateButton>
					{/* <UpdateButton>선택수정</UpdateButton> */}
					<SDeleteButton onClick={deleteCheckedList}>선택삭제</SDeleteButton>
				</ButtonWrap>
			</ControllerWrap>
			<TableWrap>
				<DataRowWrapHeader>
					<CheckboxWrap>
						<input type="checkbox"></input>
					</CheckboxWrap>
					<NumberWrap>NO.</NumberWrap>
					<Name>권한</Name>
					<DeleteButtonWrap>관리</DeleteButtonWrap>
				</DataRowWrapHeader>
				{roles?.map((role, index) => {
					return (
						<DataRowWrap key={index.toString()} onDoubleClick={() => setGroupid(role?.id)}>
							<CheckboxWrap>
								<input type="checkbox" onChange={e => checked(e.target.checked, role.id)}></input>
							</CheckboxWrap>
							<NumberWrap>{role?.id}</NumberWrap>
							<Name>{role?.name}</Name>
							<DeleteButtonWrap>
								<DeleteButton onClick={() => deleteRole(role?.id)}>삭제</DeleteButton>
							</DeleteButtonWrap>
						</DataRowWrap>
					)
				})}
				{roles?.length < 1 && <Nocontent>등록된 권한이 없습니다</Nocontent>}
			</TableWrap>
			{create ? CreateRoleCMP : update ? UpdateRoleCMP : null}
		</div>
	)
}

export default RoleManagementTable

const Name = styled.div`
	box-sizing: border-box;

	width: 85%;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
