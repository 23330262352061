import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import CreateForm from './CreateForm'
import { Name, Type, Date, Count } from './styles'
import {
	Nocontent,
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	ButtonWrap,
	CreateButton,
	UpdateButton,
	SDeleteButton,
	TableWrap,
	DataRowWrap,
	CheckboxWrap,
	NumberWrap,
	DeleteButtonWrap,
	DeleteButton,
	ContentWrap,
	ControllerWrap,
	DataRowWrapHeader,
} from 'styles/globalPage-styles'
import setting from '../../../../../assets/icons/setting.png'
import * as antd from 'antd'
const BoardManagementTable = () => {
	const { data: token } = useToken()
	const [create, setCreate] = useState(false)
	const [update, setUpdate] = useState(false)
	const [boards, setBoards] = useState<any>([])
	const [data, setData] = useState<any>()
	const [checkedList, setCheckedList] = useState<any>([])
	const [change, setChange] = useState(false)

	const refreshList = () => {
		getBoards()
	}

	const handleModal = () => {
		create && setCreate(false)
		if (update) {
			setUpdate(false)
			setData(null)
		}
	}

	const deleteCheckedList = () => {
		if (checkedList?.length < 1) return
		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}

		checkedList?.map(c => {
			let apiurl = `/system/boards/${c.id}`

			API.delete(apiurl, token + '', {})
				.then((res: any) => {
					if (res.result.success) {
					}
				})
				.catch((err: any) => {
					// toast('삭제에 실패하였습니다\n' + err)
				})
		})

		setChange(true)
		antd.message.success('삭제되었습니다')
	}

	useEffect(()=>{
		if(change) {
			refreshList()
			setCheckedList([])
			setChange(false)
		}
	},[change])


	const checked = (checked: boolean, id: number) => {
		let temp = [...checkedList]
		if (checked) {
			temp.push({ id: id })
		} else {
			temp = temp.filter(f => f.id !== id)
		}
		setCheckedList(temp)
	}

	const deleteBoard = (id: number) => {
		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}
		
		let apiurl = `/system/boards/${id}`

		API.delete(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					refreshList()
				}
			})
			.catch((err: any) => {})
	}

	const getBoards = () => {
		let apiurl = `/system/boards`

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setBoards(res.result.result)
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const CreateBoardCMP = (
		<CreateForm
			showModal={create}
			handleModal={handleModal}
			refreshList={refreshList}
			boards={boards}
		/>
	)

	const UpdateBoardCMP = (
		<CreateForm
			showModal={update}
			handleModal={handleModal}
			boardData={data}
			refreshList={refreshList}
			boards={boards}
		/>
	)

	useEffect(() => {
		getBoards()
	}, [])

	useEffect(() => {
		if (data) setUpdate(true)
	}, [data])

	const nav = [
		{ name: '홈', path: 'admin' },
		{ name: '게시판관리', path: 'admin' },
		{ name: '홈', path: 'admin' },
	]

	return (
		<ContentWrap>
			<TitleWrap>
				<TitleWrapLeft>
					<img src={setting} alt="icon"></img>
					<span style={{ fontSize: '20px', fontWeight: 'bold' }}>게시판 대시보드</span>
				</TitleWrapLeft>
				<TitleWrapRight>
					<span>홈 &gt; 게시판관리</span>
				</TitleWrapRight>
			</TitleWrap>
			<ControllerWrap>
				<ButtonWrap>
					<CreateButton onClick={() => setCreate(true)}>등록</CreateButton>
					{/* <UpdateButton>선택수정</UpdateButton> */}
					<SDeleteButton onClick={deleteCheckedList}>선택삭제</SDeleteButton>
				</ButtonWrap>
			</ControllerWrap>
			<TableWrap>
				<DataRowWrapHeader>
					<CheckboxWrap>
						<input type="checkbox"></input>
					</CheckboxWrap>
					<NumberWrap>NO.</NumberWrap>
					<Name>게시판명</Name>
					<Type>타입</Type>
					<Date>생성일</Date>
					<Count>게시글수</Count>
					<DeleteButtonWrap>관리</DeleteButtonWrap>
				</DataRowWrapHeader>
				{boards?.map((board, index) => {
					return (
						<DataRowWrap
							key={index.toString()}
							onDoubleClick={() => {
								setData(board)
							}}>
							<CheckboxWrap>
								<input type="checkbox" onChange={e => checked(e.target.checked, board.id)}></input>
							</CheckboxWrap>
							<NumberWrap>{board?.id}</NumberWrap>
							<Name>{board?.name}</Name>
							<Type>{board?.type}</Type>
							<Date>
								{moment(board?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}
							</Date>
							<Count>{board?.total}</Count>
							<DeleteButtonWrap>
								<DeleteButton onClick={() => deleteBoard(board?.id)}>삭제</DeleteButton>
							</DeleteButtonWrap>
						</DataRowWrap>
					)
				})}
				{boards?.length < 1 && <Nocontent>등록된 게시판이 없습니다</Nocontent>}
			</TableWrap>
			{create && CreateBoardCMP}
			{update && UpdateBoardCMP}
		</ContentWrap>
	)
}

export default BoardManagementTable
