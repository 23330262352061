import styled from 'styled-components'

export const Container = styled.div`
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
	width: 300px;
`

export const Header = styled.div`
	background-color: #6699ff;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
`

export const Badge = styled.div`
	background-color: rgba(135, 206, 235, 0.4);
	padding: 5px 10px;
	border-radius: 20px;
	color: #fff;
	font-size: 12px;
`

export const Body = styled.div`
	background-color: #6699ff;
	padding: 10px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
`

export const TabBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 10px;
`

export const Tab = styled.div<{ active: boolean }>`
	background-color: ${props => (props.active ? '#fff' : 'transparent')};
	padding: 5px 10px;
	border-radius: 20px;
	cursor: pointer;
`

export const Tabdiv = styled.div<{ active: boolean }>`
	color: ${props => (props.active ? '#6699ff' : '#fff')};
	font-size: 14px;
`

export const TabContent = styled.div`
	background-color: #fff;
	border-radius: 5px;
	padding: 10px;
`

export const NotificationList = styled.div`
	margin-top: 10px;
`

export const NotificationItem = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	border-bottom-width: 1px;
	border-bottom-color: #ccc;
`
export const User = styled.div`
	font-weight: bold;
	font-size: 14px;
`

export const Time = styled.div`
	font-size: 12px;
	color: #666;
`
