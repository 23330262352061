import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { View } from 'styles/reactnative'
import API from 'utils/api'
import HeaderButton from './HeaderButton'
import { HeaderButtonWrap, HeaderContainer, NavigationBarContainer } from './styles'

type Props = {
	setPath: React.Dispatch<React.SetStateAction<string>>
}

const Menu = ({ setPath }: Props) => {
	const navigate = useHistory()
	const location = useLocation()
	const currentPaths = location.pathname.split('/')

	const nav = (url: string) => navigate.push(url)

	const [menus, setMenus] = useState<any[]>([])

	const getMenus = async () => {
		try {
			const res = await API.send('GET', '/system/usermenus', null, {})

			if (res.result.success) {
				const mainMenu = res.result.originalMenu.filter(menu => menu.level === 1)

				let menuArr: any = []

				mainMenu.map(menu => {
					const subMenu = res.result.originalMenu.filter(sub => menu.id === sub.parent_menuid)
					let data: any = []
					subMenu.map(item =>
						data.push({
							title: item.name,
							goTo: `/${menu.path}/${item.path}`,
						})
					)
					const menuData = {
						...menu,
						data,
					}
					return menuArr.push(menuData)
				})

				setMenus(menuArr)
			} else {
			}
		} catch (error) {}
	}

	useEffect(() => {
		getMenus()
	}, [])

	return (
		<HeaderContainer>
			<NavigationBarContainer>
				<HeaderButtonWrap>
					<View
						style={{
							flexDirection: 'row',
							width: '100%',
							justifyContent: 'space-between',
							maxWidth: 800,
						}}>
						{menus.map((menu, index) => {
							return (
								<HeaderButton
									key={index.toString()}
									label={menu.name}
									data={menu.data && menu.data}
									onPress={() => {
										setPath(menu.path)
										nav(`/${menu.path}`)
									}}
									notIcon={true}
									active={currentPaths.indexOf(menu.path) >= 0}
								/>
							)
						})}
					</View>
				</HeaderButtonWrap>
			</NavigationBarContainer>
		</HeaderContainer>
	)
}

export default Menu
