import { useToken } from 'app/hooks'
import React, { useState, useEffect } from 'react'

import API from 'utils/api'
import styled from 'styled-components'
import {
	ButtonWrap,
	CreateButton,
	ContentWrap,
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	ControllerWrap,
} from 'styles/globalPage-styles'
import { CancelButton } from '../../BoardManage/styles'

import CIUtils from 'utils/ciutils'

import Editor from './editor'
import setting from '../../../../../../assets/icons/setting.png'

import * as antd from 'antd'

type Props = {
	handleModal: () => void
	refreshList: () => void
	data?: any
	customPages: any
}

function CreateCustom({ handleModal, refreshList, data, customPages }: Props) {
	const { data: token } = useToken()
	const [type, setType] = useState(data? data.type : 'page')
	const [layout, setLayout] = useState(data ? data.layout : 'web')
	const [path, setPath] = useState(data? data.path : '')
	const [title, setTitle] = useState(data? decodeURIComponent(data.title) : '')
	const [content, setContent] = useState<any>(data? decodeURIComponent(data.content) : '')
	const [active, setActive] = useState(data?.active ? data?.active : true)
	// const [file, setFile] = useState<File | null >(null);
	const [edit, setEdit] = useState(false)
	const [width, setWidth] = useState(data ? data.width : 100)
	const [height, setHeight] = useState(data ? data.height : 900)
	const [widthunit, setWidthunit] = useState(data ? data.widthunit : '%')
	const [heightunit, setHeightunit] = useState(data ? data.heightunit : 'px')
	const [order, setOrder] = useState(data ? data.order : 1)
	const [header, setHeader] = useState(data?.header ? data?.header : true)
	const [footer, setFooter] = useState(data?.footer ? data?.footer : true)
	const [editor, setEditor] = useState(null)
	const [imageFiles, setImageFiles] = useState<any>([])

	const initData = () => {
		if (data) {
			setTitle(decodeURIComponent(data.title))
			setType(data.type)
			setLayout(data.layout)
			setPath(data.path)
			setContent(decodeURIComponent(data.content))
			setActive(data.active)
			setWidth(data.width)
			setHeight(data.height)
			setWidthunit(data.widthunit)
			setHeightunit(data.heightunit)
			setOrder(data.order)
			setHeader(data.header)
			setFooter(data.footer)
		}
	}

	const datavalidate = () => {
		const find = customPages.find(b => b.path === path)

		if (type !== 'component' && find) {
			antd.message.info('경로가 중복되는 페이지가 있습니다')
			return false
		}

		if (!CIUtils.validateEnglish(path)) {
			antd.message.error('경로는 영문 또는 숫자를 사용하여 20자 이하로 작성해 주십시오')
			return false
		}

		return true
	}

	const create = async () => {
		let apiurl = '/custom/pages'

		const result = datavalidate()

		if (!result) {
			return
		}

		API.post(apiurl, token + '', {
			title,
			type,
			layout,
			path,
			active,
			header,
			footer,
			content,
			order,
			width,
			widthunit,
			height,
			heightunit,
		}).then((res) => {
			console.log(res)
			if (res?.result?.success) {
				antd.message.success('등록되었습니다')
				refreshList && refreshList()
				handleModal()
			} else {
				antd.message.success('등록에 실패하였습니다'+res?.result?.message)
				refreshList && refreshList()
				handleModal()
			}
		})
	}

	const validate = () => {
		let updateData: any = {}

		if (data.title != title) {
			updateData = { ...updateData, title }
		}

		if (data.type != type) {
			updateData = { ...updateData, type }
		}

		if (data.layout != layout) {
			updateData = { ...updateData, layout }
		}

		if (data.path != path) {
			updateData = { ...updateData, path }
		}

		if (data.order != order) {
			updateData = { ...updateData, order }
		}

		if (data.header != header) {
			updateData = { ...updateData, header }
		}

		if (data.footer != footer) {
			updateData = { ...updateData, footer }
		}

		if (data.content != content) {
			updateData = { ...updateData, content }
		}

		if (data.active != active) {
			updateData = { ...updateData, active }
		}

		if (data.width != width) {
			updateData = { ...updateData, width }
		}

		if (data.widthunit != widthunit) {
			updateData = { ...updateData, widthunit }
		}

		if (data.height != height) {
			updateData = { ...updateData, height }
		}

		if (data.heightunit != heightunit) {
			updateData = { ...updateData, heightunit }
		}

		const keys = Object.keys(updateData)
		if (keys.length < 1) return undefined
		return updateData
	}

	const update = async () => {
		const updateData = validate()

		if (!updateData) {
			antd.message.info('수정사항이 없습니다')
			return
		}

		try {
			let apiurl = `/custom/pages/${data.id}`

			API.put(apiurl, token + '', { updateData }).then(res => {
				if (res.result.success) {
					antd.message.success('수정되었습니다')
					handleModal()
					refreshList && refreshList()
				}
			})
		} catch (error: any) {
			//	antd.message.error(error)
		}
	}

	function radioChange(e: antd.RadioChangeEvent) {
		switch (e.target.name) {
			case 'type':
				setType(e.target.value)
				break
			case 'layout':
				setLayout(e.target.value)
				break
		}
	}

	function headerChange(checked: boolean) {
		setHeader(checked)
	}

	function footerChange(checked: boolean) {
		setFooter(checked)
	}

	function activeChange(checked: boolean) {
		setActive(checked)
	}

	function settings(event: React.ChangeEvent<HTMLInputElement>) {
		const value = event.target.value

		switch (event.target.name) {
			case 'title':
				setTitle(value)
				break
			case 'path':
				setPath(value)
				break
			case 'type':
				setType(value)
				break
			case 'active':
				if (value === 'true') setActive(true)
				else setActive(false)
				break
			case 'width':
				if (isNaN(Number(value))) break
				setWidth(Number(value))
				break
			case 'height':
				if (isNaN(Number(value))) break
				setHeight(Number(value))
				break
			case 'order':
				if (isNaN(Number(value))) break
				setOrder(Number(value))
				break
		}
	}

	const WidthUnit = (
		<antd.Select onChange={e => setWidthunit(e)} defaultValue={widthunit}>
			<antd.Select.Option value="%">%</antd.Select.Option>
			<antd.Select.Option value="px">px</antd.Select.Option>
		</antd.Select>
	)

	const HeightUnit = (
		<antd.Select onChange={e => setHeightunit(e)} defaultValue={heightunit}>
			<antd.Select.Option value="%">%</antd.Select.Option>
			<antd.Select.Option value="px">px</antd.Select.Option>
		</antd.Select>
	)

	useEffect(() => {
		if (!edit) initData()
	}, [edit])

	return (
		<ContentWrap>
			<TitleWrap>
				<TitleWrapLeft>
					<img src={setting} alt="icon"></img>
					<span style={{ fontSize: '20px', fontWeight: 'bold' }}>페이지 작성</span>
				</TitleWrapLeft>
				<TitleWrapRight>
					<span>홈 &gt; 페이지관리</span>
				</TitleWrapRight>
			</TitleWrap>
			<ControllerWrap>
				<ButtonWrap></ButtonWrap>
			</ControllerWrap>
			<TableContainer>
				<RowData>
					<RowDataNameHeader>구분</RowDataNameHeader>
					<RowDataContentHeader>내용</RowDataContentHeader>
				</RowData>
				<RowData>
					<RowDataName>레이아웃</RowDataName>
					<RowDataContent>
						{!data ? (
							<fieldset
								style={{ display: 'flex', gap: '10px', alignItems: 'center', paddingLeft: '10px' }}>
								<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
									<antd.Radio
										type="radio"
										id="layout"
										name="layout"
										onChange={radioChange}
										value={'web'}
										checked={layout === 'web' ? true : false}>
										웹
									</antd.Radio>
								</div>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<antd.Radio
										type="radio"
										id="layout"
										name="layout"
										onChange={radioChange}
										value={'mobile'}
										checked={layout === 'mobile' ? true : false}>
										모바일
									</antd.Radio>
								</div>
							</fieldset>
						) : !edit ? (
							data?.layout
						) : (
							<fieldset
								style={{ display: 'flex', gap: '10px', alignItems: 'center', paddingLeft: '10px' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<antd.Radio
										type="radio"
										id="layout"
										name="layout"
										onChange={radioChange}
										checked={layout === 'web' ? true : false}
										value={'web'}>
										웹
									</antd.Radio>
								</div>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<antd.Radio
										type="radio"
										id="layout"
										name="layout"
										onChange={radioChange}
										checked={layout === 'mobile' ? true : false}
										value={'mobile'}>
										모바일
									</antd.Radio>
								</div>
							</fieldset>
						)}
					</RowDataContent>
				</RowData>
				<RowData>
					<RowDataName>페이지형태</RowDataName>
					<RowDataContent>
						{!data ? (
							<fieldset
								style={{ display: 'flex', gap: '10px', alignItems: 'center', paddingLeft: '10px' }}>
								<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
									<antd.Radio
										type="radio"
										id="type"
										name="type"
										onChange={radioChange}
										value={'page'}
										checked={type === 'page' ? true : false}>
										페이지
									</antd.Radio>
								</div>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<antd.Radio
										type="radio"
										id="type"
										name="type"
										onChange={radioChange}
										value={'component'}
										checked={type === 'component' ? true : false}>
										컴포넌트
									</antd.Radio>
								</div>
							</fieldset>
						) : !edit ? (
							data?.type
						) : (
							<fieldset
								style={{ display: 'flex', gap: '10px', alignItems: 'center', paddingLeft: '10px' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<antd.Radio
										type="radio"
										id="type"
										name="type"
										onChange={radioChange}
										defaultChecked={type === 'page' ? true : false}
										value={'page'}>
										페이지
									</antd.Radio>
								</div>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<antd.Radio
										type="radio"
										id="type"
										name="type"
										onChange={radioChange}
										defaultChecked={type === 'component' ? true : false}
										value={'component'}>
										컴포넌트
									</antd.Radio>
								</div>
							</fieldset>
						)}
					</RowDataContent>
				</RowData>
				<RowData>
					<RowDataName>분류</RowDataName>
					<RowDataContent>
						{!data ? (
							<antd.Input type="text" name="title" onChange={settings} value={title}></antd.Input>
						) : !edit ? (
							title
						) : (
							<antd.Input type="text" name="title" onChange={settings} value={title}></antd.Input>
						)}
					</RowDataContent>
				</RowData>
				<RowData>
					<RowDataName>경로</RowDataName>
					<RowDataContent>
						{!data ? (
							<antd.Input type="text" name="path" onChange={settings} value={path}></antd.Input>
						) : !edit ? (
							path
						) : (
							<antd.Input type="text" name="path" onChange={settings} value={path}></antd.Input>
						)}
					</RowDataContent>
				</RowData>
				{!data ? (
					type === 'component' ? (
						<RowData>
							<RowDataName>컴포넌트배치순서</RowDataName>
							<RowDataContent>
								<Input type="text" name="order" onChange={settings} value={order}></Input>
							</RowDataContent>
						</RowData>
					) : null
				) : type === 'component' ? (
					!edit ? (
						<RowData>
							<RowDataName>컴포넌트배치순서</RowDataName>
							<RowDataContent>{order}</RowDataContent>
						</RowData>
					) : (
						<RowData>
							<RowDataName>컴포넌트배치순서</RowDataName>
							<RowDataContent>
								<Input type="text" name="order" onChange={settings} value={order}></Input>
							</RowDataContent>
						</RowData>
					)
				) : null}
				<RowData>
					<RowDataName>사이즈</RowDataName>
					<RowDataContent>
						{!data ? (
							<antd.Space>
								<label>가로</label>
								<antd.Input
									addonAfter={WidthUnit}
									type="text"
									name="width"
									onChange={settings}
									value={width}></antd.Input>
								<label>세로</label>
								<antd.Input
									addonAfter={HeightUnit}
									type="text"
									name="height"
									onChange={settings}
									value={height}></antd.Input>
							</antd.Space>
						) : !edit ? (
							`가로 ${width}px / 세로 ${height}px`
						) : (
							<antd.Space>
								<label>가로</label>
								<antd.Input
									addonAfter={WidthUnit}
									type="text"
									name="width"
									onChange={settings}
									value={width}
									defaultValue={width}></antd.Input>
								<label>세로</label>
								<antd.Input
									addonAfter={HeightUnit}
									type="text"
									name="height"
									onChange={settings}
									value={height}
									defaultValue={height}></antd.Input>
							</antd.Space>
						)}
					</RowDataContent>
				</RowData>
				<RowData>
					<RowDataName>헤더표시</RowDataName>
					<RowDataContent>
						{!data ? (
							<antd.Switch size="small" onChange={headerChange}></antd.Switch>
						) : !edit ? (
							header ? (
								'사용'
							) : (
								'미사용'
							)
						) : (
							<antd.Switch
								size="small"
								onChange={headerChange}
								defaultChecked={header}></antd.Switch>
						)}
					</RowDataContent>
				</RowData>
				<RowData>
					<RowDataName>푸터표시</RowDataName>
					<RowDataContent>
						{!data ? (
							<antd.Switch size="small" onChange={footerChange}></antd.Switch>
						) : !edit ? (
							footer ? (
								'사용'
							) : (
								'미사용'
							)
						) : (
							<antd.Switch
								size="small"
								onChange={headerChange}
								defaultChecked={footer}></antd.Switch>
						)}
					</RowDataContent>
				</RowData>
				<RowData>
					<ContentEditorName>내용</ContentEditorName>
					{!data ? (
						<ContentEditor>
							<Editor content={content} edit={edit} setContent={setContent}></Editor>
						</ContentEditor>
					) : !edit ? (
						<ContentEditor dangerouslySetInnerHTML={{ __html: content }} />
					) : (
						<ContentEditor>
							<Editor content={content} edit={edit} setContent={setContent}></Editor>
						</ContentEditor>
					)}
				</RowData>
				<RowData>
					<RowDataName>활성화</RowDataName>
					<RowDataContent>
						{!data ? (
							<antd.Switch size="small" onChange={activeChange}></antd.Switch>
						) : !edit ? (
							active ? (
								'활성화'
							) : (
								'비활성화'
							)
						) : (
							<antd.Switch
								size="small"
								onChange={activeChange}
								defaultChecked={active}></antd.Switch>
						)}
					</RowDataContent>
				</RowData>
				{/* {
					data && 
					<div>
						<label>기존 파일</label>
						<input name='url' type='text' value={data?.url}></input>
					</div>
				<div>
					<label>내용</label>
					<textarea name='content' onChange={(e)=>{console.log(e.target.type)}} value={content}></textarea>
				</div> 
				<div>
					<label> 파일</label>
					<input name='file' type='file' onChange={settings}></input>
				</div> */}
			</TableContainer>
			{!data ? (
				<ButtonWrap>
					<CreateButton onClick={create}>저장</CreateButton>
					<CancelButton onClick={handleModal}>취소</CancelButton>
				</ButtonWrap>
			) : !edit ? (
				<ButtonWrap>
					<CreateButton onClick={() => setEdit(true)}>수정</CreateButton>
				</ButtonWrap>
			) : (
				<ButtonWrap>
					<CreateButton onClick={update}>저장</CreateButton>
					<CancelButton onClick={() => setEdit(false)}>취소</CancelButton>
				</ButtonWrap>
			)}
		</ContentWrap>
	)
}

export default CreateCustom

const Modal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 20px;
	gap: 16px;
	isolation: isolate;

	position: absolute;
	/* width: 742px; */
	width: 900px;
	/* height: 550px; */
	left: 50%;
	top: 15%;
	margin-left: -450px;

	/* white */

	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(88, 88, 88, 0.25);
	border-radius: 2px;
`

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	gap: 26px;

	width: 860px;
	height: 23px;
`
const Title = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 4px;

	height: 23px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 23px;

	/* text/primary */

	color: #333333;
`

const TableContainer = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	/* width: 702px;*/

	width: 100%;
	/* height: 417px; */

	/* table/border */

	margin-bottom: 20px;

	border: 1px solid #e4e4e4;
`

const RowData = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	/* width: 702px;*/
	width: 100%;

	background: #ffffff;
`

const RowDataNameHeader = styled.div`
	box-sizing: border-box;

	width: 120px;
	height: 36px;
	line-height: 36px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 0px 0px 0px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`
const RowDataContentHeader = styled.div`
	box-sizing: border-box;

	/* width: 602px; */
	width: 90%;
	height: 36px;
	line-height: 36px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 0px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`

const RowDataName = styled.div`
	box-sizing: border-box;

	width: 120px;
	height: 37px;
	line-height: 37px;

	background: #ffffff;
	/* table/border */

	border-width: 1px 0px 0px 0px;
	border-style: solid;
	border-color: #e4e4e4;
	border-collapse: collapse;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`

const RowDataContent = styled.div`
	box-sizing: border-box;

	display: flex;
	align-items: center;

	/* width: 602px;*/
	width: 90%;
	height: 37px;
	line-height: 37px;
	padding: 5px;
	background: #ffffff;
	/* table/border */
	border-collapse: collapse;

	border-width: 1px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text/contents */

	color: #666666;
`
const Input = styled.input`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 10px;

	width: 100%;
	height: 26px;

	/* stroke */

	border: 1px solid #cccccc;
	border-radius: 2px;

	&:focus {
		border: 1px solid #333333;
	}
`

const ContentEditorName = styled.div`
	box-sizing: border-box;

	width: 120px;
	height: 350px;
	line-height: 350px;

	background: #ffffff;
	/* table/border */

	border-width: 1px 0px 0px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`

const ContentEditor = styled.div`
	box-sizing: border-box;

	/* width: 602px;
	 */
	width: 90%;
	height: 350px;
	padding: 5px;

	overflow-y: auto;
	overflow-x: hidden;

	background: #ffffff;
	/* table/border */

	border-width: 1px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text/contents */

	color: #666666;
`

const SizeInput = styled.input`
	width: 86px;
	height: 26px;

	border: 1px solid #cccccc;
	border-radius: 2px;

	&:focus {
		border: 1px solid #333333;
	}
`
