import React, { useState, useEffect } from 'react'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import {
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	ButtonWrap,
	CreateButton,
	SDeleteButton,
	TableWrap,
	DataRowWrap,
	CheckboxWrap,
	DeleteButtonWrap,
	DeleteButton,
	ControllerWrap,
	DataRowWrapHeader,
	Nocontent,
} from 'styles/globalPage-styles'
import setting from '../../../../../../assets/icons/setting.png'
import SettingModal from './SettingModal'
import styled from 'styled-components'
import PaginatedItems from 'app/pages/CMSPage/components/Pagination'
import * as antd from 'antd'

const SystemManagementTable = () => {
	const { data: token } = useToken()
	const [pageSize, setPageSize] = useState<number[]>([])
	const [page, setPage] = useState(1)

	const [data, setData] = useState<any>(null)
	const [createModal, setCreateModal] = useState(false)
	const [updateModal, setUpdateModal] = useState(false)

	const [customDatas, setCustomDatas] = useState<any[]>([])

	const [checkedList, setCheckedList] = useState<any>([])
	const [change, setChagne] = useState(false)

	const deleteCheckedList = () => {
		if (checkedList?.length < 1) return

		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}

		checkedList?.map(c => {
			let apiurl = `/system/settings/${c.key}`

			API.delete(apiurl, token + '', {})
				.then((res: any) => {
					if (res.result.success) {
					}
				})
				.catch((err: any) => {
					// toast('삭제에 실패하였습니다\n' + err)
				})
		})

		antd.message.success('삭제되었습니다')
		setChagne(true)

	}

	useEffect(()=>{
		if(change){
			setCheckedList([])
			getGlobalSettings()
			setChagne(false)
		}
	},[change])
	const checked = (checked: boolean, key: string) => {
		let temp = [...checkedList]
		if (checked) {
			temp.push({ key: key })
		} else {
			temp = temp.filter(f => f.key !== key)
		}
		setCheckedList(temp)
	}

	const changePage = (num: number) => {
		setPage(num)
	}

	const handleModal = () => {
		createModal && setCreateModal(false)

		if (updateModal) {
			setUpdateModal(false)
			setData(null)
		}
	}

	const getGlobalSettings = () => {
		let apiurl = '/system/settings?maxData=20&page=' + page

		API.get(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					setCustomDatas(res.result.result.settings)
					setPageSize(Array.from({ length: res.result.result.pageLength }, (v, i) => i + 1))
				}
			})
			.catch((err: any) => {
				console.log(err)
			})
	}

	const deleteSetting = (key: string) => {
		const confirm = window.confirm('삭제하시겠습니까?')

		if (!confirm) {
			return
		}
		
		
		let apiurl = `/system/settings/${key}`

		API.delete(apiurl, token + '', {})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('삭제되었습니다')
					getGlobalSettings()
				}
			})
			.catch((err: any) => {
				alert(err)
			})
	}

	const refreshList = () => {
		getGlobalSettings()
	}

	const UpdateCMP = (
		<SettingModal
			showModal={updateModal}
			handleModal={handleModal}
			refreshList={refreshList}
			data={data}
			customDatas={customDatas}
		/>
	)

	const CreateCMP = (
		<SettingModal
			showModal={createModal}
			handleModal={handleModal}
			refreshList={refreshList}
			customDatas={customDatas}
		/>
	)

	useEffect(() => {
		if (!page) setPage(1)
		else getGlobalSettings()
	}, [page])

	useEffect(() => {
		if (data) setUpdateModal(true)
	}, [data])

	return (
		<div style={{ width: 800, maxWidth: '100%', overflowX: 'auto', background: '#fff' }}>
			<TitleWrap>
				<TitleWrapLeft>
					<img src={setting} alt="icon"></img>
					<span style={{ fontSize: '20px', fontWeight: 'bold' }}>시스템설정</span>
				</TitleWrapLeft>
				<TitleWrapRight>
					<span>홈 &gt; 시스템 &gt; 시스템설정</span>
				</TitleWrapRight>
			</TitleWrap>
			<ControllerWrap>
				<ButtonWrap>
					<CreateButton onClick={() => setCreateModal(true)}>등록</CreateButton>
					<SDeleteButton onClick={deleteCheckedList}>선택삭제</SDeleteButton>
				</ButtonWrap>
			</ControllerWrap>
			<TableWrap>
				<DataRowWrapHeader>
					<CheckboxWrap>
						<input type="checkbox"></input>
					</CheckboxWrap>
					<Key>고유키</Key>
					<Value>값</Value>
					<Description>내용</Description>
					<DeleteButtonWrap>관리</DeleteButtonWrap>
				</DataRowWrapHeader>
				{customDatas?.map((setting, index) => {
					return (
						<DataRowWrap key={index.toString()} onDoubleClick={() => setData(setting)}>
							<CheckboxWrap>
								<input
									type="checkbox"
									onChange={e => checked(e.target.checked, setting.key)}></input>
							</CheckboxWrap>
							<Key>{setting.key}</Key>
							<Value>{setting.value}</Value>
							<Description>{setting.description}</Description>
							<DeleteButtonWrap>
								<DeleteButton onClick={() => deleteSetting(setting.key)}>삭제</DeleteButton>
							</DeleteButtonWrap>
						</DataRowWrap>
					)
				})}
				{customDatas?.length < 1 && <Nocontent>등록된 시스템 설정이 없습니다</Nocontent>}
			</TableWrap>
			{createModal ? CreateCMP : updateModal ? UpdateCMP : null}
			<PaginatedItems page={page} pageSize={pageSize} changePage={changePage} itemsPerPage={5} />
		</div>
	)
}

export default SystemManagementTable

const Key = styled.div`
	box-sizing: border-box;

	width: 150px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

const Value = styled.div`
	box-sizing: border-box;

	width: 212px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

const Description = styled.div`
	box-sizing: border-box;

	width: 500px;
	height: 36px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	text-align: center;
`
