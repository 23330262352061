import styled from 'styled-components'

export const FooterContainer = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	color: #ccc;
	font-size: 1.3rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const FooterWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 10px;
`

export const Copyright = styled.div`
	display: flex;
	align-items: center;
`
export const CopyrightText = styled.div``
export const PrivacyBtn = styled.div`
	display: flex;
`
