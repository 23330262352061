import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useToken } from 'app/hooks/'
import API from 'utils/api'
import {
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	ContentWrap,
	ControllerWrap,
	ButtonWrap,
	UpdateButton,
	CreateButton,
} from 'styles/globalPage-styles'
import setting from '../../../../../assets/icons/setting.png'
import UserListModal from './UserListModal'
import { CancelButton } from '../BoardManage/styles'
import * as antd from 'antd'

type Props = {
	handleWriteForm: () => void
}

const NotificationWriteForm = ({ handleWriteForm }: Props) => {
	const [content, setContent] = useState('')
	const [push, setPush] = useState(false)
	const [receivers, setReceivers] = useState<any[]>([])

	const { data: token } = useToken()

	const [modal, setModal] = useState(false)

	const [receiverList, setReceiverList] = useState<any[]>([])
	const [showReceiverList, setShowReceiverList] = useState<any[]>([])

	const handleModal = () => {
		setModal(!modal)
	}

	const createNotification = () => {
		if (!content) {
			antd.message.error('내용을 입력해주세요')
			return
		}

		if (receiverList?.length < 1) {
			antd.message.error('수신인을 설정해주세요')
			return
		}

		API.post('/notification', token + '', {
			content,
			push,
			receivers: receiverList,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('알림 발송 완료')
					handleWriteForm()
				}
			})
			.catch((err: any) => {
				//	antd.message.error('error : ' + err)
			})
	}

	const addReceiver = (data: any) => {
		const find = receivers.find(reciever => reciever === data.id)

		let tempArr: any = []
		if (find) {
			tempArr = receivers.filter(receiver => receiver !== data.id)
		} else {
			tempArr = [...receivers, data.id]
		}

		setReceivers(tempArr)
	}

	useEffect(() => {
		if (receiverList.length > 0) setShowReceiverList(receiverList)
	}, [receiverList])

	return (
		<ContentWrap>
			<TitleWrap>
				<TitleWrapLeft>
					<img src={setting} alt="icon"></img>
					<span style={{ fontSize: '20px', fontWeight: 'bold' }}>알림 전송하기</span>
				</TitleWrapLeft>
				<TitleWrapRight>
					<span>홈 &gt; 알림관리 &gt; 작성하기</span>
				</TitleWrapRight>
			</TitleWrap>
			<ControllerWrap>
				<ButtonWrap>
					<UpdateButton onClick={handleModal}>등록</UpdateButton>
				</ButtonWrap>
			</ControllerWrap>
			<List>
				<ListTitle>받는사람</ListTitle>
				<ListContent>
					{showReceiverList?.map(receiver => {
						return (
							<Receiver>
								<span>{receiver.name}</span>
							</Receiver>
						)
					})}
				</ListContent>
			</List>
			<Form>
				<FormTitle>내용</FormTitle>
				<FormContent onChange={e => setContent(e.target.value)}></FormContent>
			</Form>
			<ButtonWrap>
				<CreateButton onClick={createNotification}>완료</CreateButton>
				<CancelButton onClick={handleModal}>취소</CancelButton>
			</ButtonWrap>
			{modal && (
				<UserListModal
					showModal={modal}
					handleModal={handleModal}
					receiverList={receiverList}
					setReceiverList={setReceiverList}
				/>
			)}
		</ContentWrap>
	)
}

export default NotificationWriteForm

const Form = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	margin: 10px 0;
	width: 974px;
	height: 488px;
	border-width: 1px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
	background: #ffffff;
`

const FormTitle = styled.div`
	box-sizing: border-box;

	text-align: center;
	width: 150px;
	height: 488px;
	line-height: 488px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 0px 1px 0px 0px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`

const FormContent = styled.textarea`
	box-sizing: border-box;

	/* Auto layout */

	margin: 10px;
	padding: 10px;

	width: 800px;
	height: 468px;
	resize: none;

	/* stroke */

	border: 1px solid #cccccc;
	border-radius: 2px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 130%;
	/* identical to box height, or 16px */

	/* text/contents */

	color: #666666;
`

const List = styled.div`
	/* box-sizing: border-box; */

	/* Auto layout */

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	width: 974px;
	height: 100px;

	/* table/border */

	/* border: 1px solid red; */
`
const ListTitle = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	width: 150px;
	height: 100px;
	line-height: 100px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/contents */

	color: #666666;
`
const ListContent = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-flow: row wrap;

	overflow-x: hidden;
	overflow-y: auto;

	width: 824px;
	height: 100px;
	padding: 10px;

	/* white */

	background: #ffffff;
	/* table/border */

	border-width: 1px 1px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	/* or 18px */

	/* text/contents */

	color: #666666;
`

const Receiver = styled.div`
	width: auto;
	height: 20px;
	margin-right: 10px;
`
