import styled, { css, keyframes } from 'styled-components'

export const AdminHeaderRightSection = styled.div`
	display: flex;
	flex-direction: row;
`
export const Button = styled.div<{ isOpen: boolean }>`
	padding: 7px;
	background-color: ${({ isOpen }) => (isOpen ? '#eee' : '#fff')};
	border-radius: 50px;
	cursor: pointer;
`

export const DropdownContainer = styled.div<{ isOpen: boolean; top: number }>`
	position: absolute;
	top: 70px;
	margin-right: 120px;
	background-color: white;
	box-shadow: 0px 5px 10px rgba(100, 100, 100, 0.2);
	overflow: hidden;
	border-radius: 10px;
	max-height: ${({ isOpen }) => (isOpen ? '600px' : '0')};
	transition: all 0.6s ease-in-out;
	transform-origin: bottom center;
	transform: ${({ isOpen }) => (isOpen ? 'scaleY(1)' : 'scaleY(0.1)')};
	animation: ${({ isOpen }) =>
		isOpen
			? css`
					${fadeIn} 0.3s ease-out
			  `
			: css`
					${fadeOut} 0.3s ease-in
			  `};
`
const fadeIn = keyframes`
  0% {
    transform: scaleY(0.5);
  }
  50% {
    transform: scaleY(0.9);
  }
  100% {
    transform: scaleY(1);
  }
`
const fadeOut = keyframes`
  0% {
    transform: scaleY(1);
  }
  60% {
    transform: scaleY(0.9);
  }
  100% {
    transform: scaleY(0.5);
  }
`
export const ProfileDropdownHeader = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	border-bottom: 1px solid #eee;
	padding: 10px;
	width: 100%;
`
