import styled, { keyframes } from 'styled-components'

export const AdminSideBarContentSection = styled.div<{ maxHeight: number }>`
	width: 249px;
	height: 100%;
	background-color: #f6f8fa;
	border-right: 1px solid #ddd;
	max-height: ${props => props.maxHeight}px;
	overflow-y: scroll;
	overflow-x: hidden;
	overscroll-behavior: contain; /* or overscroll-behavior: none; */
	-webkit-overflow-scrolling: touch; /* enable momentum scrolling on iOS */
	::-webkit-scrollbar {
		width: 4px;
	}
	::-webkit-scrollbar-track {
		background: #f6f8fa;
	}
	::-webkit-scrollbar-thumb {
		background-color: #ddd;
		border-radius: 4px;
		&:hover {
			background-color: #bbb;
		}
	}
`
export const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`
export const ArrowIcon = styled.span<{ isOpen?: boolean }>`
	margin-left: auto;
	transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
	transition: transform 0.2s ease-in-out;
`

export const SideBarMenuSection = styled.div`
	padding-left: 20px;
	padding-right: 10px;
`

export const MenuList = styled.ul`
	list-style: none;
	padding-left: 0;
`
export const MenuListSection = styled.div`
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 15px;
	width: 100%;
`

export const MenuItem = styled.li`
	display: flex;
	align-items: center;
	padding: 10px;
	cursor: pointer;
	justify-content: space-between;
	width: 100%;
	&:hover {
		background-color: #f5f5f5;
	}
`

export const MenuItemTitle = styled.span`
	margin-left: 10px;
`

export const SubMenuList = styled.ul`
	list-style: none;
	margin-left: 13px;
	padding-left: 0;
	width: 100%;
`

export const SubMenuItem = styled.li<{ isActive: Boolean }>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
	cursor: pointer;
	width: 100%;
	&:hover {
		color: dodgerblue;
	}
	${({ isActive }) => isActive && `color:dodgerblue`}
`
