import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { COLORS } from 'app/constants'
import { useWindowDimensions } from 'app/hooks'
import CButton from '../CButton'
import { goTo } from '../../../hooks/navigation'
import { useHistory } from 'react-router-dom'
// import { ThemeContext } from 'theme/ThemeProvider'

const activeTabs = (tabs: any, item: any, size: any) => {
	return tabs === item ? size : size - 1
}
const CTabs = ({
	tabs,
	setTabs,
	data,
	style,
	vStyle = {},
	icon,
	iconLeft,
	activeColor,
	activeSize,
	fixedSize,
	noBorder,
	bgActiveColor,
	notActiveBgColor,
	borderActiveColor,
}: any) => {
	// const { isDarkMode } = useContext(ThemeContext)
	const { width } = useWindowDimensions()
	const { t } = useTranslation()
	const navigator = useHistory()
	return (
		<CTabSection style={{ ...vStyle }}>
			{data.map((item: any, index: any) => {
				return (
					<CButton
						icon={icon ? icon[index] : null}
						iconLeft={iconLeft}
						key={index.toString()}
						title={item}
						onClick={() => {
							setTabs(index)
							goTo(navigator, '/user/' + index)
						}}
						style={{
							padding: 6,
							paddingBottom: 10,
							marginBottom: -1.5,
							color:
								tabs === index
									? activeColor
										? activeColor
										: COLORS.secondary
									: COLORS.white,
							background:
								tabs === item && bgActiveColor
									? bgActiveColor
									: notActiveBgColor,
							borderBottomWidth: noBorder ? noBorder : 2,
							borderBottomColor:
								tabs === item
									? borderActiveColor
										? borderActiveColor
										: COLORS.primary
									: 'transparent',
							borderRadius: 0,
							fontSize: fixedSize
								? fixedSize
								: width > 900
								? activeTabs(
										tabs,
										item,
										activeSize ? activeSize : 14
								  )
								: activeTabs(
										tabs,
										item,
										activeSize ? activeSize - 2 : 10
								  ),
							fontWeight: tabs === item ? '500' : '300',
							...style,
							zIndex: 1,
						}}
					/>
				)
			})}
		</CTabSection>
	)
}

const CTabSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 10px;
	padding-top: 5px;
	padding-bottom: 0;
	width: 100%;
	@media (max-width: 600px) {
		justify-content: center;
	}
`
export default React.memo(CTabs)
