import styled from 'styled-components'
import { COLORS } from 'app/constants/STYLES'

export const QrSectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	cursor: pointer;
	background-color: ${COLORS.white};
	width: 100%;
	height: 70%;
	justify-content: space-around;
	align-items: center;
	border-radius: 50px;
`
export const QrSectionTitle = styled.div`
	justify-content: center;
	align-items: center;
	margin-top: -70px;
`

export const TopLeftCorner = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	border-top: 20px solid ${COLORS.secondary};
	border-right: 20px solid ${COLORS.secondary};
`

export const TopRightCorner = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	border-top: 20px solid ${COLORS.secondary};
	border-left: 20px solid ${COLORS.secondary};
`

export const BottomLeftCorner = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	border-bottom: 20px solid ${COLORS.secondary};
	border-right: 20px solid ${COLORS.secondary};
`

export const BottomRightCorner = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	border-bottom: 20px solid ${COLORS.secondary};
	border-left: 20px solid ${COLORS.secondary};
`
