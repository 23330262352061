import React, { useState, useEffect } from 'react'
import { useToken, useUser } from 'app/hooks/'
import API from 'utils/api'
import {
	TitleWrap,
	TitleWrapLeft,
	CreateButton,
	TableWrap,
	ContentWrap,
} from 'styles/globalPage-styles'
import moment from 'moment'
import styled from 'styled-components'
import * as antd from 'antd'

const CommentManagementTable = () => {
	const { data: token } = useToken()

	const { data: username } = useUser()
	const [boardid, setBoardid] = useState<any>(
		parseInt(window.location.pathname?.split('/')?.[3]?.split('?')[0]) || 0
	)
	const [postid, setPostid] = useState<any>(
		parseInt(window.location.pathname?.split('/')?.[4]?.split('?')[0]) || 0
	)
    const [comments, setCommnets] = useState<any>([])
    const [edit, setEdit] = useState(false)
    const [editKey, setEditKey] = useState<number | null>(null)
    const [content, setContent] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    // const [content, setContent] = useState('')

	const getComments = () => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		setBoardid(parseInt(window.location.pathname?.split('/')?.[3]?.split('?')[0]) || 0)
		setPostid(parseInt(window.location.pathname?.split('/')?.[4]?.split('?')[0]) || 0)

		let apiurl = `/board/${boardPath}/${postid}/comments?maxData=20&page=` + 1

        API.get(apiurl, token + '', {})
            .then((res: any) => {
                if (res.result.success) {
                    setCommnets(res.result.result.comments)
                    setTotalCount(res.result.result.totalCount)
                }
            })
            .catch((err: any) => {
                console.log(err)
            })
    }

	useEffect(() => {
		getComments()
	}, [])

	useEffect(() => {
		if (!refresh) {
			return
		}

		getComments()
		setRefresh(false)
	}, [refresh])

	const updateComment = (commentid: number) => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]

		let apiurl = `/board/${boardPath}/${postid}/comments/${commentid}`

		if (!content) {
			antd.message.info('내용을 입력해 주세요')
			return
		}

		API.put(apiurl, token + '', {
			content,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('수정되었습니다')
					setContent('')
					setEditKey(null)
					setRefresh(true)
				}
			})
			.catch((err: any) => {
				//	antd.message.error('error : ' + err)
			})
	}

	const createComment = (content: string, lock: boolean) => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]

		let apiurl = `/board/${boardPath}/${postid}/comments`

		if (!content) {
			antd.message.info('내용을 입력해 주세요')
			return
		}

		API.post(apiurl, token + '', {
			content,
			username,
			lock,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('등록되었습니다')
					setRefresh(true)
				}
			})
			.catch((err: any) => {
				//	antd.message.error('error : ' + err)
			})
	}

	const deleteComment = (id: number) => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]

		let apiurl = `/board/${boardPath}/${postid}/comments/${id}`

		API.delete(apiurl, token + '', {}).then(() => {
			antd.message.success('삭제완료')
			getComments()
		})
	}
	return (
        <ContentWrap>
            <TitleWrap style={{ borderBottom : '1px solid gray'}}>
                <TitleWrapLeft>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>
						{`댓글 (${totalCount})`}
					</span>
				</TitleWrapLeft>
			</TitleWrap>
			<TableWrap>
				{comments.map((comment, key) => {
					return (
						<DataRow>
							<Top>
								<Info>
									<Username>{comment?.writer}</Username>
									<Date>
										{moment(comment?.created_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}
									</Date>
								</Info>
								<ButtonWrap>
									{editKey !== key ? (
										<ModifyButton
											onClick={() => {
												setEdit(true)
												setEditKey(key)
											}}>
											수정
										</ModifyButton>
									) : (
										<ModifyButton onClick={() => updateComment(comment.id)}>완료</ModifyButton>
									)}
									<DeleteButton onClick={() => deleteComment(comment.id)}>삭제</DeleteButton>
								</ButtonWrap>
							</Top>
							<Content
								contentEditable={editKey === key ? true : false}
								onInput={e => setContent(e.currentTarget.innerText)}>
								{comment?.content}
							</Content>
						</DataRow>
						// <div style={{display:"flex", justifyContent:"space-between", borderBottom: "1px solid gray", width: '100%', backgroundColor: "white", padding: "5px" }}>
						//     <div>
						//         <div style={{display: "flex"}}>
						//             <div style={{ marginRight:  "5px"}} >{comment.name}</div>
						//             <div>{moment(comment.created_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}</div>
						//         </div>
						//         <div contentEditable={editKey === key ? true : false} onInput={(e)=>setContent(e.currentTarget.innerText)}>{comment.content}</div>
						//     </div>
						//     <div>
						//     {
						//         editKey !== key ? <button onClick={()=>{setEdit(true); setEditKey(key)}}>수정</button> : <button onClick={() => updateComment(comment.id)}>완료</button>
						//         }
						//         <button onClick={deleteComment}>삭제</button>
						//     </div>
						//     {/* <DeleteButton url={`/board/${boardid}/${postid}`} params={`comments/${comment.id}`}/> */}
						// </div>
					)
				})}
			</TableWrap>
			<CommentInput createComment={createComment} />
		</ContentWrap>
		// <TableListSection>
		// 	<TableContainer
		// 		className="ag-theme-alpine"
		// 		style={{
		// 			width: '100%',
		// 			height: height - 150,
		// 		}}>
		//             <div>
		//                 {
		//                     comments.map((comment, key) => {
		//                         return(
		//                             <div style={{display:"flex", justifyContent:"space-between", borderBottom: "1px solid gray", width: '100%', backgroundColor: "white", padding: "5px" }}>
		//                                 <div>
		//                                     <div style={{display: "flex"}}>
		//                                         <div style={{ marginRight:  "5px"}} >{comment.name}</div>
		//                                         <div>{moment(comment.created_at).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}</div>
		//                                     </div>
		//                                     <div contentEditable={editKey === key ? true : false} onInput={(e)=>setContent(e.currentTarget.innerText)}>{comment.content}</div>
		//                                 </div>
		//                                 <div>
		//                                 {
		//                                     editKey !== key ? <button onClick={()=>{setEdit(true); setEditKey(key)}}>수정</button> : <button onClick={() => updateComment(comment.id)}>완료</button>
		//                                  }
		//                                  <button onClick={deleteComment}>삭제</button>
		//                                 </div>
		//                                 {/* <DeleteButton url={`/board/${boardid}/${postid}`} params={`comments/${comment.id}`}/> */}
		//                             </div>
		//                         )
		//                     })
		//                 }
		//             </div>
		//             <CommentInput
		//                 createComment={createComment}
		//             />
		// 	</TableContainer>
		// </TableListSection>
	)
}

export default CommentManagementTable

const DataRow = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;

	width: 974px;
	height: 63px;
`
const Top = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding: 0px;
	/* gap: 8px; */

	width: 974px;
	height: 19px;
`
const Info = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	/* padding: 0px; */
	gap: 10px;

	width: 185px;
	height: 19px;
`
const Username = styled.div`
	width: 80px;
	height: 19px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	/* or 19px */

	text-align: center;

	/* text/secondary */

	color: #4e5664;
`
const Date = styled.div`
	width: 150px;
	height: 16px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	/* identical to box height, or 16px */

	text-align: center;

	/* text/tertiary */

	color: #999999;
`
const ButtonWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 10px;

	/* width: 58px; */
	height: 17px;
`

const ModifyButton = styled.div`
	width: 35px;
	height: 17px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text-align: center; */

	/* content/accentBlue */

	color: #0a387d;
`

const DeleteButton = styled.div`
	width: 35px;
	height: 17px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	/* identical to box height, or 17px */

	/* text-align: center; */

	/* content/accentBlue */

	color: #d44242;
`
const Content = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px 15px;

	width: 974px;
	height: 36px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	/* or 18px */

	/* text/contents */

	color: #666666;
`

type Props = {
	createComment: (content: string, lock: boolean) => void
}

function CommentInput({ createComment }: Props) {
	const [content, setContent] = useState('')
	const [lock, setLock] = useState(false)

	const create = () => {
		createComment(content, lock)

		setContent('')
	}

	return (
		<div>
			<div
				style={{
					width: '100%',
					marginTop: '20px',
					display: 'flex',
					justifyContent: 'space-between',
				}}>
				<div style={{ fontSize: '14px' }}> 댓글작성</div>
				<CreateButton onClick={create}>작성</CreateButton>
			</div>
			<textarea
				placeholder="댓글"
				style={{ resize: 'none', width: '100%', border: '1px solid #CCCCCC', borderRadius: '2px' }}
				onChange={e => setContent(e.target.value)}
				value={content}></textarea>
			{/* <label  style={{ color: "white"}} htmlFor="lock">비공개</label>
            <input type="checkbox" id="lock" onChange={(e)=>setLock(e.target.checked)}></input>
            <button onClick={create}>등록</button> */}
		</div>
	)
}
