import styled from 'styled-components'

export const PageMenuTabSection = styled.div`
	height: 60px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`
export const PageMenuSection = styled.div`
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
		padding-left: 10px;
	}
	@media only screen and (min-width: 1400px) {
		width: 62%;
	}
	@media only screen and (max-width: 1400px) {
		width: 70%;
	}
	@media only screen and (max-width: 968px) {
		width: 70%;
	}
	@media only screen and (max-width: 600px) {
		width: 100%;
		padding-left: 10px;
	}
`
