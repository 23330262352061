import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import MobileDrawerItem from './MobileDrawerItem'

const sideVariants = {
	closed: {
		x: '100%',
		transition: {
			staggerChildren: 0.2,
			staggerDirection: -1,
		},
	},
	open: {
		x: 0,
		transition: {
			staggerChildren: 0.2,
			staggerDirection: 1,
		},
	},
}

const MobileDrawerContainer = ({ show, setPath }) => {
	return (
		<AnimatePresence>
			{show && (
				<motion.aside
					initial={{ width: 0, backgroundColor: 'transparent' }}
					animate={{
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(0, 0, 0, 0.6)',
						backdropFilter: 'blur(6px)',
					}}
					exit={{
						width: 0,
						transition: { delay: 0.3, duration: 1 },
					}}
					style={{ position: 'fixed', top: 0, right: 0, bottom: 0 }}>
					<motion.div
						className="container"
						initial="closed"
						animate="open"
						exit="closed"
						variants={sideVariants}>
						{show ? <MobileDrawerItem setPath={setPath} /> : null}
					</motion.div>
				</motion.aside>
			)}
		</AnimatePresence>
	)
}

export default MobileDrawerContainer
