import styled from 'styled-components'

export const MoveButtonBox = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 22px;
	height: 22px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	&:hover {
		cursor: pointer;
	}
`
export const ClickedPageNumberBox = styled.div`
	box-sizing: border-box;
	border-radius: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 22px;
	height: 22px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	text-align: center;
	background-color: #333333;
	color: #ffffff;
`

export const PageNumberBox = styled.div`
	box-sizing: border-box;
	border-radius: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 22px;
	height: 22px;

	/* content/line */

	/* border: 0.5px solid #425788; */
	border-radius: 1px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	text-align: center;
	&:hover {
		cursor: pointer;
	}
`
