import React, { useState } from 'react'
import { useToken, useUser } from 'app/hooks/'
import API from 'utils/api'

import { useHistory } from 'react-router-dom'

import styled from 'styled-components'
import setting from '../../../../../assets/icons/setting.png'
import {
	TitleWrap,
	TitleWrapLeft,
	TitleWrapRight,
	ButtonWrap,
	CreateButton,
	TableWrap,
	ControllerWrap,
} from 'styles/globalPage-styles'
import { CancelButton } from '../BoardManage/styles'

import Editor from '../Custom/PostPage/editor'
import * as antd from 'antd'

type Props = {
	boardid: number
	handleModal: () => void
	boardname: string
}

function PostForm({ boardid, handleModal, boardname }: Props) {
	const { data: token } = useToken()

	const [type, setType] = useState('')
	const [content, setContent] = useState('')
	const [title, setTitle] = useState('')
	const [lock, setLock] = useState(false)
	const [password, setPassword] = useState('')
	const [edit, setEdit] = useState(false)
	const { data: username } = useUser()
	const navigate = useHistory()

	const validate = () => {
		if(!title || title.length < 1 || title.length > 100) {
			antd.message.error('제목은 1~100자 이내로 작성해 주세요')
			return false
		}

		if(!content || content.length < 1) {
			antd.message.error('내용을 작성해 주세요')
			return false
		}

		if((lock && !password) || password.length > 20) {
			antd.message.error('게시글 비공개시 비밀번호를 1~20자 이내로 작성해 주세요')
			return false
		}

		return true
	}

	
	const createPost = () => {
		const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
		let apiurl = `/board/${boardPath}`

		if (!validate()) return

		API.post(apiurl, token + '', {
			type,
			title,
			name: username,
			content,
			lock,
			password,
		})
			.then((res: any) => {
				if (res.result.success) {
					antd.message.success('등록되었습니다')
					const boardPath = window.location.pathname?.split('/')?.[3]?.split('?')[0]
					navigate.push(`/admin/board/${boardPath}/${res.result.result}`)
				}
			})
			.catch((err: any) => {})
	}

	return (
		<div style={{ margin: 0 }}>
			<TitleWrap>
				<TitleWrapLeft>
					<img src={setting} alt="icon"></img>
					<span style={{ fontSize: '20px', fontWeight: 'bold' }}>게시글 작성하기</span>
				</TitleWrapLeft>
				<TitleWrapRight>
					<span>홈 &gt; 게시판설정 &gt; {boardname} &gt; 작성하기 </span>
				</TitleWrapRight>
			</TitleWrap>
			<ControllerWrap></ControllerWrap>
			<TableWrap>
				<DataRow>
					<TitleHeader>제목</TitleHeader>
					<Title>
						<antd.Input value={title} onChange={e => setTitle(e.target.value)}></antd.Input>
					</Title>
				</DataRow>
				<DataRow>
					<ContentHeader>내용</ContentHeader>
					<Content style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'hidden' }}>
						<Editor content={content} edit={edit} setContent={setContent}></Editor>
					</Content>
				</DataRow>
				{/* <DataRow>
					<ContentHeader>내용</ContentHeader>
					<Content>
						<ContentTextarea value={content} onChange={(e)=>setContent(e.target.value)}></ContentTextarea>
					</Content>
				</DataRow> */}
				<DataRow>
					<TitleHeader>비공개</TitleHeader>
					<Title>
						<Lock>
							<antd.Space>
								<antd.Checkbox
									onChange={e => {
										setLock(e.target.checked)
										if (!e.target.checked) setPassword('')
									}}>
									비공개
								</antd.Checkbox>
							</antd.Space>
						</Lock>
						<TitleHeader>비밀번호</TitleHeader>
						<Password>
							<antd.Input
								type="password"
								readOnly={!lock}
								onChange={e => setPassword(e.target.value)}
								value={password}></antd.Input>
							{/* <Input type="password" readOnly={!lock} onChange={(e)=>setPassword(e.target.value)} value={password}></Input> */}
						</Password>
					</Title>
				</DataRow>
			</TableWrap>
			<ButtonWrap style={{ margin: '0 auto' }}>
				<CreateButton onClick={createPost}>저장</CreateButton>
				<CancelButton onClick={handleModal}>취소</CancelButton>
			</ButtonWrap>
			{/* <label>분류</label> <input type='text' onChange={(e)=>setType(e.target.value)}></input> */}
		</div>
	)
}

export default PostForm

const DataRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;

	/* border-top: 1px solid #E4E4E4; */
	width: 100%;
	/* width: 974px; */
	text-align: center;
	font-size: 12px;
	background: #ffffff;
`

const TitleHeader = styled.div`
	box-sizing: border-box;

	/* display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center; */

	width: 150px;
	height: 46px;
	line-height: 46px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 1px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

const Title = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 5px;

	min-width: 100px;
	width: 824px;
	height: 46px;

	/* white */

	background: #ffffff;
	/* table/border */

	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

const ContentHeader = styled.div`
	box-sizing: border-box;

	width: 150px;
	height: 400px;
	line-height: 400px;

	/* table/bg */

	background: #fafafa;
	/* table/border */

	border-width: 0px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

const Content = styled.div`
	box-sizing: border-box;

	/* width: 90%; */
	height: 400px;
	padding: 5px;
	width: 824px;
	/* white */

	background: #ffffff;
	/* table/border */

	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

const Lock = styled.div`
	width: 200px;
	padding: 0 30px;
	height: 46px;
	line-height: 46px;

	border-width: 0px 0px 1px 0px;
	border-style: solid;
	border-color: #e4e4e4;
`

const Password = styled.div`
	width: 100%;
	padding: 0 5px;
	height: 46px;
	line-height: 46px;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
