import React from 'react'
import { useHistory } from 'react-router-dom'
import CILABS from '../../../assets/images/CILABS_WHITE.png'
import Frame from '../../../assets/images/Frame2.png'
import Kakao from '../../../assets/icons/kakao.png'
import Tele from '../../../assets/icons/tele.png'
import { Container, Top, SocialIconWrap, MenuWrap, Menu, Bottom, Info } from './styles'
import { useWindowDimensions } from 'app/hooks'

const Footer = () => {
	const { width } = useWindowDimensions()
	const navigate = useHistory()

	const nav = (url: string) => navigate.push(url)
	const pageMove = (url: string) => {
		window.open(url)
	}
	const imgStyle = width < 400 ? { width: 25, height: 25 } : {}
	const logoStyle = width < 400 ? { height: 15 } : {}
	const companyStyle = width < 400 ? { height: 35 } : {}

	return (
		<>
			<Container>
				<Top>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
						<img src={CILABS} alt="..." style={logoStyle} />
						<SocialIconWrap>
							<img
								src={Tele}
								alt="..."
								onClick={() => pageMove('https://t.me/CILABS_kr')}
								style={imgStyle}></img>
							<img
								src={Kakao}
								alt="..."
								onClick={() => pageMove('http://pf.kakao.com/_DxnKcxj')}
								style={imgStyle}></img>
						</SocialIconWrap>
					</div>
					<MenuWrap>
						<Menu onClick={() => nav('/about')}>About Us</Menu>
						<Menu onClick={() => nav('/services')}>Services</Menu>
						<Menu onClick={() => nav('/portfolio')}>Portfolio</Menu>
						<Menu onClick={() => nav('/ideas')}>Ideas</Menu>
						<Menu onClick={() => nav('/contact')}>Contact</Menu>
					</MenuWrap>
				</Top>
				<Bottom>
					<img alt="...." style={{ height: 'fit-content', ...companyStyle }} src={Frame}></img>
					<Info>
						<p>씨아이랩스 | 경기도 고양시 일산동구 무궁화로 43-55, 성우사카르타워 4층 404호</p>
						<p>031.901.0815 | devteam@cilabs.co.kr</p>
					</Info>
				</Bottom>
			</Container>
		</>
	)
}

export default Footer
