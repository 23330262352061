import styled from 'styled-components'

export const LoginHeaderWrap = styled.div`
	align-items: flex-start;
	justify-content: center;
	display: flex;
	padding-inline: 20px;
	padding-top: 10px;
	padding-bottom: 0px;
	border-bottom-width: 0.5;
	border-bottom-color: #f1f1f1;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
`
