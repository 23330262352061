import React, { useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { Helmet } from 'react-helmet-async'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as PAGE from './pagesImport'
import { GlobalStyle } from 'styles/global-styles'
import { NotFoundPage } from './components/NotFoundPage/Loadable'
import { useToken } from './hooks'
import { AdminDashboardPage } from './adminpages/AdminDashboard'
import { NotificationPage } from './CMSPages/CMSNotificationPage'
import { BoardPage } from './CMSPages/CMSBoardPage'
import { CustomPage } from './CMSPages/CMSCustomPage'
import { PostPage } from './CMSPages/CMSPostPage'
import { SystemPage } from './CMSPages/CMSSystemPage'
import { PopupPage } from './CMSPages/CMSPopupPage'
import { UserPage } from './CMSPages/CMSUserPage'
import { LogoutPage } from './CMSPages/CMSLogoutPage'
import Custom from './CMSPages/CustomPage/Custom'
import Home from './CMSPages/Home'
import { BoardManagePage } from './CMSPages/CMSBoardManagePage'
import Board from './CMSPages/CustomPage/Community/Board/Board'
import PostManagement from './CMSPages/CustomPage/Community/Post'
import CustomManage from './CMSPages/CustomPage/CustomManage'
import OauthCallback from './organisms/Auth/Forms/OauthCallback'
import TestLoginForm from './organisms/Auth/Forms/TestLoginForm'
import RevokePage from './organisms/Auth/Forms/RevokePage'
import AppleRedirectPage from './organisms/Auth/Forms/AppleRedirectPage'

export function Routes() {
	const { i18n } = useTranslation()
	const { data: token } = useToken()

	return (
		<BrowserRouter>
			<Helmet titleTemplate="%s" defaultTitle="씨아이랩스" htmlAttributes={{ lang: i18n.language }}>
				<meta name="description" content="씨아이랩스는 트렌디한 디자인과 최신 개발 기술을 보유한 웹·앱 소프트웨어 개발 업체입니다. 고객의 니즈를 반영하여 최적의 솔루션을 제시합니다" />
			</Helmet>

			<Switch>
				<Route
					exact
					path="/editordemo"
					component={() => {
						const xhrHtml = new XMLHttpRequest()
						xhrHtml.open('GET', './editor/editor.html', false)
						xhrHtml.send()
						const html = xhrHtml.responseText
						const xhrCss = new XMLHttpRequest()
						xhrCss.open('GET', './editor/styles.css', false)
						xhrCss.send()
						const css = xhrCss.responseText
						return (
							<>
								<style>{css}</style>
								<div dangerouslySetInnerHTML={{ __html: html }} />
							</>
						)
					}}
				/>
				{/* <Route exact path="/" component={Custom} /> */}

				<Route path="/admin">
					<Route exact path="/admin/" component={token ? AdminDashboardPage : PAGE.LoginPage} />
					<Route
						exact
						path="/admin/login/"
						component={token ? AdminDashboardPage : PAGE.LoginPage}
					/>
					<Route exact path="/admin/logout/" component={LogoutPage} />
					<Route exact path="/admin/users" component={token ? UserPage : PAGE.LoginPage} />
					<Route exact path="/admin/board" component={token ? BoardManagePage : PAGE.LoginPage} />
					<Route exact path="/admin/board/:id" component={token ? BoardPage : PAGE.LoginPage} />
					<Route exact path="/admin/board/:id/:id" component={token ? PostPage : PAGE.LoginPage} />
					<Route exact path="/admin/custom" component={token ? CustomPage : PAGE.LoginPage} />
					<Route path="/custom/:id" component={token ? Custom : PAGE.LoginPage} />

					<Route path="/admin/notification" component={token ? NotificationPage : PAGE.LoginPage} />
					<Route exact path="/admin/popup" component={token ? PopupPage : PAGE.LoginPage} />
					<Route exact path="/admin/popup/:id" component={token ? PopupPage : PAGE.LoginPage} />
					<Route exact path="/admin/system" component={token ? SystemPage : PAGE.LoginPage} />
					<Route exact path="/admin/system/:id" component={token ? SystemPage : PAGE.LoginPage} />
					{/* <Route exact path="/admin/login/test" component={TestLoginForm} /> */}
					{/* <Route exact path="/admin/login/auth/:id" component={OauthCallback} />
					<Route exact path="/admin/login/auth/:id/revoke" component={RevokePage} />
					<Route exact path="/admin/oauth/apple" component={AppleRedirectPage}  /> */}
				</Route>

				{/* Secure Page */}
				{/* <Route
					exact
					path="/admin"
					component={token ? AdminDashboardPage : PAGE.LoginPage}
				/> */}
				
				<Route path="/">
					<CustomManage />
				</Route>
				
				{/* <Route exact path="/ideas/:id" component={PostManagement} />*/}
				{/* <Route exact path="/ideas/:id" component={Board} />
				<Route exact path="/ideas/:id/:id" component={PostManagement} /> */}
				<Route path="*" component={NotFoundPage} />
			</Switch>
			<GlobalStyle />
		</BrowserRouter>
	)
}
