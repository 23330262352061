// export const API_URL = 'https://coinapi.cilabs.co.kr/v1' // API URL
export const API_URL = 'https://cms-api.cilabs.co.kr/v1' // API URL
export const USERINFO = '/user/my/info' // 사용자 정보
export const MYSETTINGS = '/user/my/settings' // 내 설정
export const MYBALANCE = '/user/my/balance' // 내 자산
export const MYTRADESETTINGS = '/user/my/tradesetting' // 내 트레이딩설정

// Created By 태신
export const TRANSFER = '/user/my/transfer'

export const EXCHANGESETTINGS = '/exchanges' // 거래쌍 설정
export const ORDERBOOK = '/trade/orderbooks' // 오더북
export const CANCELORDER = '/trade/cancel' // 주문취소

export const TRADELIST = '/trade/tradelist' // 주문취소

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyDWa6hlrMtPx2AvZgSR7HyYntuJ0l6XZ4A",
    authDomain: "cms-server-22b35.firebaseapp.com",
    projectId: "cms-server-22b35",
    storageBucket: "cms-server-22b35.appspot.com",
    messagingSenderId: "887007230308",
    appId: "1:887007230308:web:f5e9fe3faba37ae3c9a271"
};

export const VAPID_KEY="BBw0ryNkmuEGY1w6ZTsTvsINJIS-mbEANi4Y826-M7zW5iGfiOika787Okrt3uF5R8mdPFzTX0HucYpVYPpunhI"

export const UPLOAD_API_URL = `https://cms-api.cilabs.co.kr/v1/custom/pages`
export const UPLOAD_DEFAULT_URL =  `https://cms-api.cilabs.co.kr/public/uploadImage`