import styled from 'styled-components'

export const HeaderSection = styled.div`
	width: 100%;
`
export const ContentWrap = styled.div`
	width: 100%;
	max-width: 1200px;
	background-color: #ffffff;
	/* padding: 10px; */
	/* margin-left: ; */
	margin: 30px auto;
`

export const HeaderContainer = styled.div`
	width: 100%;
`

export const NavigationBarContainer = styled.div`
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: flex-start;

	/* main */

	background: #000000;
`

export const HeaderButtonWrap = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
`

export const Container = styled.div`
	width: 100%;
	/* max-width: 1920px; */
	height: 340px;
	background-color: #333333;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	gap: 50px;
	padding: 0 50px;

	@media screen and (max-width: 1000px) {
		padding: 50px;
		justify-content: flex-start;
		align-items: flex-start;
		height: 100% !important;
		width: 100% !important;
		gap: 40px;
	}
	@media screen and (max-width: 600px) {
		padding: 0px;
	}
`

export const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 1920px;
	flex-wrap: wrap;
	row-gap: 20px;

	@media screen and (max-width: 600px) {
		flex-direction: column-reverse;
		justify-content: flex-start;
		align-items: flex-start;
		font-size: 14px;
		text-align: center;
		margin-top: 10px;
		margin-left: 20px;
	}
`

export const MenuWrap = styled.div`
	display: flex;
	gap: 30px;
	@media screen and (max-width: 1000px) {
		display: none;
	}
`

export const SocialIconWrap = styled.div`
	display: flex;
	margin-top: 10px;
	gap: 10px;

	@media screen and (max-width: 570px) {
		justify-content: center;
	}
`

export const Bottom = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1920px;
	flex-wrap: wrap;
	row-gap: 20px;
	font-size: 16px;

	@media screen and (max-width: 600px) {
		flex-direction: column-reverse;
		justify-content: flex-start;
		align-items: flex-start;
		font-size: 10px;
		text-align: left;
		padding-left: 20px;
		row-gap: 10px;
		margin-top: -25px;
	}
`

export const Info = styled.div`
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	/* font-size: 16px; */
	line-height: 25px;
	/* or 156% */

	letter-spacing: -0.015em;

	color: #ffffff;
`

export const Menu = styled.div`
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 28px;
	/* identical to box height, or 187% */

	cursor: pointer;

	letter-spacing: -0.015em;

	&:hover {
		color: #ff8800;
	}

	@media screen and (max-width: 570px) {
		display: none;
	}
`

export const HeaderContainer1 = styled.div`
	/* position: absolute; */
	width: 100%;
	height: 65px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	/* padding: 15px 0;
	 */
	padding: 15px 0px 15px 25px;
	background-color: #ffffff;
`

export const HeaderLogo = styled.div`
	width: 160px;
	height: 34.62px;
	margin-left: 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
`

export const NavigationButtonWrap = styled.div<{ hover?: boolean }>`
	/* Gnb/list */

	transition: all 0.31s ease-in-out;
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	height: 50px;

	/* white */

	background: #000000;
	/* main */

	border-top: 3px solid #000000;

	/* Inside auto layout */

	flex: none;
	flex-grow: 1;
	max-width: 160px;

	color: #ffffff;

	&:hover {
		color: #000000;
		background-color: #ffffff;
		cursor: pointer;
		font-weight: bold;
	}

	@media screen and (max-width: 570px) {
		width: 75px;
	}
`
export const NavigationButtonText = styled.span`
	font-size: 14px;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;
	letter-spacing: 1px;

	@media screen and (max-width: 570px) {
		font-size: 12px;
	}
`

export const CustomContentWrap = styled.div<{ width?: string; height?: string }>`
	width: ${props => props.width};
	/* height: ${props => props.height}; */

	margin: 0 auto 300px auto;
	/* display: flex;
	align-items: center; */
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 1000px) {
		/* margin: 0 auto 100px auto; */

		width: 100%;
		margin: 0;
	}
`

export const CustomPageWrap = styled.div`
	/* display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column; */
	width: 100%;
	height: 100%;

	overflow-x: hidden !important;
`
export const ContetnWrap = styled.div<{ width: string; height: string }>`
	width: ${props => props.width};
	/* height:${props => props.height};*/
	height: auto;
	margin: 0 auto 300px auto;
	/* display: flex;
	align-items: center; */
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 900px) {
		margin: 0 auto 100px auto;
	}

	@media screen and (max-width: 570px) {
		margin: 0 auto 50px auto;
	}
`

export const MapContainer = styled.div`
	width: 600px;
	height: 500px;
	z-index: 0 !important;
	@media screen and (max-width: 1000px) {
		max-width: 1920px;
		width: 100% !important;
		height: 250px;
	}
	@media screen and (max-width: 600px) {
		max-width: 1920px;
		width: 100% !important;
		height: 25vh;
	}
`

export const InfoContainer = styled.div`
	width: 100%;
	max-width: 2400px;
	/* min-height: 900px; */
	margin-top: 50px;
	margin-bottom: 200px;

	/* padding: 100px 300px; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 80px;
	@media screen and (max-width: 900px) {
		/* margin-bottom: 100px; */
		margin-bottom: 0px !important;
		gap: 0px !important;
		margin-top: 30px;
	}
`

export const Title = styled.div`
	margin: 0 auto;

	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 60px;
	/* identical to box height, or 117% */

	text-align: center;
	letter-spacing: -0.015em;

	color: #000000;

	@media screen and (max-width: 1200px) {
		font-size: 30px;
	}

	@media screen and (max-width: 900px) {
		font-size: 28px;
		margin-bottom: 10px;
	}

	@media screen and (max-width: 570px) {
		font-size: 20px;
	}
`

export const InfoWrap = styled.div`
	display: flex;
	gap: 115px;

	@media screen and (max-width: 1000px) {
		flex-direction: column;
		gap: 0px;
		width: 90%;
		max-width: 1920px;
		align-items: center;
	}

	@media screen and (max-width: 600px) {
		width: 100%;
	}
`

export const InfoTextWrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-self: flex-end;
	padding-bottom: 50px;

	@media screen and (max-width: 1000px) {
		gap: 5px;
		text-align: left;
		align-self: flex-start;
		padding-left: 15px;
		align-items: center;
		padding-block: 10px;
	}
`
export const CompanyName = styled.div`
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	/* identical to box height, or 125% */

	letter-spacing: -0.015em;

	color: #222222;

	@media screen and (max-width: 1000px) {
		margin-top: 10px;
		font-size: 24px !important;
		width: 100%;
		text-align: left;
	}
	@media screen and (max-width: 600px) {
		margin-top: 10px;
		font-size: 18px !important;
		width: 100%;
		text-align: left;
	}
`

export const CompanyInfo = styled.div`
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	/* identical to box height, or 125% */

	letter-spacing: -0.01em;

	color: #222222;

	display: flex;
	flex-direction: column;
	gap: 10px;

	@media screen and (max-width: 570px) {
		font-size: 16px;
	}
`
