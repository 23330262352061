import styled from 'styled-components'


export const TableContainer = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;

	width: 380px;

	/* table/border */

	border-bottom: 1px solid #e4e4e4;
`

export const RowDataName = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 50px;

	padding: 0 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	border-width: 0px 0px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
export const TitleContent = styled.div`
	box-sizing: border-box;

	padding: 0 5px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	width: 280px;
	height: 50px;
	/* line-height: 50px; */

	border-width: 0px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`

export const RowDataContent = styled.div`
	box-sizing: border-box;

	display: flex;
	justify-content: space-evenly;
	align-items: center;
	align-self: center;

	padding: 0 5px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	width: 280px;
	height: 50px;
	line-height: 50px;

	border-width: 0px 1px 0px 1px;
	border-style: solid;
	border-color: #e4e4e4;
`
export const CheckboxWrap = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`