import styled from 'styled-components'

export const TableContainer = styled.div``

export const TableListSection = styled.div`
	width: 100%;
	background-color: transparent;
`

export const Name = styled.div`
	box-sizing: border-box;

	width: 400px;
	height: 36px;

	/* table/border */
	padding: 0 12px;

	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #E4E4E4;
`
export const Type = styled.div`
	width: 100px;
	height: 36px;

	padding: 0 12px;

	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #E4E4E4;

	text-align: center;
`
export const Date = styled.div`
	box-sizing: border-box;

	width: 167px;
	height: 36px;

	padding: 0 12px;

	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #E4E4E4;

	text-align: center;
`
export const Count = styled.div`
	box-sizing: border-box;

	width: 140px;
	height: 36px;

	padding: 0 12px;

	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #E4E4E4;

	text-align: center;
`

export const Modal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px 20px;
	gap: 16px;

	position: absolute;
	width: 420px;
	/* height: 242px; */
	left: 50%;
	top: 20%;
	margin-left: -210px;

	/* white */

	background: #FFFFFF;
	box-shadow: 0px 0px 20px rgba(88, 88, 88, 0.25);
	border-radius: 2px;
`

export const RowWrap = styled.div` 
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;

	width: 380px;
	height: 36px;
	line-height: 36px;
	background: #FFFFFF;

`

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px;
	gap: 26px;

	width: 380px;
	height: 23px;
`

export const Title= styled.div`
	gap: 4px;

	width: 100px;
	height: 23px;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;

	/* text/primary */

	color: #333333;
`

export const Icon = styled.div`
	width: 16px;
	height: 16px;
`

export const ContentWrap = styled.div`
	box-sizing: border-box;

	/* Auto layout */

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;


	width: 380px;
	/* height: 109px; */

	/* table/border */

	/* border: 1px solid #E4E4E4; */
`
	
export const ContentColumn1 = styled.div`
	box-sizing: border-box;

	width: 100px;
	height: 50px;

	padding: 0 12px;

	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;

	text-align: center;

	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #E4E4E4;

	text-align: center;
`

export const ContentColumn2 = styled.div`
	box-sizing: border-box;

	width: 280px;
	height: 50px;

	padding: 0 5px;

	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;

	border-width: 0px 1px 1px 1px;
	border-style: solid;
	border-color: #E4E4E4;
`

export const Input = styled.input`
	box-sizing: border-box;

	padding: 0px 10px;

	width: 250px;
	height: 26px;

	/* text/primary */

	border: 1px solid #CCCCCC;
	border-radius: 2px;

	&:focus{
		border: 1px solid #333333;
	}

	/* Inside auto layout */

	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;

	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	/* identical to box height, or 16px */


	/* text/primary */

	color: #333333;
`

export const CancelButton = styled.button`

	padding: 0px 10px;

	width: 68px;
	text-align: center;
	height: 32px;

	/* content/accentRed */

	background: #D44242;
	border-radius: 2px;
	border: none;

	font-size: 14px;
	line-height: 17px;

	/* text/white */

	color: #FFFFFF;
`

export const Close = styled.div`
	width: 16px;

	&:hover{  
		cursor: pointer;
		font-size: bold;
 	 }
`