import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Header } from 'app/organisms/'
import { CToast } from 'app/components/Common'
import {
	ContentContainer,
	ContentWrapper,
} from '../../../styles/globalPage-styles'
import { useHistory } from 'react-router-dom'

export const AdminDashboardPage = () => {
	const navigate = useHistory()

	useEffect(()=>{
		navigate.push('/admin/users')
	},[])
	return (
		null
		// <>
		// 	<Helmet>
		// 		<title>ADMIN DASHBOARD</title>
		// 	</Helmet>

		// 	<ContentContainer isDarkMode={false}>
		// 		<CToast />
		// 		<Header />
		// 		<ContentWrapper style={{ height: '100%', overflowY: 'scroll' }}>
		// 			<>메인요약위젯</>
		// 		</ContentWrapper>
		// 	</ContentContainer>
		// </>
	)
}
