const Facebook: string = require('./facebook.svg').default
const Discord: string = require('./discord.svg').default
const Instagram: string = require('./instagram.svg').default
const Linkin: string = require('./linkin.svg').default
const Medium: string = require('./medium.svg').default
const Reddit: string = require('./reddit.svg').default
const Telegram: string = require('./telegram.svg').default
const Tictok: string = require('./tictok.svg').default

const Twitter: string = require('./twitter.svg').default
const Youtube: string = require('./youtube.svg').default

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	Facebook,
	Discord,
	Instagram,
	Linkin,
	Medium,
	Reddit,
	Telegram,
	Tictok,
	Twitter,
	Youtube,
}
